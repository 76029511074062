import React from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {List, Map, fromJS} from 'immutable';
import {v4 as uuid4} from 'uuid';
import validator from "validator";
import styled from 'styled-components';

/* Components */
import {SavedSettings} from '../../../../common/components/cards';
import {Alert, AlertRed, SetupOptions, SetupBox, SetupSchedule, SetupAddHours, SetupEachDay} from '../components/recurringsettings';
import {RadioBoxWithLabel, TextInput} from "../../../../common/components/inputs";
import {LogoLoading} from "../../../../common/components/loading";
import {FontBody16, FontHeader16, FontBody14, FontTitle18} from "../../../../common/components/fonts";

/* Domain */
import getDomainEnv from '../../../../domains/utils';
const env = getDomainEnv();

const Inline = styled.div`
    display: inline-block;
    vertical-align: top;
    margin: 20px 35px 0px 0px;
    @media screen and (max-width: 640px) {
        margin: 20px 0px -10px 0px;
        display: block;
    }
`;

/* Middleware */
import {tryGetRecurringAvailability, tryGetRecurringAvailabilitySchedule, tryUpdateRecurringAvailability, tryUpdateRecurringAvailabilitySchedule, tryUpdateRecurringAvailabilityScheduleOutlookEmail} from '../middleware/recurring';

const MONDAY = 0;
const TUESDAY = 1;
const WEDNESDAY = 2;
const THURSDAY = 3;
const FRIDAY = 4;
const SATURDAY = 5;
const SUNDAY = 6;

class AvailabilityRecurringSettings extends React.Component {
    static defaultProps = {
        onSave: () => {}
    }

    state = {
        isLoading: true,
        method: '',
        schedule: List([
            Map({id: MONDAY, dayOfWeek: "mon", startTime: "09:00:00", endTime: "18:00:00", startAfterEnd: true, atLeastOneHr: true}),
            Map({id: TUESDAY, dayOfWeek: "tue", startTime: "09:00:00", endTime: "18:00:00", startAfterEnd: true, atLeastOneHr: true}),
            Map({id: WEDNESDAY, dayOfWeek: "wed", startTime: "09:00:00", endTime: "18:00:00", startAfterEnd: true, atLeastOneHr: true}),
            Map({id: THURSDAY, dayOfWeek: "thu", startTime: "09:00:00", endTime: "18:00:00", startAfterEnd: true, atLeastOneHr: true}),
            Map({id: FRIDAY, dayOfWeek: "fri", startTime: "09:00:00", endTime: "18:00:00", startAfterEnd: true, atLeastOneHr: true})
        ]),
        isValid: true,
        isSaving: false,
        isSaved: false,
        error: '',
        isChanged: false,
        email: "", emailValid: true,
        outlookProxy: null,
        preview: false,
        isSyncingWithExternal: false
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.method !== this.props.method && !this.state.isSyncingWithExternal) {
            return this.init();
        }
    }

    componentWillUnmount() {
        clearInterval(this.timeout)
    }

    init = async () => {
        await this.props.tryGetRecurringAvailability();
        await this.props.tryGetRecurringAvailabilitySchedule();
        let method;
        // if(this.props.method === "willow") {
        //     method = "setup_willow_manual"
        /*} else */
        if(this.props.method === "willow") {
            method = "setup_willow_recurring"
        } else if(this.props.method === "willow_recurring") {
            method = "willow_recurring"
        } else if(this.props.method === "google_calendar") {
            method = "google_calendar"
        } else if(this.props.method === "microsoft_outlook") {
            method = "microsoft_outlook"
        } else if(this.props.method === "calendly") {
            method = "calendly"
        }
        this.setState({
            method: method,
            schedule: this.props.method === "willow" ? this.state.schedule : this.props.workSchedule,
            outlookProxy: this.props.method === "microsoft_outlook" ? this.props.outlookProxy : null,
            isLoading: false
        });
    };

    setCalendarSetupGoogleCalendar = () => {
        this.setState({"method": "setup_google_calendar", "isValid": true, "emailValid": true})
    };

    setCalendarSetupMicrosoftCalendar = () => {
        this.setState({"method": "setup_microsoft_outlook", "isValid": false})
    };

    setCalendarSetupWillowRecurring = () => {
        this.setState({"method": "setup_willow_recurring", "isValid": true, "emailValid": true})
    };

    setCalendarSetupCalendly = () => {
        this.setState({"method": "setup_calendly", "isValid": true, "emailValid": true})
    };

    // setCalendarSetupWillowManual = () => {
    //     this.setState({"method": "setup_willow_manual", "isValid": true, "emailValid": true})
    // };

    setCalendarWillowRecurring = async () => {
        await this.setState({"isLoading": true})
        await this.setState({"method": "willow_recurring"})
        await this.props.tryUpdateRecurringAvailability("willow_recurring");
        await this.props.tryUpdateRecurringAvailabilitySchedule(this.state.schedule.toJS());
        await this.props.onSave();
        await this.setState({"isLoading": false})
    };

    // setCalendarWillowManual = async () => {
    //     await this.props.tryUpdateRecurringAvailability("willow");
    //     this.props.history.push("/availability/calendar");
    // };

    setCalendarGoogleCalendar = async () => {
        await this.setState({"isLoading": true, "isSyncingWithExternal": true})
        // await this.props.tryUpdateRecurringAvailability("google_calendar");
        await this.props.tryUpdateRecurringAvailabilitySchedule(this.state.schedule.toJS());
        window.location.replace("https://"+env.URL.API+"/integrations/google-calendar/request-google-authorization");
        // await this.setState({"method": "google_calendar"})
        // await this.props.onSave();
        // await this.setState({"isLoading": false})
    };

    setCalendarMicrosoftCalendar = async () => {
        await this.setState({"isLoading": true, "isSyncingWithExternal": true})
        // await this.props.tryUpdateRecurringAvailability("microsoft_outlook");
        await this.props.tryUpdateRecurringAvailabilitySchedule(this.state.schedule.toJS());
        await this.props.tryUpdateRecurringAvailabilityScheduleOutlookEmail(this.state.email);
        window.location.replace("https://"+env.URL.API+"/integrations/microsoft-calendar/request-oauth2-authorization");
        // await this.setState({"method": "microsoft_outlook"})
        // await this.props.onSave();
        // await this.setState({"isLoading": false})
    };

    setCalendarCalendly = async () => {
        await this.setState({"isLoading": true, "isSyncingWithExternal": true})
        // NOTE: I've disabled this to allow users to complete auth flow before settings to calendly
        //       Let auth flow do setup of calendar, it will mark correct availability
        // await this.props.tryUpdateRecurringAvailability("calendly");
        // await this.props.tryUpdateRecurringAvailabilitySchedule(this.state.schedule.toJS());
        window.location.replace("https://"+env.URL.API+"/integrations/calendly/request-auth-code");
    }

    setSkipDay = async (id) => {
        const scheduleAdj = await this.state.schedule.filter(d => {return d.get("id") !== id});
        await this.setState({"schedule": scheduleAdj});
        this.checkValid();
    };

    setCopyDay = async (id) => {
        const row = await this.state.schedule.filter(s => {
            return(id === s.get("id"))
        }).get(0);
        let schedule = this.state.schedule.toJS();
        schedule.push({...row.toJS(), "id": uuid4()});
        this.setState({"schedule": fromJS(schedule)});
    };

    setAddDay = async () => {
        const newDay = Map({"id": uuid4(), "dayOfWeek": "mon", "startTime": "09:00:00", "endTime": "18:00:00", "startAfterEnd": true, "atLeastOneHr": true});
        let schedule = this.state.schedule.toJS();
        schedule.push(newDay);
        await this.setState({"schedule": fromJS(schedule)});
        this.checkValid();
    };

    handleChangeEmail = async e => {
        this.setState({'email': e.target.value});
        await this.setState({'emailValid': validator.isEmail(e.target.value)});
        this.setState({'isChanged': true});
        this.checkValid();
    };

    checkValid = async () => {
        let isValid = false;
        await this.state.schedule.map(d => {
            // Make sure no overlaps between days/times
            if (d.get("startTime") !== null) {
                isValid = true;
            }
        });
        this.setState({
            "isValid": this.state.method === "setup_microsoft_outlook" ? (validator.isEmail(this.state.email) && isValid) : isValid
        });
    };

    handleChangeDay = async (day, id) => {
        const scheduleAdj = await this.state.schedule.map(d => {
            if(d.get("id") === id) {
                return Map({...d.toJS(), "dayOfWeek": day});
            } else {
                return d;
            }
        });
        await this.setState({"schedule": scheduleAdj});
        this.checkValid();
    };

    handleTimeChange = async (startTime, endTime, id) => {
        const endTimeNew = parseInt(endTime.substring(0, 2)+endTime.substring(3, 5));
        const startTimeNew = parseInt(startTime.substring(0, 2)+startTime.substring(3, 5));
        let isValid = true;
        const scheduleAdj = await this.state.schedule.map(d => {
            if(d.get("id") === id) {
                if(endTimeNew > startTimeNew && (endTimeNew - startTimeNew) >= 60) {
                    return Map({id: d.get("id"), dayOfWeek: d.get("dayOfWeek"), startTime: startTime, endTime: endTime, startAfterEnd: true, atLeastOneHr: true});
                } else if(endTimeNew <= startTimeNew && (endTimeNew - startTimeNew) >= 60) {
                    isValid = false;
                    return Map({id: d.get("id"), dayOfWeek: d.get("dayOfWeek"), startTime: startTime, endTime: endTime, startAfterEnd: false, atLeastOneHr: true});
                } else if(endTimeNew > startTimeNew && (endTimeNew - startTimeNew) < 60) {
                    isValid = false;
                    return Map({id: d.get("id"), dayOfWeek: d.get("dayOfWeek"), startTime: startTime, endTime: endTime, startAfterEnd: true, atLeastOneHr: false});
                } else if(endTimeNew <= startTimeNew && (endTimeNew - startTimeNew) < 60) {
                    isValid = false;
                    return Map({id: d.get("id"), dayOfWeek: d.get("dayOfWeek"), startTime: startTime, endTime: endTime, startAfterEnd: false, atLeastOneHr: false});
                }
            } else {
                if(!d.get("startAfterEnd") || !d.get("atLeastOneHr")) {
                    isValid = false;
                }
                return d;
            }
        });
        await this.setState({"schedule": scheduleAdj, "isValid": isValid});
        this.checkValid();
    };

    handleSubmit = async () => {
        if(this.state.isValid) {
            this.setState({'isSaving': true, 'error': ''});
            await this.props.tryUpdateRecurringAvailabilitySchedule(this.state.schedule.toJS());
            await this.props.onSave();
            this.setState({'isSaving': false, 'isSaved': true, isChanged: false});
            this.timeout = setTimeout(() => {this.setState({'isSaved': false})}, 3000);
        }
    };

    submitCalendarTypeSetup = async () => {
        if (this.state.method === "setup_google_calendar")
            this.setCalendarGoogleCalendar()
        else if (this.state.method === "setup_microsoft_outlook")
            this.setCalendarMicrosoftCalendar()
        else if (this.state.method === "setup_calendly")
            this.setCalendarCalendly()
        else
            this.setCalendarWillowRecurring()
    }

    render() {
        if(this.state.isLoading) {
            return(<LogoLoading />)
        } else {
            if(this.state.isSaved) {
                if(this.state.error === "") {
                    return(<SavedSettings height={"208px"} error={false} message={"Saved"}/>)
                } else {
                    return(<SavedSettings height={"208px"} error={true} message={this.state.error}/>)
                }
            } else {
                if(/*this.state.method === "setup_willow_manual" || */this.state.method === "setup_google_calendar" || this.state.method === "setup_microsoft_outlook" || this.state.method === "setup_willow_recurring" || this.state.method === "setup_calendly") {
                    return(
                        <>
                            <AlertRed><FontHeader16>Set a recurring weekly schedule below.</FontHeader16></AlertRed>
                            <br />
                            <FontHeader16>Choose a calendar</FontHeader16>
                            <div>
                                <Inline><RadioBoxWithLabel id={3} action={this.setCalendarSetupWillowRecurring} checked={this.state.method === "setup_willow_recurring"} label={"Willow Recurring"} /></Inline>
                                <Inline><RadioBoxWithLabel id={1} action={this.setCalendarSetupGoogleCalendar} checked={this.state.method === "setup_google_calendar"} label={"Sync Google"} /></Inline>
                                <Inline><RadioBoxWithLabel id={2} action={this.setCalendarSetupMicrosoftCalendar} checked={this.state.method === "setup_microsoft_outlook"} label={"Sync Outlook"} /></Inline>
                                <Inline><RadioBoxWithLabel id={2} action={this.setCalendarSetupCalendly} checked={this.state.method === "setup_calendly"} label={"Sync Calendly"} /></Inline>
                                {/*<Inline><RadioBoxWithLabel id={4} action={this.setCalendarSetupWillowManual} checked={this.state.method === "setup_willow_manual"} label={"Manual Calendar"} /></Inline>*/}
                            </div>
                            {/*{this.state.method !== "setup_willow_manual" &&*/}
                                <>
                                    <br />
                                    <br />
                                    {this.state.method !== "setup_calendly" && <FontHeader16>Set weekly recurring availability</FontHeader16>}
                                    <SetupBox>
                                        {this.state.method !== "setup_calendly" &&
                                            <>
                                                <SetupSchedule>
                                                    {this.state.schedule.map(s => (
                                                        <SetupEachDay
                                                            key={s.get("id")}
                                                            data={s}
                                                            handleChangeDay={this.handleChangeDay}
                                                            handleTimeChange={this.handleTimeChange}
                                                            setSkipDay={this.setSkipDay}
                                                            setCopyDay={this.setCopyDay}
                                                            showDelete={this.state.schedule.size > 1}
                                                        />
                                                    ))}
                                                </SetupSchedule>
                                                <SetupAddHours onClick={this.setAddDay}><FontBody16>+ Add Day</FontBody16></SetupAddHours>
                                                {this.state.method === "setup_microsoft_outlook" &&
                                                    <TextInput
                                                        title={"Email*"}
                                                        valid={this.state.emailValid}
                                                        warning={"Enter a valid email address"}
                                                        id={"email"}
                                                        onChange={this.handleChangeEmail}
                                                        placeholder={"Enter your Outlook or Live Email address"}
                                                        value={this.state.email}
                                                    />
                                                }
                                            </>
                                        }
                                        <SetupOptions
                                            cancelLabel={"Back"}
                                            cancel={null}
                                            submit={this.submitCalendarTypeSetup}
                                            submitLabel={this.state.method === "setup_willow_recurring" ? "Save" : "Connect"}
                                            isValid={(this.state.isValid && this.state.emailValid)}
                                        />
                                    </SetupBox>
                                </>
                            {/*}*/}
                            {/*{this.state.method === "setup_willow_manual" &&*/}
                            {/*    <SetupOptions*/}
                            {/*        cancelLabel={"Back"}*/}
                            {/*        cancel={null}*/}
                            {/*        submit={this.setCalendarWillowManual}*/}
                            {/*        submitLabel={"Save"}*/}
                            {/*        isValid={true}*/}
                            {/*    />*/}
                            {/*}*/}
                        </>
                    )
                } else if(this.state.method === "google_calendar" || this.state.method === "microsoft_outlook" || this.state.method === "willow_recurring") {
                    return(
                        <>
                            <Alert><FontHeader16>{this.state.method === "willow_recurring" ? "Weekly Availability Set!" : "Calendar Sync Enabled!"}</FontHeader16></Alert>
                            <br />
                            <br />
                            <FontHeader16>Weekly recurring availability</FontHeader16>
                            <SetupBox>
                                <SetupSchedule>
                                    {this.state.schedule.map(s => (
                                        <SetupEachDay
                                            key={s.get("id")}
                                            data={s}
                                            handleChangeDay={this.handleChangeDay}
                                            handleTimeChange={this.handleTimeChange}
                                            setSkipDay={this.setSkipDay}
                                            setCopyDay={this.setCopyDay}
                                            showDelete={this.state.schedule.size > 1}
                                        />
                                    ))}
                                </SetupSchedule>
                                <SetupAddHours onClick={this.setAddDay}><FontBody16>+ Add Day</FontBody16></SetupAddHours>
                                <SetupOptions
                                    cancelLabel={null}
                                    cancel={null}
                                    submit={this.handleSubmit}
                                    submitLabel={"Update"}
                                    isValid={this.state.isValid}
                                />
                            </SetupBox>
                        </>
                    )
                } else if (this.state.method === "calendly") {
                    return (
                        <>
                            <Alert><FontHeader16>Calendar Sync Enabled!</FontHeader16></Alert>
                            <FontTitle18>Weekly recurring availability</FontTitle18>
                            <br />
                            <FontBody14>
                                Your availability schedule is pulled from Calendly. To update your availability schedule please use Calendly.
                            </FontBody14>
                            <br />
                        </>
                    )
                }
            }
        }
    }
}

const mapStateToProps = state => ({
    method: state.enterprise.recurring.get("recurringAvailability").get("availability_calendar_update_method"),
    outlookProxy: state.enterprise.recurring.get("recurringAvailability").get("outlookProxy"),
    workSchedule: state.enterprise.recurring.get("recurringAvailabilitySchedule").get("workSchedule")
});

const mapDispatchToProps = dispatch => ({
    tryGetRecurringAvailabilitySchedule: () => dispatch(tryGetRecurringAvailabilitySchedule()),
    tryGetRecurringAvailability: () => dispatch(tryGetRecurringAvailability()),
    tryUpdateRecurringAvailabilitySchedule: (workSchedule) => dispatch(tryUpdateRecurringAvailabilitySchedule(workSchedule)),
    tryUpdateRecurringAvailability: (syncMethod) => dispatch(tryUpdateRecurringAvailability(syncMethod)),
    tryUpdateRecurringAvailabilityScheduleOutlookEmail: (email) => dispatch(tryUpdateRecurringAvailabilityScheduleOutlookEmail(email))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AvailabilityRecurringSettings));
