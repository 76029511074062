import TagManager from "react-gtm-module";
import ReactGA from "react-ga";
import { FullStoryAPI } from 'react-fullstory';
import mixpanel from 'mixpanel-browser';
import {axiosReq} from "./axios";
import * as Sentry from '@sentry/browser';
import TaboolaTracking from 'react-tracking-taboola';

/* Domain */
import getDomainEnv from '../../domains/utils';
const env = getDomainEnv();

export const initiateSentry = () => {
    const isStaging = env.ENVIRONMENT === "STAGING"
    const isProduction = env.ENVIRONMENT === "PRODUCTION"
    if((isStaging && env.TRACKING.SENTRY_DSN_STAGING !== null) || (isProduction && env.TRACKING.SENTRY_DSN_PRODUCTION !== null)) {
        const sentryDsn = isStaging ? env.TRACKING.SENTRY_DSN_STAGING : env.TRACKING.SENTRY_DSN_PRODUCTION;
        Sentry.init({
            dsn: sentryDsn,
            debug: isStaging
        });
    }
};

export const initiateGoogleTagManager = () => {
    if(env.ENVIRONMENT !== "DEVELOPMENT" && env.TRACKING.GOOGLE_TAG_MANAGER_ID !== null) {
        TagManager.initialize({gtmId: env.TRACKING.GOOGLE_TAG_MANAGER_ID})
    }
};

export const initiateGoogleAnalytics = () => {
    if(env.ENVIRONMENT !== "DEVELOPMENT" && env.TRACKING.GOOGLE_ANALYTICS_ID !== null) {
        ReactGA.initialize(env.TRACKING.GOOGLE_ANALYTICS_ID);
    }
};

export const initiateMixpanel = () => {
    if(env.ENVIRONMENT !== "DEVELOPMENT" && env.TRACKING.MIXPANEL_ID !== null) {
        mixpanel.init(env.TRACKING.MIXPANEL_ID);
    }
};

export const initiateFacebookPixel = () => {
    if(env.ENVIRONMENT !== "DEVELOPMENT" && env.TRACKING.FACEBOOK_ID !== null) {
        fbq("init", env.TRACKING.FACEBOOK_ID); //, {"em": email});
    }
};

export const initiateFacebookPixelAdditionalInfo = async (infoObject) => {
    if(env.ENVIRONMENT !== "DEVELOPMENT" && env.TRACKING.FACEBOOK_ID !== null) {
        fbq("init", env.TRACKING.FACEBOOK_ID, infoObject);
        // https://developers.facebook.com/docs/meta-pixel/advanced/advanced-matching/
    }
};

const initiateFullstory = async (user) => {
    if(env.ENVIRONMENT !== "DEVELOPMENT" && env.TRACKING.FULLSTORY_ORG_ID !== null) {
        FullStoryAPI('identify', user.get("userId"), {...user.toJS(), "environment": env.ENVIRONMENT});
    }
};

export const initiateFullstoryVars = async (vars) => {
    if(env.ENVIRONMENT !== "DEVELOPMENT" && env.TRACKING.FULLSTORY_ORG_ID !== null) {
        FullStoryAPI('setUserVars', vars);
    }
};

export const initiateTaboola = () => {
    if(env.ENVIRONMENT !== "DEVELOPMENT" && env.TRACKING.TABOOLA !== null) {
        TaboolaTracking.initialize(env.TRACKING.TABOOLA);
    }
};

export const tryPostMixpanel = (emailString, anonId, eventString, contentObj) => {
    return async dispatch => {
        if(env.ENVIRONMENT !== "DEVELOPMENT" && env.TRACKING.MIXPANEL_ID !== null) {
            axiosReq('apiv1/mixpanel', "POST", {"userEmail": emailString, "anonId": anonId, "event": eventString, "environment": env.ENVIRONMENT, ...contentObj}, {});
        }
    }
};

export const tryPostMailchimp = (contentObj) => {
    return async dispatch => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            // Turn off for demos
            // axiosReq('apiv1/mailchimp/save-tags', "POST", contentObj, {})
        }
    }
}

// export const trackLoginPublicPage = (user) => {
//     return async dispatch => {
//         dispatch(tryPostMixpanel(user.get("email"), "Login"));
//         await initiateFacebookPixel(user.get("email"));
//         return FullStoryAPI('identify', user.get("uid"), {...user.toJS()});
//     }
// };

// export const trackPageView = () => {
//     return async (dispatch, getState) => {
//         if(getEnvironment() === "prod") {
//             const user = getState().common.user;
//             await initiateFacebookPixel(user.get("email"));
//             fbq('track', 'PageView')
//         }
//     }
// };

export const trackLogin = () => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // if(!user.get("newUser")) {
                // dispatch(tryPostMixpanel(user.get("email"), "Login"));
                // await initiateFacebookPixel(user.get("email"));
                console.log("Login")
                fbq('trackCustom', 'Login')
                await initiateFullstory(user);
                Sentry.configureScope(scope => {scope.setUser({ id: user.get("userId") });});
                ReactGA.event({category: "User", action: "Login"});
                FullStoryAPI("event", "Login");
            // }
        }
    }
};

export const trackRegistration = () => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // if (user.get("newUser")) {
            //     await mixpanel.alias(user.get("userId"));
            //     // await mixpanel.register({'Email': user.get("email")});
                // dispatch(tryPostMixpanel(user.get("email"), "CompleteRegistration"));
                // await initiateFacebookPixel(user.get("email"));
                // fbq('trackCustom', 'Signup')
                fbq('track', 'CompleteRegistration')
                await initiateFullstory(user);
                Sentry.configureScope(scope => {scope.setUser({ id: user.get("userId") });});
                ReactGA.event({category: "User", action: "CompleteRegistration"});
                FullStoryAPI("event", "CompleteRegistration");
                // TaboolaTracking.trackEvent("CompleteRegistration");
            // }
        }
    }
};

// export const trackProfileSetUp = () => {
//     return (dispatch, getState) => {
//         if(env.ENVIRONMENT !== "DEVELOPMENT") {
//             const user = getState().common.user;
//             dispatch(tryPostMixpanel(user.get("email"), "ProfileSetUp"));
//             FullStoryAPI("event", "ProfileSetUp");
//         }
//     }
// };

// export const trackPhoneVerified = () => {
//     return (dispatch, getState) => {
//         if(env.ENVIRONMENT !== "DEVELOPMENT") {
//             const user = getState().common.user;
//             dispatch(tryPostMixpanel(user.get("email"), "PhoneVerified"));
//             FullStoryAPI("event", "PhoneVerified");
//         }
//     }
// };

// export const trackAvailabilityComplimentaryCall = (coachDetails) => {
//     return async (dispatch, getState) => {
//         if(env.ENVIRONMENT !== "DEVELOPMENT") {
//             const user = getState().common.user;
//             // await mixpanel.register({'Email': user.get("email")});
//             dispatch(tryPostMixpanel(user.get("email"), "AvailabilityComplimentaryCall"), coachDetails);
//             FullStoryAPI("event", "AvailabilityComplimentaryCall");
//         }
//     }
// };

// export const trackAvailabilityPaidCall = (coachDetails) => {
//     return async (dispatch, getState) => {
//         if(env.ENVIRONMENT !== "DEVELOPMENT") {
//             const user = getState().common.user;
//             // await mixpanel.register({'Email': user.get("email")});
//             dispatch(tryPostMixpanel(user.get("email"), "AvailabilityPaidCall"), coachDetails);
//             FullStoryAPI("event", "AvailabilityPaidCall");
//         }
//     }
// };

export const trackScheduleComplimentaryCall = (coachDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "ScheduleAComplimentaryCall", coachDetails));
            fbq('track', 'Schedule')
            ReactGA.event({category: "Trial", action: "ScheduleAComplimentaryCall"});
            FullStoryAPI("event", "ScheduleAComplimentaryCall");
        }
    }
};

export const trackSchedulePaidCall = (coachDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "SchedulePaidCall", coachDetails));
            fbq('track', 'Schedule')
            ReactGA.event({category: "Trial", action: "SchedulePaidCall"});
            FullStoryAPI("event", "SchedulePaidCall");
        }
    }
};

export const trackViewGetCareCoachParentJourneys = () => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "ViewCoachJourneys"));
            FullStoryAPI("event", "ViewCoachJourneys");
        }
    }
}

export const trackViewGetCareCoachSubJourneys = (parentJourneyDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "ViewCoachSubJourneys", parentJourneyDetails));
            FullStoryAPI("event", "ViewCoachSubJourneys");
        }
    }
}

export const trackViewGetCareCoachProfile = (coachDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "ViewCoachProfileComplimentaryCall", coachDetails));
            FullStoryAPI("event", "ViewCoachProfileComplimentaryCall");
        }
    }
}

export const trackViewLearnParentJourneys = () => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "ViewContentJourneys"));
            FullStoryAPI("event", "ViewContentJourneys");
        }
    }
}

export const trackViewLearnSubJourneys = (contentDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "ViewContentSubJourneys", contentDetails));
            FullStoryAPI("event", "ViewContentSubJourneys");
        }
    }
}

export const trackViewProgramCatalogue = () => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "ViewProgramCatalogue"));
            FullStoryAPI("event", "ViewProgramCatalogue");
        }
    }
}

export const trackViewProductsToPurchase = () => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "ViewProductsToPurchase"));
            FullStoryAPI("event", "ViewProductsToPurchase");
        }
    }
}

export const trackCheckoutProducts = (checkoutDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "Checkout", checkoutDetails));
            FullStoryAPI("event", "Checkout");
        }
    }
}

export const trackPurchaseProducts = (purchaseDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "PurchaseComplete", purchaseDetails));
            FullStoryAPI("event", "PurchaseComplete");
        }
    }
}

export const trackBookingProfilePage = (stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "BookedFromProfilePage", stepDetails));
            FullStoryAPI("event", "BookedFromProfilePage");
        }
    }
}

export const trackStepOne = (stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "StepOneComplete", stepDetails));
            FullStoryAPI("event", "StepOneComplete");
        }
    }
}

export const trackStepTwo = (stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "StepTwoComplete", stepDetails));
            FullStoryAPI("event", "StepTwoComplete");
        }
    }
}

export const trackStepThree = (stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "StepThreeComplete", stepDetails));
            FullStoryAPI("event", "StepThreeComplete");
        }
    }
}

export const trackStepFour = (stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "StepFourComplete", stepDetails));
            FullStoryAPI("event", "StepFourComplete");
        }
    }
}

export const trackStepZero = (stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "StepZeroComplete", stepDetails));
            FullStoryAPI("event", "StepZeroComplete");
        }
    }
}


export const trackStepZeroCheckout = (stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "StepZeroCheckoutComplete", stepDetails));
            FullStoryAPI("event", "StepZeroCheckoutComplete");
        }
    }
}

export const trackStepListBecomeMember = () => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "StepListStepZero"));
            FullStoryAPI("event", "StepListStepZero");
        }
    }
}

export const trackStepListStepOne = (stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "StepListStepOne", stepDetails));
            FullStoryAPI("event", "StepListStepOne");
        }
    }
}

export const trackStepListStepTwo = (stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "StepListStepTwo", stepDetails));
            FullStoryAPI("event", "StepListStepTwo");
        }
    }
}

export const trackStepListStepThree = (stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "StepListStepThree", stepDetails));
            FullStoryAPI("event", "StepListStepThree");
        }
    }
}

export const trackStepListStepFour = (stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "StepListStepFour", stepDetails));
            FullStoryAPI("event", "StepListStepFour");
        }
    }
}

export const trackTasksBecomeMember = () => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "TasksBecomeMember"));
            FullStoryAPI("event", "TasksBecomeMember");
        }
    }
}

export const trackCertificationCompletion = (stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // await mixpanel.register({'Email': user.get("email")});
            dispatch(tryPostMixpanel(user.get("email"), null, "CertificationComplete", stepDetails));
            FullStoryAPI("event", "CertificationComplete");
        }
    }
}


export const trackInitiateCheckout = () => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const checkoutData = getState().common.program.get("programCheckoutData");
            // await mixpanel.register({'Email': checkoutData.get("email")});
            // dispatch(tryPostMixpanel(checkoutData.get("email"), "InitiateCheckout"));
            // await initiateFacebookPixel(checkoutData.get("email"));
            fbq('track', "InitiateCheckout", {"currency": "USD", "value": (checkoutData.get("productCost")/100), "product_catalog_id": 565116004159828, "content_type": "product", "contents": [{"id": checkoutData.get("priceId"), "quantity": 1}]});
            ReactGA.event({category: "Subscribe", action: "InitiateCheckout", label: checkoutData.get("priceId"), value: (checkoutData.get("productCost")/100)});
            FullStoryAPI("event", "InitiateCheckout", {"value": (checkoutData.get("productCost")/100), "priceId": checkoutData.get("priceId")});
        }
    }
};

export const trackPurchase = () => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const purchaseData = getState().common.program.get("programReceiptData");
            // await mixpanel.register({'Email': purchaseData.get("email")});
            // dispatch(tryPostMixpanel(purchaseData.get("email"), "Purchase"));
            // await initiateFacebookPixel(purchaseData.get("email"));
            fbq('track', "Purchase", {"currency": "USD", "value": (purchaseData.get("productCost")/100), "product_catalog_id": 565116004159828, "content_type": "product", "contents": [{"id": purchaseData.get("priceId"), "quantity": 1}]});
            ReactGA.event({category: "Subscribe", action: "Purchase", label: purchaseData.get("priceId"), value: (purchaseData.get("productCost")/100)});
            FullStoryAPI("event", "Purchase", {"value": (purchaseData.get("productCost")/100), "priceId": purchaseData.get("priceId")});
        }
    }
};

export const trackLogout = () => {
    return (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            // dispatch(tryPostMixpanel(user.get("email"), "Logout"));
            return FullStoryAPI('identify', false);
        }
    }
};

export const trackMarketingStepZeroStart = (anonId, stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            dispatch(tryPostMixpanel(null, anonId, "MarketingStepZeroStart", stepDetails));
            FullStoryAPI("event", "MarketingStepZeroStart");
        }
    }
}
export const trackMarketingStepOneCalendar = (anonId, stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            dispatch(tryPostMixpanel(null, anonId, "MarketingStepOneCalendar", stepDetails));
            FullStoryAPI("event", "MarketingStepOneCalendar");
        }
    }
}

export const trackMarketingStepTwoJourney = (anonId, stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            dispatch(tryPostMixpanel(null, anonId, "MarketingStepTwoJourney", stepDetails));
            FullStoryAPI("event", "MarketingStepTwoJourney");
        }
    }
}
export const trackMarketingStepThreeAssets = (anonId, stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            dispatch(tryPostMixpanel(null, anonId, "MarketingStepThreeAssets", stepDetails));
            FullStoryAPI("event", "MarketingStepThreeAssets");
        }
    }
}
export const trackMergeAnonIdAndEmail = (anonId) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            axiosReq('apiv1/mixpanel/identify', "POST", {"userEmail": user.get("email"), "anonId": anonId}, {});
        }
    }
}

export const trackScheduleMarketingCall = (stepDetails) => {
    return async (dispatch, getState) => {
        if(env.ENVIRONMENT !== "DEVELOPMENT") {
            const user = getState().common.user;
            const coachDetails = {
                "coachId": 40,
                "coachName": "Candice McGarvey, CFP®"
            }
            dispatch(tryPostMixpanel(user.get("email"), null, "ScheduleMarketingCall", {...stepDetails, ...coachDetails}));
            await initiateFacebookPixelAdditionalInfo({em: user.get("email"), fn: user.get("first")});
            fbq('track', 'Schedule')
            ReactGA.event({category: "Trial", action: "ScheduleMarketingCall"});
            FullStoryAPI("event", "ScheduleMarketingCall");
        }
    }
};
