import React from 'react';
import styled from 'styled-components';
import {Link, withRouter} from 'react-router-dom';

/* Components */
import {FontTitle40, FontBody21} from "../../../../common/components/fonts";
import {colors} from "../../../../common/components/colors";

/* Domain */
import getDomainEnv from '../../../../domains/utils';
const env = getDomainEnv();

const Photo = styled.img`
    width: 130px;
    height: 130px;
    border-radius: 130px;
    border: 1px solid ${colors.border100};
    object-fit: cover;
`;
const Name = styled(FontTitle40)`
    color: ${colors.action100};
`;
const CoachRecord = styled(Link)`
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    column-gap: 30px;
    flex-wrap: wrap;
    cursor: pointer;
    color: ${colors.primary100};
    width: fit-content;
    align-items: center;
`;

class FirmCoachPreview extends React.Component {
   static defaultProps = {
       coach: {}
   }

    render() {
        return(
            <CoachRecord to={(this.props.location.pathname.includes("/my-certificates") ? "/my-certificates" : "") + "/my-coaches/"+this.props.coach.coachId}>
                <Photo src={this.props.coach.photo} alt={""}/>
                <div>
                    <Name>{this.props.coach.first} {this.props.coach.last}</Name>
                    <FontBody21>{env.ENTERPRISE.COMPANY.CARE.PRINT_EXPERT_CATEGORIES(this.props.coach.expertCategories, true)}</FontBody21>
                </div>
            </CoachRecord>
        )
    }
}

export default withRouter(FirmCoachPreview);
