import React, { useState, useEffect, preload } from "react";
import styled from "styled-components";

const Container = styled.div`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 30%;
    transition: background-image 0.5s ease-in-out;
    -webkit-transition: background-image 0.5s ease-in-out;

    height: 100%;
    width: 100%;
    aspect-ratio: 16 / 9;
`;

Photo.defaultProps = {
    photos: [],
};

export default function Photo(props) {
    const intervalRef = React.useRef(null);
    const [count, setCount] = useState(0);

    const preloadImage = (url) => {
        const img = new Image();
        img.src = url;
    };

    const updateCount = () => {
        if (!intervalRef.current) {
            console.log("setting interval");
            
            intervalRef.current = setInterval(() => {
                setCount((prevCount) => (prevCount + 1 >= props.photos.length ? 0 : prevCount + 1));
            }, 5000);
        }
    };

    useEffect(() => {
        props.photos.forEach((photo) => preloadImage(photo));
        updateCount();
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        };
    }, []);

    return <Container style={{ backgroundImage: `url(${props.photos[count]})` }} />;
}