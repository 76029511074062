import React from 'react';
import styled from 'styled-components';
import {connect, useSelector} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import { fromJS } from 'immutable';

/* Components */
import {colors} from '../../../../common/components/colors';
import {LogoLoading} from "../../../../common/components/loading";
import FirmCoachRecordIntro from "./recordintro";
import {FontBody24} from "../../../../common/components/fonts";
import {images} from "../../../../common/components/images";

/* Containers */
import FirmCoachRecordSharedDocuments from './shareddocs';
import IframeAbout from "../../../../iframes/profile/containers/about";
import FirmCoachRecordOverview from "./recordoverview";
import SchedulerFlow from "../../../../iframes/scheduler/containers/flow";
import SMSById from "../../../../iframes/smsbyid/containers/smscompose";

/* Middleware */
import {tryGetAllMyCoaches, tryGetCoachProfile, tryPostCreateProspectRecord} from "../middleware/coaches";
import {trackScheduleComplimentaryCall, trackSchedulePaidCall, /*trackAvailabilityComplimentaryCall, trackAvailabilityPaidCall*/} from "../../../../common/utils/tracking";
import {certificationValidation} from "../../certification/middleware/certificationValidation";

/* Store */
import {setIsLily} from "../../../../routes/store/user";

/* Domain */
import getDomainEnv from '../../../../domains/utils';
const env = getDomainEnv();

const Foreground = styled.div`
  position: relative;
  z-index: 2;
`;
const LoadingCenter = styled.div`
  margin: 60px auto;
  text-align: center;
`;
const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.white};
  z-index: 1;
`;
const Back = styled(Link)`
  width: fit-content;
  color: ${colors.primary100};
`;
const SMSContainer = styled.div`
  margin: 50px 24px 0 24px;
  @media screen and (max-width: 748px) {
    margin: 50px 0 0 0;
  }
`;

class FirmCoachRecord extends React.Component {
    state = {
        'profile': {},
        'categories': "",
        "isNew": true,
        "isGettingCertified": false,
        "isLoading": true,
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const validation = await this.props.certificationValidation();
        const profile = await this.coachProfile();
        const isNew = await this.coachCheckIsNew(profile);
        // if(isNew) {
        //     await this.props.history.push("/my-certificates/my-coaches/"+profile.id+"/profile");
        // }
        await this.setState({
            'profile': profile,
            'categories': await this.coachCategories(profile),
            "isNew": isNew,
            "isGettingCertified": validation.currentProgress !== null,
            "isGettingCertifiedSessionVettingComplete": validation.currentProgress?.step2Pieces.sessionCompleted,
            "isLoading": false
        })
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if(prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname === ("/my-certificates/my-coaches/"+this.state.profile.id) && this.state.isNew) {
    //         this.props.history.push("/my-certificates/my-coaches");
    //     }
    // }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if(!prevProps.location.pathname.includes("appointment") && this.props.location.pathname.includes("appointment")) {
    //         const coachDetails = {
    //             "coachId": this.state.profile.id,
    //             "coachName": this.state.profile.firstName+" "+this.state.profile.lastName
    //         }
    //         if(this.state.isNew) {
    //             this.props.trackAvailabilityComplimentaryCall(coachDetails)
    //         } else {
    //             this.props.trackAvailabilityPaidCall(coachDetails)
    //         }
    //     }
    // }

    coachProfile = async () => {
        let path = window.location.pathname;
        let pathAdj = path.charAt(path.length-1) === "/" ? path.slice(0, -1) : path;
        let pathSplit = pathAdj.split("/");
        return this.props.tryGetCoachProfile(pathSplit[pathSplit.length-1]);
    }

    coachCategories = async (profile) => {
        return env.CONSUMER.CARE.PRINT_EXPERT_CATEGORIES(profile.expertCategories, true)
    }

    coachCheckIsNew = async (profile) => {
        const myCoaches = await this.props.tryGetAllMyCoaches();
        const myCoachesChecked = await myCoaches.filter(c => {return(c.coachId === profile.id)})
        return (myCoachesChecked.length === 0)
    }

    createProspect = async () => {
        await this.props.tryPostCreateProspectRecord(this.state.profile.id)
        await this.props.setIsLily();
    }

    confirmOnClose = () => {
        this.props.history.push((this.props.location.pathname.includes("/my-certificates") ? "/my-certificates" : "") + "/my-coaches/"+this.state.profile.id)
    }

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            if(this.props.location.pathname.includes("appointment")) {
                return(
                    <>
                        <Foreground>
                            <FontBody24><Back to={(this.props.location.pathname.includes("/my-certificates") ? "/my-certificates" : "") + "/my-coaches/"+this.state.profile.id+(this.state.isNew ? "/profile" : "")}><img src={images.chevronLeft} alt={"<"} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back</Back></FontBody24>
                            <SchedulerFlow
                                userId={this.state.profile.id}
                                userFirstName={this.state.profile.firstName}
                                userLastName={this.state.profile.lastName}
                                userPhotoUrl={this.state.profile.photo}
                                purchaseHistoryId={null}
                                isWillowSession={/*!this.state.isNew*/true}
                                eventCustomMinutes={/*this.state.isNew ? 15 : 45*/60}
                                availabilityCloseBtn={false}
                                availabilityOnClose={() => {}}
                                signupOnClose={this.createProspect}
                                confirmOnClose={this.confirmOnClose}
                                confirmOnScheduled={() => {
                                    const coachDetails = {
                                        "coachId": this.state.profile.id,
                                        "coachName": this.state.profile.firstName+" "+this.state.profile.lastName
                                    }
                                    // if(this.state.isNew) {
                                    //     this.props.trackScheduleComplimentaryCall(coachDetails)
                                    // } else {
                                        this.props.trackSchedulePaidCall(coachDetails)
                                    // }
                                }}
                                showArrowClose={false}
                                useNewDesign={true}
                                isAdvisor={true}
                                isAdvisorCertVetting={this.state.isGettingCertified && !this.state.isGettingCertifiedSessionVettingComplete}
                            />
                        </Foreground>
                        <Background />
                    </>
                )
            } else if(this.props.location.pathname.includes("messages")) {
                return(
                    <>
                        <Foreground>
                            <FontBody24><Back to={(this.props.location.pathname.includes("/my-certificates") ? "/my-certificates" : "") + "/my-coaches/"+this.state.profile.id}><img src={images.chevronLeft} alt={"<"} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back</Back></FontBody24>
                            <SMSContainer>
                                <SMSById
                                    showClose={false}
                                    viewInBar={false}
                                    recipientId={this.state.profile.id}
                                    recipientFirstName={this.state.profile.firstName}
                                    recipientLastName={this.state.profile.lastName}
                                    from={"coach"}
                                    to={"coach"}
                                />
                            </SMSContainer>
                        </Foreground>
                        <Background />
                    </>
                )
            } else {
                return (
                    <>
                        <Foreground>
                            <FirmCoachRecordIntro
                                profile={this.state.profile}
                                categories={this.state.categories}
                                isNew={this.state.isNew}
                            />
                            {(this.props.location.pathname.includes("documents") && !this.state.isNew) &&
                                <FirmCoachRecordSharedDocuments
                                    profile={this.state.profile}
                                />
                            }
                            {(this.props.location.pathname.includes("profile") || this.state.isNew) &&
                                <IframeAbout
                                    profile={fromJS(this.state.profile)}
                                    viewVerticalOnly={true}
                                    viewFromAdvisorDashboard={true}
                                />
                            }
                            {(!this.props.location.pathname.includes("documents") && !this.props.location.pathname.includes("profile") && !this.state.isNew) &&
                                <FirmCoachRecordOverview
                                    profile={this.state.profile}
                                />
                            }
                        </Foreground>
                        <Background />
                    </>
                )
            }
        }
    }
}

const mapDispatchToProps = dispatch => ({
    tryGetCoachProfile: (coachId) => dispatch(tryGetCoachProfile(coachId)),
    tryGetAllMyCoaches: () => dispatch(tryGetAllMyCoaches()),
    tryPostCreateProspectRecord: (coachId) => dispatch(tryPostCreateProspectRecord(coachId)),
    trackScheduleComplimentaryCall: (coachDetails) => dispatch(trackScheduleComplimentaryCall(coachDetails)),
    trackSchedulePaidCall: (coachDetails) => dispatch(trackSchedulePaidCall(coachDetails)),
    setIsLily: () => dispatch(setIsLily()),
    certificationValidation: () => dispatch(certificationValidation()),
    // trackAvailabilityComplimentaryCall: (coachDetails) => dispatch(trackAvailabilityComplimentaryCall(coachDetails)),
    // trackAvailabilityPaidCall: (coachDetails) => dispatch(trackAvailabilityPaidCall(coachDetails)),
});

export default connect(null, mapDispatchToProps)(withRouter(FirmCoachRecord));