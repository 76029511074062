import React, {useState, useRef, useCallback, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';

/* Components */
import {ButtonTertiary} from "../../../common/components/buttons";
import {
    FontTitle40,
    FontBody24,
    FontBody12,
    FontTitle32To27,
    FontBody21To16,
    FontBody16
} from "../../../common/components/fonts";
import {colors} from "../../../common/components/colors";
import {Section, PreviewBlack} from './components';
import {ContainerOutlined, AuditOutlined, DatabaseOutlined } from '@ant-design/icons';
import {images} from "../../../common/components/images";
const arrowDown = <svg viewBox={"0 0 40 40"}><path d="M19.8,34.2c-0.3,0-0.3-0.1-0.4-0.1L6.6,21.4c-0.1-0.1-0.1-0.1-0.1-0.4c0-0.3,0.1-0.3,0.1-0.4l1.5-1.5 C8.2,19.1,8.2,19,8.5,19s0.3,0.1,0.4,0.1l9.2,9.2V5.9c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.4-0.1H21 c0.2,0,0.4,0.1,0.5,0.1c0,0,0,0.1,0,0.2v22.4l9.2-9.2c0.1-0.1,0.1-0.1,0.4-0.1c0.3,0,0.3,0.1,0.4,0.1l1.5,1.5 c0.1,0.1,0.1,0.1,0.1,0.4c0,0.3-0.1,0.3-0.1,0.4L20.2,34.1C20.1,34.1,20.1,34.2,19.8,34.2z" /></svg>;
import {iconNames} from "../../../common/components/icons";
import getDomainEnv from "../../../domains/utils";

const env = getDomainEnv();

const Title = styled(FontTitle40)`
  font-size: 120px !important;
  padding:0;
  margin:0;
  @media screen and (max-width: 1680px) {  
    font-size: 90px !important;
  }
  line-height: 1;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  animation: ${({ faded }) => faded ? `fadeIn 1s ease-in-out` : `fadeIn 1s ease-in-out`};
`;
const Button = styled.div`
  width: fit-content;
  & > div, & > div > div {
    font-size: 22px;
    padding: 16px 22px;
    @media screen and (max-width: 1680px) {
      font-size: 16px !important;
      padding: 10px 16px;
    }
    background-color: ${colors.canvaGreen};
    border-color: ${colors.canvaGreen};
  }
  & > div {
    transition: transform 0.25s ease;
    &:hover {
      transform: scale(1.05);
    }
  }
`;
const Links = styled.div`
  display: flex;
  width:fit-content;
  height:100%;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 3;
  flex-grow:2;
  position: relative;
  gap:10px;
  align-items: center;
  justify-content: center;
`;
const Circle = styled.div`
    background-color: ${colors.canvaGray};
    border-radius: 100%;
    padding: 15px calc(15px - ${props => props.leftOffset}px) 15px calc(15px + ${props => props.leftOffset}px);
    height: 35px;
    width: 35px;
    font-size: 35px;
    line-height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${colors.canvaGreen};
    font-weight: bold;
    cursor: pointer;
    @media screen and (max-width: 1680px) {
        padding: 20px;
    }
    transition: transform 0.25s ease;
    &:hover {
        transform: scale(1.2);
    }
`;
const Pill = styled.div`
    padding: 15px 0;
    height: 35px;
    width: fit-content;
    font-size: 35px;
    line-height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: ${colors.canvaGold};
    & > div {
      color: ${colors.primary100};
    }
    font-weight: bold;
    @media screen and (max-width: 1680px) {
        padding: 20px;
    }
  
  .fid {
    cursor:pointer !important;
  }
`;
const Icon = styled.div`
  display: inline-block;
  vertical-align: middle;
  & svg {
    width: 28px;
    height: 30px;
    opacity: 0.7;
    & path {
      stroke: ${colors.white};
      fill: ${colors.white};
    }
  }
`;
const Fixed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: ${colors.black}B3;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const VideoBox = styled.div`
  position: relative;
  aspect-ratio: 16 / 9;
  width: 80%;
  height: auto;
`;
const Close = styled.div`
  position: absolute;
  top: -20px;
  right: -20px;
  padding: 10px;
  font-size: 50px;
  line-height: 50px;
  width: 50px;
  @media screen and (max-width: 720px) {
    top: -15px;
    right: -15px;
    padding: 5px;
    font-size: 40px;
    line-height: 40px;
    width: 40px;
  }
  background-color: ${colors.action100};
  color: ${colors.white};
  border-radius: 100%;
  z-index: 1;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: bold;
`;
const ImageIconBox = styled.div`
  text-align: center;
  cursor:pointer;
`;
const ImageIcon = styled.img`
  width:100px;
  height:100px;
  cursor: pointer;
`;

const LearnMore = styled.div`
    display: flex;
    border-radius: 10px;
    background-color: ${colors.background15};
    padding:10px;
    & a {
      text-decoration: none;
      font-weight: bold;
      color: ${colors.black};
    }
`;

const DescriptionBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  flex-shrink: 1.5;
  
`;

const Tooltip = styled.div`
  background-color: ${colors.primary100};
  
  width:100%;
  position: absolute;
  bottom: -40px;
  height:35px;
  border-radius:10px;
  padding:0.25em 0;
  justify-content: center;
  align-content: center;
  display:flex;
  
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    left: ${props => props.pos};
    right: auto;
    top: 0;
    border: 15px solid transparent;
    border-top: 0;
    border-bottom: 15px solid ${colors.primary100};
    transform: translate(-50%, calc(-100% - 0px));
  }

  ${FontBody16} {
    color:${colors.white};
    align-self: center;
    width: calc(100% - 40px);
    
  }
`;

Intro.defaultProps = {
    name: "",
    intro: {
        description: "",
        video: null
    },
    scrollToContact: () => {},
    scrollToExpertise: () => {},
    scrollToServices: () => {},
    scrollToFirm: () => {},
}

const StyledSection = styled(Section)`
  gap: 10px;
  width: calc(100% - 120px);
  padding: 20px 60px;
  @media screen and (max-width: 720px) {
    width: calc(100% - 10px);
    padding: 15px 7px;
  }
  @media screen and (max-width: 1680px) {
    width: calc(100% - 60px);
    padding: 10px 30px;
    gap: 5px;
  }

`;

const StyledPreviewBlack = styled(PreviewBlack)`
  line-height: 1.5;
  width:100%;
`;

export default function Intro(props) {
    const [showVideo, setShowVideo] = useState(false)
    const videoRef = useRef(null)
    const [showLearn, setLearn] = useState(false);
    const [readMore, openReadMore] = useState(false);


    const openVideo = () => {
        setShowVideo(true);
    }
    const closeVideo = () => {
        videoRef.current.pause();
        setShowVideo(false);
    }

    const isAnimal = props.intro.animal !== ""
    const animal = isAnimal ? images[iconNames.filter(icon => icon.includes(props.intro.animal))[0]] : ""
    const isZodiac = props.intro.zodiac !== ""
    const zodiac = isZodiac ? images[iconNames.filter(icon => icon.includes(props.intro.zodiac))[0]] : ""
    const isBirthOrder = props.intro.birthOrder !== ""
    const birthOrder = isBirthOrder ? images[iconNames.filter(icon => icon.includes(props.intro.birthOrder))[0]] : ""
    const isHobby = props.intro.hobby !== ""
    const hobby = isHobby ? images[iconNames.filter(icon => icon.includes(props.intro.hobby))[0]] : ""
    const isMBTI = props.intro.mbti !== ""
    const mbti = isMBTI ? images[iconNames.filter(icon => icon.includes(props.intro.mbti))[0]] : ""
    const [hoverAnimal, setHoverAnimal] = useState(false)
    const [hoverHobby, setHoverHobby] = useState(false)
    const [hoverMBTI, setHoverMBTI] = useState(false)
    const [hoverZodiac, setHoverZodiac] = useState(false)
    const [hoverBirthOrder, setHoverBirthOrder] = useState(false)

    const greetings = ['Hello', 'Hola', 'Howdy', 'Welcome']
    const [faded, setFaded] = useState(false);
    const [greeting, setGreeting] = useState('Hello')

    const shuffle = useCallback(() => {
        const index = Math.floor(Math.random() * greetings.length);
        setGreeting(greetings[index]);
        setFaded(false);


    }, []);

    useEffect(() => {
        const intervalID = setInterval(shuffle, 3000);
        setFaded(true);
        return () => clearInterval(intervalID);
    }, [shuffle])

    return (
        <StyledSection backgroundColor={colors.white}>
            <Title faded={faded}>{greeting}</Title>
            <FontTitle32To27>I am {props.name}!</FontTitle32To27>

             <Links>
                {isMBTI && <ImageIconBox onMouseEnter={() => setHoverMBTI(true)} onMouseLeave={() => setHoverMBTI(false)}>
                    <ImageIcon leftOffset={0} src={mbti} alt={"MBTI Icon"}  />
                </ImageIconBox> }
                {hoverMBTI && <Tooltip pos={zodiac !== "" ? '10%' : '13%'}><FontBody16>Myers-Briggs Personality Trait: {props.intro.mbti} </FontBody16></Tooltip>}
                {hoverHobby && <Tooltip pos={zodiac !== "" ? '30%' : '37%'}><FontBody16>Favorite Hobby: {props.intro.hobby} </FontBody16></Tooltip>}
                {isHobby && <ImageIconBox onMouseEnter={() => setHoverHobby(true)} onMouseLeave={() => setHoverHobby(false)}>
                    <ImageIcon leftOffset={0} src={hobby}alt={"Hobby Icon"} />
                </ImageIconBox> }
                {hoverAnimal && <Tooltip pos={zodiac !== "" ? '51%' : '63%'}><FontBody16>Favorite Animal: {props.intro.animal} </FontBody16></Tooltip>}
                {isAnimal && <ImageIconBox onMouseEnter={() => setHoverAnimal(true)} onMouseLeave={() => setHoverAnimal(false)}>
                    <ImageIcon leftOffset={0} src={animal} alt={"Animal Icon"} />
                </ImageIconBox> }
                {hoverBirthOrder && <Tooltip pos={zodiac !== "" ? '71%' : '87%'}><FontBody16>{props.intro.birthOrder} </FontBody16></Tooltip>}
                {isBirthOrder && <ImageIconBox onMouseEnter={() => setHoverBirthOrder(true)} onMouseLeave={() => setHoverBirthOrder(false)}>
                    <ImageIcon leftOffset={0} src={birthOrder} alt={"Birth Order Icon"} />
                </ImageIconBox> }
                {hoverZodiac && <Tooltip pos={'90%'}><FontBody16>{props.intro.zodiac} </FontBody16></Tooltip>}
                {isZodiac && <ImageIconBox onMouseEnter={() => setHoverZodiac(true)} onMouseLeave={() => setHoverZodiac(false)}>
                    <ImageIcon leftOffset={0}  src={zodiac} alt={"Zodiac Icon"} />
                </ImageIconBox> }



                {/*{props.intro.video !== null &&*/}
                {/*   <ImageIcon*/}
                {/*       onClick={openVideo} leftOffset={3}*/}
                {/*       src={images.videoIcon} alt={"video icon"}/>*/}
                {/*}*/}
                {/*    <ImageIconBox>*/}
                {/*        <ImageIcon onClick={props.scrollToExpertise} leftOffset={0}*/}
                {/*                   src={images.expertiseIcon} alt={"expertise icon"}/>*/}
                {/*        <FontBody12>EXPERTISE</FontBody12>*/}
                {/*    </ImageIconBox>*/}

                {/*    <ImageIconBox>*/}
                {/*        <ImageIcon*/}
                {/*            onClick={props.scrollToFirm} leftOffset={0}*/}
                {/*            src={images.homeIcon} alt={"firm icon"}/>*/}
                {/*        <FontBody12>FIRM</FontBody12>*/}
                {/*    </ImageIconBox>*/}

                {/*    /!*<ImageIcon*!/*/}
                {/*    /!*    onClick={props.scrollToServices} leftOffset={0}*!/*/}
                {/*    /!*    src={images.servicesIcon} alt={"services icon"}/>*!/*/}
                {/*<Pill style={{cursor:"pointer"}}*/}
                {/*    onClick={() => { let val = showLearn; setLearn(!val)}}*/}

                {/*>🛡&nbsp;<FontBody24>Fiduciary</FontBody24></Pill>*/}
                {/*{showLearn && <LearnMore><FontBody12>An investment adviser who is a fiduciary to their advisory clients has a fundamental obligation to act in the best interests of their clients and to provide investment advice in their clients' best interests. Fiduciaries owe their clients a duty of undivided loyalty and utmost good faith. - U.S. Securities and Exchange Commission, Nov. 23, 2010. For more information, click <a target={"_blank"} href={"https://www.sec.gov/divisions/investment/advoverview.htm#:~:text=As%20an%20investment%20adviser%2C%20you,loyalty%20and%20utmost%20good%20faith."}>here</a>.</FontBody12></LearnMore>}*/}

            </Links>

            <StyledPreviewBlack color={colors.primary100}>
                <>
                    {/*<strong>{props.name}</strong>*/}
                    {" "}
                    {props.intro.description.length < 380 || readMore ?
                        <p>{props.intro.description.split('\n').join('')}
                            {readMore && <StyledPreviewBlack style={{display:"inline"}} onClick={() => openReadMore(false)}> <span style={{textDecoration: "underline"}}>Show less</span></StyledPreviewBlack>}

                        </p>
                        :
                        <>
                            <p>{props.intro.description.substring(0, 380).split('\n').join('')}. . .
                                {!readMore && <StyledPreviewBlack style={{display:"inline"}} onClick={() => openReadMore(true)}> <span style={{textDecoration: "underline", fontFamily: ` ${env.STYLES.FONTS.header.fontFamily}`}}>Read more</span></StyledPreviewBlack>}
                            </p>

                        </>
                    }

                </>
            </StyledPreviewBlack>
            {/*<Button onClick={props.scrollToContact}>*/}
            {/*    <ButtonTertiary label={<>Get in touch <Icon>{arrowDown}</Icon></>} canSubmit={false}/>*/}
            {/*</Button>*/}
            {showVideo &&
                <Fixed onClick={closeVideo}>
                    <VideoBox onClick={closeVideo}>
                        <Close onClick={closeVideo}>&times;</Close>
                        <video ref={videoRef} src={props.intro.video} width={"100%"} height={"auto"} controls={true} />
                    </VideoBox>
                </Fixed>
            }
        </StyledSection>
    )
}
