import React from 'react';

import {SettingOutlined, ContainerOutlined, ReadOutlined, StarOutlined, ClockCircleOutlined, TagsOutlined, MessageOutlined, NotificationOutlined, LogoutOutlined, TeamOutlined, CalendarOutlined, FunnelPlotOutlined, UserOutlined, ScheduleOutlined, ContactsOutlined, ShopOutlined, FormatPainterOutlined, FolderAddOutlined, PaperClipOutlined, HomeOutlined, BulbOutlined} from "@ant-design/icons";
import images from '../styles/images';

const enterpriseNav = {
    logo: "/verify",
    view: [
        [
            {
                label: "Dashboard",
                href: "/dashboard",
                showWMC: true,
                iconActive: images.navFirmDashboardIconActive,
                iconInactive: images.navFirmDashboardIconInactive,
            },
        ],
        [
            {
                label: "Advisor Certificates",
                href: "/my-certificates",
                showWMC: true,
                iconActive: images.certificationIconActive,
                iconInactive: images.certificationIconInactive,
            },
            // {
            //     label: "Advisor Training",
            //     href: "/my-trainings",
            //     showWMC: true,
            //     iconActive: images.navFirmProgramsIconActive,
            //     iconInactive: images.navFirmProgramsIconInactive,
            // },
            // {
            //     label: "My Resources",
            //     href: "/my-resources",
            //     showWMC: true,
            //     iconActive: images.navFirmIntegrationsIconActive,
            //     iconInactive: images.navFirmIntegrationsIconInactive,
            // },

          /*  {
                label: "Advisor Resources",
                href: "/my-resources",
                showWMC: true,
                iconActive: images.navResourcesIconActive,
                iconInactive: images.navResourcesIconInactive,
            },*/
            // {
            //     label: "My Resources",
            //     href: "/my-resources",
            //     showWMC: true,
            //     iconActive: images.navFirmIntegrationsIconActive,
            //     iconInactive: images.navFirmIntegrationsIconInactive,
            // },
        // ],
        //
        // [
            {
               label: "Client Acquisition",
               href: "/my-growth",
               showWMC: true,
               iconActive: images.navFirmAcquisitionIconActive,
               iconInactive: images.navFirmAcquisitionIconInactive,
            },

            // {
            //     label: "Client Coaching",
            //     href: "/my-clients",
            //     showWMC: true,
            //     iconActive: images.navFirmEmployeesIconActive,
            //     iconInactive: images.navFirmEmployeesIconInactive,
            // },
            // {
            //     label: "Client Materials",
            //     href: "/client-materials",
            //     showWMC: true,
            //     iconActive: images.navFirmIntegrationsIconActive,
            //     iconInactive: images.navFirmIntegrationsIconInactive,
            // },
        ],
        // [
        //     {
        //         label: "Advisor Roundtables (beta)",
        //         href: "/my-roundtables",
        //         showWMC: true,
        //         iconActive: images.certificationIconActive,
        //         iconInactive: images.certificationIconInactive,
        //     },
        // ],
        [
            {
                label: "CE Library",
                href: "/ce-library",
                showWMC: true,
                iconActive: images.navFirmProgramsIconActive,
                iconInactive: images.navFirmProgramsIconInactive
            },
            {
                label: "Coaching",
                href: "/my-coaches",
                showWMC: true,
                iconActive: images.navFirmCoachingIconActive,
                iconInactive: images.navFirmCoachingIconInactive,
            },
        ],
        [
            // This is the old "Upgrade" page
            // {
            //     label: "Upgrade",
            //     href: "/upgrade",
            //     showWMC: true,
            //     iconActive: images.navFirmUpgradeIconActive,
            //     iconInactive: images.navFirmUpgradeIconInactive,
            // },
            // {
            //     label: "Reports",
            //     href: "/reports",
            //     showWMC: true,
            //     iconActive: images.navFirmDashboardIconActive,
            //     iconInactive: images.navFirmDashboardIconInactive,
            // },
            {
                label: "Settings",
                href: "/my-settings",
                showWMC: true,
                iconActive: images.navFirmSettingsIconActive,
                iconInactive: images.navFirmSettingsIconInactive,
            },
            {
                label: "Refer & Earn",
                href: "/refer",
                showWMC: true,
                iconActive: images.navFirmReferralIconActive,
                iconInactive: images.navFirmReferralIconInactive,
            },
            {
               label: "Upgrade",
               href: "/upgrade",
               showWMC: true,
               iconActive: images.navFirmUpgradeIconActive,
               iconInactive: images.navFirmUpgradeIconInactive,
            },
            {
                label: "Support",
                href: "modalSupport",
                showWMC: true,
                iconActive: images.navFirmSupportIconActive,
                iconInactive: images.navFirmSupportIconInactive,
            },
        ],
    ],
    main: [
        [
            {
                label: <><HomeOutlined />&nbsp;Home</>,
                href: "/home",
                onlyMobile: false,
                showWMC: false,
                showCoach: true,
                showCoachCategoryIds: [1, 2, 3, 4, 5, 9, 10, 11, 12, 55]
            },
            {
                label: <><TeamOutlined />&nbsp;My Team</>,
                href: "/team",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: []
            },
            {
                label: <><TeamOutlined />&nbsp;My Partners</>,
                href: "/partners",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: [1, 2, 3, 4, 5, 9, 10, 11, 12]
            },
            {
                label: <><TeamOutlined />&nbsp;My Firms</>,
                href: "/firms",
                onlyMobile: false,
                showWMC: false,
                showCoach: true,
                showCoachCategoryIds: [6, 7]
            },
            {
                label: <><StarOutlined />&nbsp;Experts</>,
                href: "/experts",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: []
            },
        ],
        [
            {
                label: <><UserOutlined />&nbsp;Clients</>,
                href: "/clients",
                onlyMobile: false,
                showWMC: false,
                showCoach: true,
                showCoachCategoryIds: [1, 2, 3, 4, 5, 9, 10, 11, 12, 55]
            },
            {
                label: <><CalendarOutlined />&nbsp;Meetings</>,
                href: "/meetings",
                onlyMobile: false,
                showWMC: false,
                showCoach: true,
                showCoachCategoryIds: "all"
            },
            {
                label: <><ReadOutlined />&nbsp;Resources</>,
                href: "/resources",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: [1, 4, 55]
            },
        ],
        [
            {
                label: <><SettingOutlined />&nbsp;Settings</>,
                href: "/settings/basic-info",
                onlyMobile: false,
                showWMC: false,
                showCoach: true,
                showCoachCategoryIds: "all"
            },
            {
                label: <><MessageOutlined />&nbsp;Support</>,
                href: "modalSupport",
                onlyMobile: false,
                showWMC: false,
                showCoach: true,
                showCoachCategoryIds: "all"
            },
        ],
        [
            {
                label: <><FunnelPlotOutlined />&nbsp;Prospects</>,
                href: "/prospects",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: "all"
            },
            {
                label: <><UserOutlined />&nbsp;Clients</>,
                href: "/clients",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: [1, 2, 3, 4, 5, 9, 10, 11, 12, 55]
            },
            {
                label: <><ContactsOutlined />&nbsp;Contacts</>,
                href: "/contacts",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: "all"
            }
        ],
        [
            {
                label: <><CalendarOutlined />&nbsp;Meetings</>,
                href: "/meetings",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: "all"
            },
            {
                label: <><ClockCircleOutlined />&nbsp;Availability</>,
                href: "/availability",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: "all"
            },
            // {
            //     label: <><ScheduleOutlined />&nbsp;Scheduler</>,
            //     href: "/scheduler",
            //     onlyMobile: false,
            //     showWMC: false,
            //     showCoach: true,
            //     showCoachCategoryIds: "all"
            // },

        ],
        [
            {
                label: <><TagsOutlined />&nbsp;Products</>,
                href: "/products",
                onlyMobile: false,
                showWMC: false,
                showCoach: true,
                showCoachCategoryIds: [56] // [1, 2, 3, 4, 5, 9, 10, 11, 12]
            },
            {
                label: <><ShopOutlined />&nbsp;Shop</>,
                href: "/shop",
                onlyMobile: false,
                showWMC: false,
                showCoach: true,
                showCoachCategoryIds: [56] // [1, 2, 3, 4, 5, 9, 10]
            },
            {
                label: <><MessageOutlined />&nbsp;Community</>,
                href: "/community",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: [1, 2, 3, 4, 5, 9, 10, 11, 12, 55]
            },
        ],
        [
            {
                label: <><BulbOutlined />&nbsp;Curriculum</>,
                href: "/curriculum",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: "all"
            },
            {
                label: <><FolderAddOutlined />&nbsp;Vault</>,
                href: "/vaults",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: "all"
            },
            {
                label: <><ReadOutlined />&nbsp;Resources</>,
                href: "/resources",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: [1, 4, 55]
            },
            // {
            //     label: "Emails",
            //     href: "/emails"
            // },
            // {
            //     label: "SMS",
            //     href: "/sms"
            // },
            // {
            //     label: "Notes",
            //     href: "/notes"
            // }

        ],
        [
            {
                label: <><ContainerOutlined />&nbsp;My Profile</>,
                href: "/profile",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: [1, 4, 9, 10, 11, 12, 55]
            },
            {
                label: <><SettingOutlined />&nbsp;Settings</>,
                href: "/settings/basic-info",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: "all"
            },

        ],
        // [
        //     {
        //         label: "Reports",
        //         href: "/reports"
        //     }
        // ],
        [
            // {
            //     label: "Create",
            //     href: "/create"
            // },
            // {
            //     label: "Search",
            //     href: "/search"
            // },
            // {
            //     label: <><SettingOutlined />&nbsp;Settings</>,
            //     href: "/settings/basic-info",
            //     onlyMobile: true,
            //     showWMC: false,
            //     showCoach: true,
            //     showCoachCategoryIds: "all"
            // },
            // {
            //     label: <><ContainerOutlined />&nbsp;Billing</>,
            //     href: "/billing",
            //     onlyMobile: true,
            //     showWMC: false,
            //     showCoach: true,
            //     showCoachCategoryIds: "all"
            // },
            {
                label: <><MessageOutlined />&nbsp;Support</>,
                href: "modalSupport",
                onlyMobile: false,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: "all"
            },
            {
                label: <><NotificationOutlined />&nbsp;Feedback</>,
                href: "modalFeedback",
                onlyMobile: true,
                showWMC: false,
                showCoach: false,
                showCoachCategoryIds: "all"
            },
            {
                label: <><LogoutOutlined />&nbsp;Log out</>,
                href: "/logout",
                onlyMobile: true,
                showWMC: false,
                showCoach: true,
                showCoachCategoryIds: "all"
            },
        ]
    ],
    settings: [
        {
            title: null,
            options: [
                {
                    label: <div style={{"fontSize": "16px", "fontWeight": "bold"}}>{"<- Home"}</div>,
                    href: "/verify",
                    onlyMobile: true,
                    showWMC: true,
                    showCoach: true,
                    showCoachCategoryIds: "all"
                },
            ],
            onlyMobile: true,
            showWMC: true,
            showCoach: true,
            showCoachCategoryIds: "all"
        },
        {
            title: "YOUR PREFERENCES",
            options: [
                {
                    label: "Basic info",
                    href: "/settings/basic-info",
                    onlyMobile: false,
                    showWMC: true,
                    showCoach: true,
                    showCoachCategoryIds: "all"
                },
                // {
                //     label: "Notifications",
                //     href: "/settings/notifications"
                // },
                {
                    label: "Security",
                    href: "/settings/security",
                    onlyMobile: false,
                    showWMC: true,
                    showCoach: true,
                    showCoachCategoryIds: "all"
                },
            ],
            onlyMobile: false,
            showWMC: true,
            showCoach: true,
            showCoachCategoryIds: "all"
        },
        {
            title: "ACCOUNT",
            options: [
                {
                    label: "Branding",
                    href: "/settings/branding",
                    onlyMobile: false,
                    showWMC: true,
                    showCoach: false,
                    showCoachCategoryIds: []
                },
                {
                    label: "Users",
                    href: "/settings/users",
                    onlyMobile: false,
                    showWMC: true,
                    showCoach: false,
                    showCoachCategoryIds: []
                },
                {
                    label: "Coaching",
                    href: "/settings/coaching",
                    onlyMobile: false,
                    showWMC: false,
                    showCoach: true,
                    showCoachCategoryIds: [1, 4, 55]
                },
                {
                    label: "My Profile",
                    href: "/settings/profile",
                    onlyMobile: false,
                    showWMC: false,
                    showCoach: true,
                    showCoachCategoryIds: [1, 4, 9, 10, 11, 12, 55]
                },
                // {
                //     label: "Billing",
                //     href: "/settings/billing",
                //     onlyMobile: false,
                //     showWMC: true,
                //     showCoach: true,
                //     showCoachCategoryIds: []
                // }
            ],
            onlyMobile: false,
            showWMC: true,
            showCoach: true,
            showCoachCategoryIds: "all"
        },
        {
            title: "TOOLS",
            options: [
                {
                    label: "Prospects",
                    href: "/settings/prospects",
                    onlyMobile: false,
                    showWMC: true,
                    showCoach: false,
                    showCoachCategoryIds: "all"
                },
                {
                    label: "Availability",
                    href: "/settings/availability/calendar",
                    onlyMobile: false,
                    showWMC: true,
                    showCoach: true,
                    showCoachCategoryIds: "all"
                },
                // {
                //     label: "Clients",
                //     href: "/settings/clients"
                // },
                // {
                //     label: "Meetings",
                //     href: "/settings/meetings"
                // },
                // {
                //     label: "My Willow Page",
                //     href: "/settings/my-willow-page"
                // }
            ],
            onlyMobile: false,
            showWMC: true,
            showCoach: true,
            showCoachCategoryIds: "all"
        }
    ]
};

export default enterpriseNav;
