import React from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';

/* Components */
// import {colors} from '../../../../common/components/colors';
// import {FontBody24, FontSubtitle40, FontTitle30} from '../../../../common/components/fonts';
// import {images} from '../../../../common/components/images';
import {LogoLoading} from "../../../../common/components/loading";

/* Containers */
// import FirmTrainingsNav from '../components/listnav';
import FirmTrainingsPreviewEvents from './previewevents';

/* Middleware */
import {tryGetPurchaseHistory} from "../../tasks/middleware/tasks";
import {FontBody24} from "../../../../common/components/fonts";
import {CertBack} from "../../certification/containers/previewevents";
import {images} from "../../../../common/components/images";

/* Store */
// import {setSupportOpen} from "../../../support/store/support";

// const TopSegment = styled.div`
//     background-image: url(${images.firmBackgroundTop});
//     background-position: top;
//     background-size: cover;
//     background-repeat: no-repeat;
//     position: relative;
//     left: -60px;
//     right: 0;
//     top: -90px;
//     bottom: 0;
//     padding: 90px 60px 0 60px;
//     width: 100%;
//     @media screen and (max-width: 748px) {
//         left: -30px;
//         right: -30px;
//         top: -30px;
//         bottom: 0;
//         padding: 30px 30px 0 30px;
//     }
// `;
// const Foreground = styled.div`
//     position: relative;
//     z-index: 2;
// `;
// const Subtitle = styled(FontBody24)`
//     margin: 30px 0 0 0;
//     min-height: 108px;
// `;
const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
// const Background = styled.div`
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: ${colors.white};
//     z-index: 1;
// `;
// const Upgrade = styled.div`
//     display: inline-block;
//     text-decoration: underline;
//     font-weight: bold;
//     color: ${colors.primary100};
//     cursor: pointer;
// `;
const IntroWithNav = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 60px;
`;

class FirmTrainingsList extends React.Component {
    static defaultProps = {
        pms_sessions: 0
    }

    state = {
        "isLoading": true,
        "cannotAccessAllTrainings": false
    }

    componentDidMount() {
        return this.init();
    }

    init = async () => {
        const subscription = await this.findSubscription();
        await this.setState({
            "cannotAccessAllTrainings": (subscription === null && !this.props.isReferredByPartner && !this.props.isReferredByPartnerWMC),
            "isLoading": false
        })
    }

    findSubscription = async () => {
        const purchases = await this.props.tryGetPurchaseHistory();
        const purchasesAdvisor = purchases.filter(p => {return(p.willowPlanId === "willow_ondemand" || p.willowPlanId === "willow_empowered")})
        if(purchasesAdvisor.length === 0) {
            return null
        } else {
            const purchasesAdvisorSorted = purchasesAdvisor.reduce((a, b) => a.purchasedOn > b.purchasedOn ? a : b)
            return purchasesAdvisorSorted.willowPlanId;
        }
    }

    // addMoreTrainings = () => {
    //     this.props.history.push("/my-trainings/add-trainings");
    // }

    // sendToCert = () => {
    //     this.props.history.push("/my-certificates/activate");
    // }

    // lookingForMore = () => {
    //     this.props.setSupportOpen(true, 6);
    //     // this.props.history.push("/support")
    // }

    render() {
        if(this.state.isLoading) {
            return(<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return (
                <>
                    {/*<Foreground>*/}
                    {/*    <TopSegment>*/}
                    {/*        <FontSubtitle40 spaced={true}>PRACTICE MANAGEMENT</FontSubtitle40>*/}
                    {/*        /!*<Subtitle>Register for continuing education designed to help you better serve women & the underrepresented. <Upgrade onClick={this.lookingForMore}><b>Looking for another topic?</b></Upgrade></Subtitle>*!/*/}
                    {/*        {!this.state.cannotAccessAllTrainings*/}
                    {/*            ?*/}
                    {/*            <Subtitle>Complete continuing education to demonstrate that you are uniquely qualified to serve Women, NextGen, Underrepresented, and LGBTQ+ clients. Gain the skills essential to acquiring and growing trusted relationships with these clients. /!*<Upgrade onClick={this.sendToCert}><b> Get certified.</b></Upgrade>*!/</Subtitle>*/}
                    {/*            // <Subtitle>Register for continuing education designed to help you better serve tomorrow's clients. You have [{this.props.pms_sessions}] pre-paid trainings. <Upgrade onClick={this.addMoreTrainings}><b>Add more trainings.</b></Upgrade></Subtitle>*/}
                    {/*            : <Subtitle>Continuing education designed to help you better serve tomorrow's clients.</Subtitle>*/}
                    {/*        }*/}
                    {/*        <FirmTrainingsNav />*/}
                    {/*    </TopSegment>*/}
                    {/*    <FirmTrainingsPreviewEvents />*/}
                    {/*</Foreground>*/}
                    {/*<Background />*/}
                    <IntroWithNav>
                        <FontBody24 style={{"justifyContent":"space-evenly"}}><CertBack to={"/my-certificates"}><img src={images.chevronLeft} alt={"<"} /> Back</CertBack></FontBody24>
                        <FirmTrainingsPreviewEvents />
                    </IntroWithNav>
                </>
            )
        }
    }
}

const mapStateToProps = state => ({
    pms_sessions: state.common.user.get("pms_sessions"),
    isReferredByPartner: state.common.user.get("isReferredByPartner"),
    isReferredByPartnerWMC: state.common.wmc.get("wmc").get("isReferredByPartner"),
});


const mapDispatchToProps = dispatch => ({
    tryGetPurchaseHistory: () => dispatch(tryGetPurchaseHistory()),
    // setSupportOpen: (isOpen, page) => dispatch(setSupportOpen(isOpen, page))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirmTrainingsList));