import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/* Components */
import {
    FontSubtitle24,
    FontHeader16,
    FontTitle24,
    FontBody16,
    FontSubtitle16
} from "../../../../common/components/fonts";
import { images } from "../../../../common/components/images";
import { LogoLoading } from "../../../../common/components/loading";
import FirmMarketingProspectRecord from '../components/prospectrecord';

/* Middleware */
import { tryGetProspects, tryGetLeadConversation, tryUpdateStatus } from '../middleware/prospects';
import {Tooltip} from "../../../../iframes/advisorprofilev2/containers/intro";
import {ToolTipBody} from "../../../../common/components/messages";
import {colors} from "../../../../common/components/colors";
import {DropdownInput} from "../../../../common/components/inputs";
import {InfoCircleOutlined} from "@ant-design/icons";
import {ToolTipContainer} from "../../certification/components/directoryform";
import {
    Arrow,
    Background,
    Dropdown,
    DropdownList,
    DropdownRow,
    DropdownTitle,
    ListButton, Relative
} from "../components/prospectelements";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
`;
const ColProspectParent = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;





const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

export default function FirmMarketingProspects() {
    const [filter, setFilter] = useState("Pending")
    const [filterOpen, setFilterOpen] = useState(false)
    const [prospects, setProspects] = useState([])
    const [filtered, setFiltered] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [showPopup, setPopup] = useState(false)
    const [conversation, setConservation] = useState([])
    const [pending, setPending] = useState(["Lead information sent to advisor", "Follow up sent", "Follow up with advisor completed"])
    const [presentedCount, setPresentedCount] = useState(0)
    const [selectedCount, setSelectedCount] = useState(0)
    const [hoverMatched, setHoverMatched] = useState(false)
    const [hoverChosen, setHoverChosen] = useState(false)
    const [leadId, setLeadId] = useState(0)
    const [leadStatus, setLeadStatus] = useState("Pending")
    // state = {
    //     "filter": "Pending", // Pending, Won, Lost
    //     "filterOpen": false,
    //     "prospects": [],
    //     "filtered": [],
    //     "isLoading": true,
    //     "showPopup": false,
    //     "conversation": [],
    //     "pending": ["Lead information sent to advisor", "Follow up sent", "Follow up with advisor completed"]
    // }

    // componentDidMount() {
    //     this.init();
    // }

    useEffect(() => {
        console.log('use effect')
        init()
    }, [])
    const init = async () => {
        console.log('init')
        const prospects = await tryGetProspects();
        console.log('prospects')
        // prospects.timesShownToLeads
        setProspects(prospects?.leads ? prospects.leads : [])
        setFiltered(prospects?.leads && prospects?.leads.length > 0 ? prospects.leads.filter(p => pending.includes(p.status)) : [])
        setPresentedCount(prospects?.timesShownToLeads ? prospects?.timesShownToLeads : 0)
        setSelectedCount(prospects?.leads.filter((l) => l.status === "Lead information sent to advisor").length)
        setLoading(false)
        // this.setState({
        //     "prospects": prospects?.leads ? prospects.leads : [],
        //     "filtered": prospects?.leads && prospects?.leads.length > 0 ? prospects.leads.filter(p => this.state.pending.includes(p.status)) : [],
        //     "isLoading": false
        // })
    }

    const setShowPopup = (showPopup) => {
        setPopup(showPopup)
    }

    const setConversation = (conversation) => {
        setConversation(conversation)
    }

    const toggleFilterChange = (value) => {
        setFilter(value)
        setFiltered(filterProspectsByValue(value))
        setFilterOpen(false)
        // this.setState({
        //     "filter": value,
        //     "filtered": prospects && prospects?.length > 0 ?
        //         value === "Won" ? prospects.filter(p => (p.status === "Matched")) :
        //             value === "Lost" ? prospects.filter(p => (p.status === "Advisor did not win lead")) :
        //                 prospects.filter(p => pending.includes(p.status)) : [],
        //     "filterOpen": false
        // })
    }
    const filterProspectsByValue = (value) => {
        if (prospects && prospects.length > 0) {
            if (value === "Successfully Onboarded") {
                return prospects.filter(p => (p.status === "Matched"))
            } else if (value === "Not Ready to Move Forward") {
                return prospects.filter(p => p.status === "Call with lead completed / bad lead")
            } else if (value === "All") {
                return prospects
            } else if (value === "Not a Fit") {
                return prospects.filter(p => (p.status === "Advisor did not win lead"))
            } else if (value === "Pending") {
                return prospects.filter(p => p.status === "Received")
            } else if (value === "No Response Received") {
                return prospects.filter(p => p.status === "No answer/left message")
            }
            else {
                return []
            }
        }
    }
    const toggleFilterOpen = () => {
        setFilterOpen(true)
    }

    const toggleFilterClose = () => {
        setFilterOpen(false)
    }

    const updateStatus = (leadStatus) => {
        setLeadStatus(leadStatus)
    }
    const selectStatus = async (leadId, leadStatus) => {
        setLeadId(leadId)
        setLoading(true)
        await tryUpdateStatus(leadId, leadStatus);
        setLoading(false)
        //init()
    }

    const getConvo = async (userID) => {
        const APIConversation = await tryGetLeadConversation(userID);
        setConversation(APIConversation);
        init();
    }

        if (isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return (
                <>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:'2em'}}>
                        <ToolTipContainer>
                            <ToolTipBody show={hoverMatched} style={{left:"53%"}}>
                                <FontSubtitle16>
                                    A match occurs whenever your profile is viewed by a prospect who has completed the questionnaire.
                                </FontSubtitle16>
                            </ToolTipBody>
                            <FontSubtitle24>{presentedCount} Times Matched <span style={{ width: "50px", height: "50px", fontSize:"16px" }}
                                                                                 onMouseEnter={() => setHoverMatched(true)}
                                                                                 onMouseLeave={() => setHoverMatched(false)}>
                                <InfoCircleOutlined />{" "}
                            </span></FontSubtitle24>
                        </ToolTipContainer>
                        <ToolTipContainer>
                            <ToolTipBody show={hoverChosen} >
                                <FontSubtitle16>
                                    Chosen refers to when a prospect selects to share their information with you.
                                </FontSubtitle16>
                            </ToolTipBody>
                            <FontSubtitle24> {selectedCount} Times Chosen <span style={{ width: "25px", height: "25px", fontSize:"16px" }}
                                                                                onMouseEnter={() => setHoverChosen(true)}
                                                                                onMouseLeave={() => setHoverChosen(false)}>
                                <InfoCircleOutlined />{" "}
                            </span></FontSubtitle24>
                        </ToolTipContainer>
                    </div>
                    <hr/>
                    {prospects && prospects?.length > 0 ?
                        <div style={{marginTop: "3em"}}>
                            <Row>
                                <FontSubtitle24 spaced={true}>{filter.toUpperCase()} PROSPECTS ({filtered.length})</FontSubtitle24>
                                <Relative>
                                    <Dropdown onClick={toggleFilterOpen}>
                                        <DropdownRow>
                                            <DropdownTitle>{filter}</DropdownTitle>
                                            <Arrow />
                                        </DropdownRow>
                                    </Dropdown>
                                    {filterOpen &&
                                        <>
                                            <DropdownList>
                                                <ListButton onClick={() => toggleFilterChange("All")}>All</ListButton>
                                                <ListButton onClick={() => toggleFilterChange("Pending")}>Pending</ListButton>
                                                <ListButton onClick={() => toggleFilterChange("Successfully Onboarded")}>Successfully Onboarded</ListButton>
                                                <ListButton onClick={() => toggleFilterChange("Not a Fit")}>Not a Fit</ListButton>
                                                <ListButton onClick={() => toggleFilterChange("Not Ready to Move Forward")}>Not Ready to Move Forward</ListButton>
                                                <ListButton onClick={() => toggleFilterChange("No Response Received")}>No Response Received</ListButton>


                                            </DropdownList>
                                            <Background onClick={toggleFilterClose} />
                                        </>
                                    }
                                </Relative>
                            </Row>
                            <ColProspectParent>
                                {filtered.map((p, pIndex) => {
                                    if (p.id === leadId){
                                        return (
                                            <FirmMarketingProspectRecord
                                                key={pIndex}
                                                prospect={p}
                                                selectStatus={selectStatus}
                                                index={pIndex}
                                                getConvo={getConvo}
                                                showPopup={showPopup}
                                                setShowPopup={setShowPopup}
                                                conversation={conversation}
                                                leadStatus={leadStatus}
                                                updateStatus={updateStatus}
                                            />
                                        )
                                    }
                                    return (
                                        <FirmMarketingProspectRecord
                                            key={pIndex}
                                            prospect={p}
                                            selectStatus={selectStatus}
                                            index={pIndex}
                                            getConvo={getConvo}
                                            showPopup={showPopup}
                                            setShowPopup={setShowPopup}
                                            conversation={conversation}
                                            updateStatus={updateStatus}

                                        />
                                    )
                                })}
                            </ColProspectParent>
                        </div>
                        : <FontTitle24>No prospects found</FontTitle24>
                    }
                </>
            )
        }

}

// const mapDispatchToProps = dispatch => ({
//     // tryGetAdvisorProspects: () => dispatch(tryGetAdvisorProspects()),
//     // tryPostAdvisorProspectsAccept: () => dispatch(tryPostAdvisorProspectsAccept()),
//     tryGetProspects: () => dispatch(tryGetProspects()),
//     tryGetLeadConversation: (leadId) => dispatch(tryGetLeadConversation(leadId)),
//     tryUpdateStatus: (leadId, leadStatus) => dispatch(tryUpdateStatus(leadId, leadStatus))
// })

// export default connect(null, mapDispatchToProps)(withRouter(FirmMarketingProspects));