import styled from "styled-components";
import {FontHeader16} from "../../../../common/components/fonts";
import {images} from "../../../../common/components/images";

export const DropdownRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
`;

export const Dropdown = styled.div`
  padding: 11px 30px 9px 30px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  cursor: pointer;
`;

export const DropdownList = styled.div`
  padding: 20px 30px 15px 30px;
  box-shadow: 0 3px 10px 0 rgb(0, 0, 0, 25%);
  border-radius: 20px;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  background-color: white;
`;


export const ListButton = styled(FontHeader16)`
    padding: 7px 0;
    cursor: pointer;
`;
export const DropdownTitle = styled(FontHeader16)`
    width: 80px;
`;

export const Arrow = styled.div`
  background-image: url(${images.chevronDown});
  background-size: cover;
  background-repeat: no-repeat;
  width: 15px;
  height: 9px;
`;

export const Background = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  z-index: 1;
`;

export const Relative = styled.div`
    position: relative;
`;