import React from 'react';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { connect } from "react-redux";
import {Link, withRouter} from 'react-router-dom';
import Cookies from "js-cookie";
import {v4 as uuid4} from "uuid";
import ReactQuill from "react-quill";

/* Components */
import {fontFamily, FontBody14, FontBody24, FontBody16, FontTitle28, FontHeader18, FontSubtitle40, FontBody21, FontHeader21, FontBody18} from '../../../common/components/fonts';
import {ButtonPrimary, ButtonInactive, ButtonTertiary} from '../../../common/components/buttons';
import { LogoLoading } from "../../../common/components/loading";
import { colors } from '../../../common/components/colors';
import {SettingsCard, ActionBox, PurchaseBox, LoadingCenter} from '../components/details';
import {images} from "../../../common/components/images";
import {StyledModal} from "../../../enterprise/firm/certification/containers/steplist";

/* Domains */
import getDomainEnv from '../../../domains/utils';
const env = getDomainEnv();

/* Containers */
import ProgramDetailsLecture from './programdates';

/* Middleware */
import { tryGetProgramDetails, tryGetProgramRegistrationInfo } from '../middleware/programs'
import {tryAuthOnly} from "../../../routes/middleware/auth";
import {certificationValidation} from '../../../enterprise/firm/certification/middleware/certificationValidation';

const Red = styled(FontBody14)`
    color: ${colors.alert100};
`;
const ChevronLeftRight = styled.img`
    padding: 3.5px 7.5px 5.5px 7.5px;
    vertical-align: top;
    display: block;
    object-fit: contain;
`;
const InlineBlock = styled.div`
    display: inline-block;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-around;
    margin: 0 auto;
    ${props => props.entryPoint !== "advisor"} {
        max-width: 100%;
        position: relative;
    }
    ${props => props.entryPoint === "advisor"} {
        max-width: 450px;
    }
`;
const BackSectionAdvisor = styled.div`
    padding-top: 90px;
    padding-left: 60px;
`;
const BackAdvisor = styled(Link)` 
    width: fit-content;
    color: ${colors.white};
    & img {
        filter: brightness(0) invert(1);
    }
`;
const BackSection = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 10px;
`;
const Back = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
`;
const ContentTypeIcon = styled.img`
    height: 18px;
    width: 18px;
    vertical-align: top;
    object-fit: cover;
`;
const IntroSection = styled.div`
    background-color: ${props => props.backgroundColor};
    ${props => props.bottom !== true} {
        border-radius: 0px 42px 30px 30px;
    }
    ${props => props.bottom !== false} {
        border-radius: 0px 42px 0px 0px;
    }
    margin-top: -42px;
    padding: 20px 30px 57px 30px;
    width: calc(100% - 60px);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    &::before {
        content: "";
        position: absolute;
        background-color: transparent;
        top: -84px;
        height: 84px;
        width: 84px;
        left: 0px;
        border-top-left-radius: 42px;
        box-shadow: 0 -42px 0 0 ${props => props.backgroundColor};
        transform: rotate(180deg);
        transform: scaleY(-1);
    }
`;
const TypeSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    z-index: 0;
    ${props => props.entryPoint !== "advisor"} {
        padding: 10px 30px;
    }
    ${props => props.entryPoint === "advisor"} {
        padding: 0;
    }
`;
const QuillSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    z-index: 0;
    .ql-editor {
        font-family: ${fontFamily.body};
        font-size: 20px;
        line-height: 26px;
    }
    padding: 0 15px;
`;
const Relative = styled.div`
    position: relative;
    background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${props => props.image});
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 100px;
`;
const Title = styled.div`
    color: ${colors.white};
    font-weight: bold;
    filter: brightness(0) invert(1);
`;
const TitleAndButton = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 10px;
    padding: 60px;
    align-items: center;
`;
const Button = styled.div`
    width: fit-content;
    & div {
        font-size: 20px;
    }
    & > div > div > div {
      padding: 16px 42px;
    }
`;
const NoClick = styled.div`
    & div {
      cursor: default;
    }
`;
const DescriptionTitle = styled(FontTitle28)`
    color: ${colors.secondary100};
    margin: 40px 30px 0 30px;
`;
const CoachAuthor = styled(FontBody21)`
    margin: 0 30px 0 30px;
    font-size: 20px;
`;
const CoachTitle = styled(FontHeader21)`
    margin-bottom: 27px;
`;
const Photo = styled.img`
    width: 75px;
    height: 75px;
    border-radius: 75px;
    border: 1px solid ${colors.border100};
    object-fit: cover;
`;
const CoachRecord = styled.div`
    display: flex;
    flex-direction: row;
    row-gap: 10px;
    column-gap: 30px;
    flex-wrap: wrap;
    color: ${colors.primary100};
    width: fit-content;
    align-items: center;
`;
const CECredit = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    & img {
      height: 32px;
      align-items: center;
      justify-content: flex-start;
    }
`;
const ButtonComplete = styled.div`
    & div {
      cursor: default;
    }
`;
const Download = styled(FontBody16)`
    margin: 10px 0 0 30px;
    & a {
      text-decoration: underline;
      color: ${colors.action100};
      font-weight: bold;
    }
`;
const SpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;;
  align-items: center;
  margin-top: 20px;
`;


class GroupProgramDetails extends React.Component {
    static defaultProps = {
        back: () => {},
        programId: null,
        error: null,
        onTryRegister: () => {},
        entryPoint: "consumer",
        openToCheckout: false,
        onInitProductData: () => {},
        urlRequest: "/programs/request",
        urlCourse: null,
        urlQuiz: null,
        isCourseCompleted: null,
        isQuizCompleted: null,
        isCourseAssigned: false,
        courseData: null,
        shouldAllowToTakeCert: true
    };

    state = {
        "isLoading": true,
        "isRegistrationInProgress": false,
        "existingRegistration": null,
        "error": null,
        "webinarOpen": null,
        "ceCredits": 0,
        "isPromotionalProgram": false,
        "showModal": false
    };

    componentDidMount() {
        return this.init();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.isRegistrationInProgress !== this.props.isRegistrationInProgress) {
            this.setState({"isRegistrationInProgress": this.props.isRegistrationInProgress})
        }
        if (prevProps.existingRegistration !== this.props.existingRegistration) {
            this.setState({"existingRegistration": this.props.existingRegistration})
        }
        if(prevProps.error !== this.props.error) {
            this.setState({"error": this.props.error})
        }
    }

    init = async () => {
        await this.props.tryGetProgramDetails(this.props.programId);
        this.props.onInitProductData(this.props.groupProgramDetails.toJS());
        const user = await this.props.tryAuthOnly(null, false, false);
        if(user !== null) {
            await this.props.tryGetProgramRegistrationInfo(this.props.programId);
        }
        let sortedLectures;
        if(this.props.groupProgramDetails.get("isPromotionalProgram")) {
            sortedLectures = this.props.groupProgramDetails.get("lectures");
        } else {
            sortedLectures = await this.props.groupProgramDetails.get("lectures").sort((a, b) => {return(new Date(a.get("flashSaleStartDateTime")) - new Date(b.get("flashSaleStartDateTime")))});
        }
        await this.setState({
            "isRegistrationInProgress": this.props.isRegistrationInProgress,
            "existingRegistration": this.props.existingRegistration,
            "dateString": this.calcDateString(this.props.groupProgramDetails.get("lectures").toJS()),
            "groupProgramDetails": await this.props.groupProgramDetails,
            "isPromotionalProgram": this.props.groupProgramDetails.get("isPromotionalProgram"),
            "lectures": sortedLectures,
            "webinarOpen": sortedLectures.get(0).get("id"),
            "error": this.props.error,
            "ceCredits": this.props.groupProgramDetails.get("ceCredits"),
            "price": (this.props.groupProgramDetails.get("price") === 0 || this.props.groupProgramDetails.get("price") === null)
                ? "Price: Free"
                : "Price: $"+(this.props.groupProgramDetails.get("price") / 100).toFixed(0).toString(),
            'isLoading': false,
        });
        if(this.props.openToCheckout) {
            await this.props.onTryRegister(this.props.groupProgramDetails.get("price"));
        }
    };

    calcDateString = (lectures) => {
        const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (lectures.length === 0)
            return null;

        const sortedLectures = lectures.sort((l1, l2) => l1.flashSaleEndDateTime - l2.flashSaleEndDateTime);
        const startLectureDateTime = moment(sortedLectures[0].flashSaleEndDateTime);
        const endLectureDateTime = moment(sortedLectures[sortedLectures.length - 1].flashSaleEndDateTime);

        if (lectures.length === 1){
            const timeZoneAdjustedStart = startLectureDateTime.tz(browserTimeZone);
            let startPart = timeZoneAdjustedStart.format("MMMM Do, YYYY@H:mm:a");
            let endPart = timeZoneAdjustedStart.format("h:mm:a z");
            return `${startPart} - ${endPart}`;
        }

        const shouldPrintStartYear = startLectureDateTime.year() !== endLectureDateTime.year()
        const shouldPrintEndMonth = !shouldPrintEndYear && startLectureDateTime.month() === endLectureDateTime.month()

        let startPart = shouldPrintStartYear ? startLectureDateTime.format("MMMM Do, YYYY") : startLectureDateTime.format("MMMM Do");
        let endPart = shouldPrintEndMonth ? endLectureDateTime.format("MMMM Do, YYYY") : endLectureDateTime.format("Do, YYYY");
        return `${startPart} - ${endPart}`;
    };

    webinarOpenStatus = (id) => {
        this.setState({"webinarOpen": this.state.webinarOpen === id ? null : id})
    };

    clickNewVideo = async () => {
        // const validation = await this.props.certificationValidation();
        // if(this.props.programId !== "62" && validation.currentProgress !== null && !validation.currentProgress?.step1Pieces.mainTrainingsCompleted) {
        //     this.setState({"showModal":true})
        // } else {
            this.props.history.push(this.props.urlCourse);
        // }
    }

    render() {
        if (this.state.isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return (
                <Container entryPoint={this.props.entryPoint}>
                    {this.props.entryPoint !== "advisor" &&
                        <BackSection>
                            <Back onClick={this.props.back}>
                                <ChevronLeftRight src={images.chevronLeft} alt={""} />
                                <InlineBlock><FontBody16>{/*"Group programs"*/}</FontBody16></InlineBlock>
                            </Back>
                        </BackSection>
                    }
                    <SettingsCard entryPoint={this.props.entryPoint}>
                        <Relative image={this.state.groupProgramDetails.get("imageUrl")}>
                            {this.props.entryPoint === "advisor" &&
                                <BackSectionAdvisor>
                                    <FontBody24><BackAdvisor to={location.pathname.includes("/my-certificates/my-trainings") ? "/my-certificates": "/ce-library"
                                    }><img src={images.chevronLeft} alt={"<"} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Back</BackAdvisor></FontBody24>
                                </BackSectionAdvisor>
                            }
                            <TitleAndButton>
                                <Title><FontSubtitle40 spaced={true}>{this.state.groupProgramDetails.get('title').toUpperCase()}</FontSubtitle40></Title>
                                {this.props.entryPoint === "advisor" &&
                                    <Button>
                                        {this.state.isPromotionalProgram
                                            ? <>
                                                {this.props.urlCourse === null
                                                    ? <div onClick={() => {this.props.history.push(this.props.urlRequest)}}><ButtonPrimary canSubmit={false} label={"Request"} /></div>
                                                    : <>
                                                        {this.props.isCourseCompleted
                                                            ? <>{this.props.isQuizCompleted
                                                                ? <ButtonComplete><ButtonTertiary canSubmit={false} label={"Training completed"} /></ButtonComplete>
                                                                : <div onClick={() => {this.props.history.push(this.props.urlQuiz)}}><ButtonPrimary canSubmit={false} label={"Take quiz"} /></div>
                                                            }</>
                                                            : <>
                                                                {this.props.isCourseAssigned
                                                                    ? <div onClick={() => {this.props.history.push(this.props.urlCourse)}}><ButtonPrimary canSubmit={false} label={"Continue video"} /></div>
                                                                    : <>{this.props.shouldAllowToTakeCert
                                                                        ? <div onClick={this.clickNewVideo}><ButtonPrimary canSubmit={false} label={"Watch video & take quiz"} /></div>
                                                                        : <>
                                                                            {parseInt(this.props.programId) === 64 && <ButtonComplete><ButtonTertiary canSubmit={false} label={"Women Certificate Only"}/></ButtonComplete>}
                                                                            {parseInt(this.props.programId) === 77 && <ButtonComplete><ButtonTertiary canSubmit={false} label={"Prerequisite not met!"}/></ButtonComplete>}
                                                                            {parseInt(this.props.programId) === 56 && <ButtonComplete><ButtonTertiary canSubmit={false} label={"NextGen Certificate Only"}/></ButtonComplete>}
                                                                            {parseInt(this.props.programId) === 75 && <ButtonComplete><ButtonTertiary canSubmit={false} label={"Prerequisite not met!"}/></ButtonComplete>}
                                                                        </>
                                                                    }</>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                            : <>
                                                {this.state.existingRegistration.get("registrationId")
                                                    ? <NoClick><ButtonTertiary canSubmit={false} label={"Registered"} /></NoClick>
                                                    : <>
                                                        {this.state.isRegistrationInProgress
                                                            ? <NoClick><ButtonTertiary canSubmit={false} label={"Registering..."} /></NoClick>
                                                            : <div onClick={() => this.props.onTryRegister(this.props.groupProgramDetails.get("price"))}><ButtonPrimary canSubmit={false} label={"Register"} /></div>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </Button>
                                }
                            </TitleAndButton>
                        </Relative>
                        <IntroSection backgroundColor={colors.backgroundColor1} bottom={false}>
                            <TypeSection entryPoint={this.props.entryPoint}>
                                {this.props.entryPoint === "advisor"
                                    ? <FontBody24>
                                        {!this.state.isPromotionalProgram && <>{this.state.dateString}</>}
                                        {(this.state.ceCredits !== undefined && this.state.ceCredits !== null && this.state.ceCredits > 0) &&
                                            <CECredit>
                                                <img src={images.firmIconCECredit} alt={""} />
                                                <FontBody21>{this.state.ceCredits} Credit{this.state.ceCredits === 1 ? "" : "s"}</FontBody21>
                                            </CECredit>
                                        }
                                    </FontBody24>
                                    : <>
                                        <ContentTypeIcon src={images.calendarIcon} alt={""} />
                                        <div>
                                            <FontBody16>{this.state.dateString}</FontBody16>
                                            <FontBody16>{this.state.price}</FontBody16>
                                        </div>
                                    </>
                                }
                            </TypeSection>
                        </IntroSection>
                        <IntroSection backgroundColor={colors.white} bottom={true}>
                            {this.props.entryPoint !== "advisor" &&
                                <>{this.state.existingRegistration.get("registrationId")
                                    ? <PurchaseBox clickable={false}>
                                        <ButtonInactive canSubmit={false} label={"Already registered!"} />
                                    </PurchaseBox>
                                    : <>
                                        {this.state.isRegistrationInProgress
                                            ? <PurchaseBox clickable={false}>
                                                <ButtonInactive canSubmit={false} label={"Registering..."} />
                                            </PurchaseBox>
                                            : <PurchaseBox clickable={true} onClick={() => this.props.onTryRegister(this.props.groupProgramDetails.get("price"))}>
                                                <ButtonPrimary canSubmit={false} label={"Register"} />
                                            </PurchaseBox>
                                        }
                                    </>
                                }</>
                            }
                            {(this.state.error !== null) && <Red>{this.state.error}</Red>}
                            {!(this.state.groupProgramDetails.get("description") === "" || this.state.groupProgramDetails.get("description") === null) &&
                                <>
                                    {this.props.entryPoint === "advisor" && <DescriptionTitle>OVERVIEW</DescriptionTitle>}
                                    {(this.props.isCourseCompleted && this.props.courseData !== null) &&
                                        <>
                                            <Download>Training: <Link to={this.props.urlCourse}>Rewatch video</Link></Download>
                                            {this.props.courseData.course.documents.map((d, dIndex) => (
                                                <Download key={dIndex}>Download: <a href={d.url} target={"_blank"}>{d.title}</a></Download>
                                            ))}
                                        </>
                                    }
                                    <QuillSection>
                                        <ReactQuill
                                            id={uuid4()}
                                            readOnly={true}
                                            theme={null}
                                            value={this.state.groupProgramDetails.get("description")}
                                            bounds={'.app'}
                                        />
                                    </QuillSection>
                                </>
                            }
                            {this.props.entryPoint === "advisor" &&
                                <CoachAuthor>
                                    <CoachTitle>Coach:</CoachTitle>
                                    <CoachRecord>
                                        {this.state.groupProgramDetails.get("author").get("photo") !== undefined &&
                                            <Photo src={this.state.groupProgramDetails.get("author").get("photo")} alt={""}/>
                                        }
                                        <div>
                                            {this.state.groupProgramDetails.get("author").get("id") === 5194
                                                ? <>
                                                    <b>{this.state.groupProgramDetails.get("author").get("first_name")} {this.state.groupProgramDetails.get("author").get("last_name")}</b>
                                                    <div>Head of Marketing @ Willow</div>
                                                </>
                                                : <>{(this.state.groupProgramDetails.get("author").get("expertCategories") !== undefined && this.state.groupProgramDetails.get("author").get("expertCategories").size > 0)
                                                    ? <>
                                                        <b>{this.state.groupProgramDetails.get("author").get("first_name")} {this.state.groupProgramDetails.get("author").get("last_name")}</b>
                                                        <div>{env.ENTERPRISE.COMPANY.CARE.PRINT_EXPERT_CATEGORIES(this.state.groupProgramDetails.get("author").get("expertCategories").toJS(), true)}</div>
                                                    </>
                                                    : <div>{this.state.groupProgramDetails.get("author").get("first_name")} {this.state.groupProgramDetails.get("author").get("last_name")}</div>
                                                }</>
                                            }
                                        </div>
                                    </CoachRecord>
                                </CoachAuthor>
                            }
                            {this.props.entryPoint === "advisor"
                                ? <>{this.state.lectures.size > 1 && <DescriptionTitle>{this.state.isPromotionalProgram ? "VIDEO SESSIONS" : "LIVE VIDEO SESSIONS"}</DescriptionTitle>}</>
                                : <FontHeader18>Live video sessions:</FontHeader18>
                            }
                            {((this.props.entryPoint === "advisor" && this.state.lectures.size > 1) || this.props.entryPoint !== "advisor") &&
                                <>
                                    {this.state.lectures.map((l, index) => (
                                        <ProgramDetailsLecture
                                            key={index}
                                            order={index}
                                            data={l}
                                            webinarOpen={this.state.webinarOpen}
                                            webinarOpenStatus={this.webinarOpenStatus}
                                            entryPoint={this.props.entryPoint}
                                            isPromotionalProgram={this.state.isPromotionalProgram}
                                        />
                                    ))}
                                </>
                            }
                            {this.props.entryPoint !== "advisor" &&
                                <>{!this.state.existingRegistration.get("registrationId") &&
                                    <>
                                        {this.state.isRegistrationInProgress
                                            ? <PurchaseBox clickable={false}>
                                                <ButtonInactive canSubmit={false} label={"Registering..."} />
                                            </PurchaseBox>
                                            : <PurchaseBox clickable={true} onClick={() => this.props.onTryRegister(this.props.groupProgramDetails.get("price"))}>
                                                <ButtonPrimary canSubmit={false} label={"Register"} />
                                            </PurchaseBox>
                                        }
                                    </>
                                }</>
                            }
                        </IntroSection>
                    </SettingsCard>
                    {(this.state.showModal && this.props.entryPoint === "advisor") &&
                        <StyledModal onClick={(e) => e.stopPropagation()} style={{zIndex:"100"}}>
                            <FontBody18>You are about to take an elective training that will not count towards your certification - would you like to continue?</FontBody18>
                            <SpaceBetween>
                                <div onClick={() => this.setState({"showModal":false})}> <ButtonTertiary canSubmit={true} label={"Cancel"}/>
                                </div>
                                <div onClick={() => this.props.history.push(this.props.urlCourse)}> <ButtonPrimary canSubmit={true}
                                                                                                                                                        label={"Continue"}/></div>
                            </SpaceBetween>
                        </StyledModal>
                    }
                </Container>
            )
        }
    }
}

const mapStateToProps = state => ({
    groupProgramDetails: state.iframe.programs.programs.get("groupProgramDetails"),
    isRegistrationInProgress: state.iframe.programs.programs.get("groupProgramRegistrationInProgress"),
    existingRegistration: state.iframe.programs.programs.get("existingRegistration"),
});

const mapDispatchToProps = dispatch => ({
    tryGetProgramDetails: (groupProgramId) => dispatch(tryGetProgramDetails(groupProgramId)),
    tryGetProgramRegistrationInfo: (groupProgramId) => dispatch(tryGetProgramRegistrationInfo(groupProgramId)),
    tryAuthOnly: (email, trackNewRegistration, forceAuth) => dispatch(tryAuthOnly(email, trackNewRegistration, forceAuth)),
    certificationValidation: () => dispatch(certificationValidation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GroupProgramDetails))