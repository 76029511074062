import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment/moment';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/* Components */
import { FontHeader16, FontBody16 } from "../../../../common/components/fonts";
import { colors } from '../../../../common/components/colors';
import { ButtonInactive, ButtonLink, ButtonPrimary, ButtonSecondary, ButtonTertiary } from "../../../../common/components/buttons";
import { BtnDiv } from "../../../components/advisorpreviewcard";
import Popup from './conversation';
import { tryGetLeadConversation, tryUpdateStatus } from '../middleware/prospects';
import {
    Arrow,
    Background,
    Dropdown,
    DropdownList,
    DropdownRow,
    DropdownTitle,
    ListButton,
    Relative
} from "./prospectelements";

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 720px) {
    flex-direction: row;
    align-items: center;
    }
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
const ColName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: 720px) {
    flex-direction: row;
    width: 100%;
    text-align: left;
    padding-bottom: 10px;
    }
`;

const FirstCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media screen and (max-width: 720px) {
    flex-direction: row;
    width: 100%;
    text-align: left;
    padding-bottom: 10px;
    }
`;
const RowCTA = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 200px;
  @media screen and (max-width: 720px) {
    flex-direction: row;
    width: 100%;
    align-items: center;
    }
`;
const ColCTA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
  text-align: center;
  max-width: 200px;
  width: 100%;
  & > div {
    margin: 0 auto;
  }
`;
const ProspectBackground = styled.div`
    // background-color: ${props => props.backgroundColor};
    // padding: 20px;
    // border-radius: 20px 0 20px 0;
`;
const ProspectInside = styled.div`
    background-color: white;
    padding: 20px;
    border-radius: 20px 0 20px 0;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
`;
const DateBox = styled.div`
    background-color: ${colors.primary70};
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    color: white;
    max-width: 180px;
    width: 100%;
    align-content: center;
    @media screen and (max-width: 720px) {
        padding: 10px;
        max-width: 120px;
    }
    //box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.12);
`;
const NotInterested = styled(FontBody16)`
    text-decoration: underline;
    cursor: pointer;
`;

const MediaBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
    align-items: center;
    @media screen and (max-width: 720px) {
        flex-direction: column;
        gap: 10px;
        width: none;
    }
`;

const Web = styled.div`
    display: flex;
    text-align: center;
    padding-right: 5px;
    @media screen and (max-width: 720px) {
        display: none;
    }
`;

const Mobile = styled.div`
    display: none;
    @media screen and (max-width: 720px) {
        display: flex;
    }
`;

export default function FirmMarketingProspectRecord(props) {
    const { prospect = {
        "id": null,
        "email": "",
        "firstName": "",
        "lastName": "",
        "requestedSessionTime": "",
        "status": "pending",
    },
        selectStatus = (leadId, leadStatus) => {},
        getConvo = (userID) => {},
        index = 0,
        showPopup = false,
        conversation = [],
        pending = [],
        setShowPopup = (bool) => {},
        leadStatus = "Pending",
        updateStatus = (leadStatus) => {}
    } = props;

    const [filterOpen, setFilterOpen] = useState(false)
    const dropdown = ["Successfully Onboarded", "Not Ready to Move Forward", "Not a Fit", "No Response Received", "Pending"]
    const [status, setStatus] = useState(prospect.status)
    const [statusNumber, setStatusNumber] = useState()
    const [prevStatus, setPrevStatus] = useState(status)
    const statusToNumberObject = {
        7 : "Successfully Onboarded",
        1 : "Not Ready to Move Forward",
        10: "Not a Fit",
        0 : "Pending",
        2: "No Response Received"
    }
    const numberToStatusObject = {
        "Successfully Onboarded" : 7,
        "Not Ready to Move Forward" : 1,
        "Not a Fit" : 10,
        "Pending" : 0,
        "No Response Received" : 2
    }

    const selectConvo = async (userID) => {
        setShowPopup(true);
        await getConvo(userID);
    }
    const toggleFilterChange =  async (value) => {
        setFilterOpen(false)
        updateStatus(value)
        selectStatus(prospect.id, statusToNumberObject[value])
    }


    const toggleFilterOpen = () => {
        setFilterOpen(true)
    }

    const toggleFilterClose = () => {
        setFilterOpen(false)
    }

        return (
            <ProspectBackground backgroundColor={colors.prospectColors[index % colors.prospectColors.length]}>
                <ProspectInside>
                    <Row>
                        <MediaBox>
                            <FirstCol>
                                <ColName>
                                    <FontBody16>{prospect.firstName} {prospect.lastName}</FontBody16>
                                </ColName>
                                <Web onClick={() => selectConvo(prospect.id)}>
                                    <ButtonSecondary
                                        canSubmit={false}
                                        label={"Show Conversation"}
                                    />
                                </Web>
                            </FirstCol>
                            <RowCTA>
                                <DateBox>
                                    <Col>
                                        <FontHeader16>{moment(prospect.requestedSessionTime).format("dddd")}</FontHeader16>
                                        <FontHeader16>{moment(prospect.requestedSessionTime).format("l")}</FontHeader16>
                                        <FontBody16>{moment(prospect.requestedSessionTime).format("LT").replace(" ", "").toLowerCase()} - {moment(prospect.requestedSessionTime).add(15, "minutes").format("LT").replace(" ", "").toLowerCase()}</FontBody16>
                                    </Col>
                                </DateBox>
                            </RowCTA>
                        </MediaBox>
                        <ColCTA>
                            <Relative>

                            <Dropdown onClick={toggleFilterOpen}>
                                <DropdownRow>
                                    <DropdownTitle>{leadStatus}</DropdownTitle>
                                    <Arrow />
                                </DropdownRow>
                            </Dropdown>
                            {filterOpen &&
                                <>
                                    <DropdownList>
                                        {dropdown.map((d,index) => <ListButton key={index} onClick={() => toggleFilterChange(d)}>{d}</ListButton>)}
                                    </DropdownList>
                                    <Background onClick={toggleFilterClose} />
                                </>
                            }
                            </Relative>
                            {/*{pending.includes(prospect.status) ?*/}
                            {/*    <>*/}
                            {/*        <BtnDiv onClick={() => {selectStatus(prospect.id, 7) }}>*/}
                            {/*            <ButtonPrimary*/}
                            {/*                canSubmit={false}*/}
                            {/*                label={"Won Client"}*/}
                            {/*            />*/}
                            {/*        </BtnDiv>*/}
                            {/*        <NotInterested onClick={() => {selectStatus(prospect.id, 10) }}>*/}
                            {/*            Lost Client</NotInterested>*/}
                            {/*    </>*/}
                            {/*    : <></>*/}
                            {/*}*/}
                            <Mobile onClick={() => selectConvo(prospect.id)}>
                                <ButtonSecondary
                                    canSubmit={false}
                                    label={"Show Conversation"}
                                />
                            </Mobile>
                        </ColCTA>
                    </Row>
                    {showPopup && <Popup conversation={conversation} onClose={() => {setShowPopup(false); }} name={prospect.firstName} />}
                </ProspectInside>
            </ProspectBackground>
        )

}

// const mapDispatchToProps = dispatch => ({
//     tryGetLeadConversation: (leadId) => dispatch(tryGetLeadConversation(leadId)),
//     tryUpdateStatus: (leadId, leadStatus) => dispatch(tryUpdateStatus(leadId, leadStatus)),
// });

// export default connect(null, mapDispatchToProps)(withRouter(FirmMarketingProspectRecord));