import {axiosReq} from "../../../../common/utils/axios";

/* DevData */
import {devMarketingCoachProspects, profileDetailsData, leadsData, conversation } from "../static/devdata";

// export const tryGetAdvisorProspects = () => {
//     return async dispatch => {
//         const res = await axiosReq('apiv1/coach/marketing/prospects', "GET", {}, devMarketingCoachProspects);
//         return res.allProspects;
//     }
// };

// export const tryPostAdvisorProspectsAccept = (userId, accept) => {
//     return async dispatch => {
//         return axiosReq('apiv1/coach/marketing/prospects/accept', "POST", {"userId": userId, "accept": accept}, {});
//     }
// };

export const tryGetAdvisorProfile = () => {
        return axiosReq('apiv1/coach/settings/advisor-profile', 'GET', {}, profileDetailsData);

};

export const trySaveCriteriaDetails = (profileDetails) => {
        return axiosReq('apiv1/coach/settings/advisor-profile', 'PUT', profileDetails, {}, profileDetailsData);


};

export const tryGetProspects = () => {
        return axiosReq('advertising/advisors/v1/leads', 'GET', {}, leadsData);
};

export const tryGetLeadConversation = (leadId) => {
        return axiosReq(`advertising/advisors/v1/leads/${leadId}/conversation`, 'GET', {}, conversation);

};

export const tryUpdateStatus = (leadId, leadStatus) => {
        return axiosReq(`advertising/advisors/v1/leads/${leadId}/update-status`, 'POST', { "leadStatus": leadStatus }, {});


};

// export const tryPostAdvisorCriteria = (criteria) => {
//     return async dispatch => {
//         return axiosReq('apiv1/coach/marketing/criteria', "POST", criteria, {});
//     }
// };
