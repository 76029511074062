import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PreviewEventsElectives from "../../trainings/containers/previeweventselectives";
import {
    tryGetAllTrainings,
    tryGetUpcomingTrainingsRegisteredFor,
} from "../../dashboard/middleware/home";
import { tryGetAllTasks } from "../../tasks/middleware/tasks";
import { tryGetTrainingMapping } from "../../trainings/middleware/trainings";
import { Map } from "immutable";
import Nav from "../../navbars/containers/nav";
import {PageContentContainer, PageFull} from "../../navbars/components/navlayouts";
import {PageContent} from "../../../components/mwc";
import {FontBody24, FontSubtitle40} from "../../../../common/components/fonts";
import FirmMarketingNav from "../../marketing/components/nav";
import {TopSegment} from "../../marketing/containers/structure";
import styled from "styled-components";

const Subtitle = styled(FontBody24)`
    margin: 30px 0 0 0;
    min-height: 108px;
`;

const Foreground = styled.div`    
    position: relative;
    z-index: 2;
`;

export function FirmElectiveTrainings() {
    const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [trainingsShown, setTrainingsShown] = useState([]);
    const [allTrainings, setAllTrainings] = useState([]);
    const [allTasks, setAllTasks] = useState([]);
    const [trainingsRegistered, setTrainingsRegistered] = useState([]);
    const [completedCert, setCompletedCert] = useState(false)
    // const [requiredTrainings, setRequiredTrainings] = useState([]);

    const completedCertificateIds = useSelector((state) =>
        state.common.user
            .get("completedCertificates")
            .map((cert) => cert.get("certificationDefinitionId"))
    );

    // const completedCertificateDesignations = useSelector((state) =>
    // state.common.user
    //     .get("completedCertificates").map((cert) => cert.get("designation")))

    const currentCertification = useSelector((state) =>
        state.common.user.getIn(["certificationProgress", "certificationDefinition"], Map()).toJS()
    );

    const dispatch = useDispatch();
    // const disabledTrainingIds = [];
    // const requiredTrainings = [
    //     {
    //         id: 62,
    //         message: "Required for all Advisor Certificates",
    //         requiredForCertificationProgramId: null,
    //         showAfterCompletedTrainingId: null,
    //     },
    //     {
    //         id: 64,
    //         message: "Required for Advisor for Women™ Certificate Program",
    //         requiredForCertificationProgramId: 3,
    //         showAfterCompletedTrainingId: null,
    //     },
    //     {
    //         id: 77,
    //         message: `Required for Advisor for Women™ Certificate Program. Can be taken after you complete training "Empowering Women Investors"`,
    //         requiredForCertificationProgramId: 3,
    //         showAfterCompletedTrainingId: 64,
    //     },
    //     {
    //         id: 56,
    //         message: "Required for Advisor for NextGen™ Certificate Program",
    //         requiredForCertificationProgramId: 4,
    //         showAfterCompletedTrainingId: null,
    //     },
    //     {
    //         //
    //         id: 75,
    //         message: `Required for Advisor for NextGen™ Certificate Program. Can be taken after you complete training "Engaging NextGen Investors"`,
    //         requiredForCertificationProgramId: 4,
    //         showAfterCompletedTrainingId: 56,
    //     },
    // ];

    useEffect(() => {
        const fetchData = async () => {
            const allTrainingsData = await dispatch(tryGetAllTrainings(null, null));
            setAllTrainings(allTrainingsData);

            const allTasksData = await dispatch(tryGetAllTasks());
            setAllTasks(allTasksData);

            const trainingsRegisteredData = await dispatch(tryGetUpcomingTrainingsRegisteredFor());
            setTrainingsRegistered(trainingsRegisteredData);

            const trainingsUpcomingWithStatus = allTrainingsData.map((t) => {
                return {
                    ...t,
                    canRegister: !trainingsRegisteredData.map((tr) => tr.groupProgram.id).includes(t.id),
                };
            });

            const distinctTrainings = await distinctLiveAndOndemandTrainings(trainingsUpcomingWithStatus, allTasksData);
            setTrainingsShown(distinctTrainings);
            if (completedCertificateIds.length > 0) {
                setCompletedCert(true)
            }
        };

        fetchData();
    }, [dispatch]);

    const distinctLiveAndOndemandTrainings = async (trainings, allTasks) => {
        const mapping = await dispatch(tryGetTrainingMapping());
        const pathname = window.location.pathname;

        // if (pathname === "/my-certificates/my-trainings/live") {
        //     return getLiveTrainingMapping(trainings, mapping);
        // }

        return trainings
            .filter((t) => t.isAssetManagement && mapping.matchProgramAndVideo[t.id.toString()] !== undefined)
            .map((t) => {
                const courseId = mapping.matchProgramAndVideo[t.id.toString()] ?? null;
                const quizId = mapping.matchVideoAndQuiz[courseId?.toString()] ?? null;

                const isVideoTaskCompleted =
                    allTasks.filter((a) => a.course.id.toString() === courseId.toString() && a.isCompleted).length > 0;

                const isCourseAssignedToUser =
                    allTasks.filter(
                        (a) =>
                            a.course.id.toString() === courseId.toString() &&
                            !a.isCompleted &&
                            !(a.courseAssignmentId === undefined || a.courseAssignmentId === null)
                    ).length > 0;

                const isQuizTaskCompleted =
                    allTasks.filter((a) => a.course.id.toString() === quizId.toString() && a.isCompleted).length > 0;

                return {
                    ...t,
                    courseId: courseId,
                    hasCourse: true,
                    isCourseCompleted: isVideoTaskCompleted,
                    quizId: quizId,
                    isQuizCompleted: isQuizTaskCompleted,
                    isCourseAssigned: isCourseAssignedToUser,
                };
            });
    };

    // const getElectiveTrainings = () => {
    //     const requiredTrainingIds = [62, 64, 77, 56, 75] //requiredTrainings.map((t) => t.id);
    //     let filteredIds = []
    //     if (completedCert) {
    //         if (completedCertificateDesignations.includes('CAW')) {
    //             filteredIds.push(56,75) // required trainings for nextgen
    //         }
    //         if (completedCertificateDesignations.includes('CAN')){
    //             filteredIds.push(64,77) // required trainings for women
    //         }
    //         return trainingsShown.filter((t) => !filteredIds.includes(t.id))
    //     }
    //         return trainingsShown.filter((t) => !requiredTrainingIds.includes(t.id))
    // };

    return (
        <>
            <Nav />
            <PageFull style={{"backgroundImage": "none"}}>
                <PageContentContainer fullPage={true}>
                    <PageContent>
                            <TopSegment>
                                <FontSubtitle40 spaced={true}>CONTINUING EDUCATION</FontSubtitle40>
                                <Subtitle>Earn additional CE credits for the following designations: CFP®, CIMA®, CIMC®, RMA®, CPWA® and CDFA®.</Subtitle>
                            </TopSegment>
            {trainingsShown.map((t, index) => (
                <PreviewEventsElectives key={index} t={t} timeZone={timezone} />
            ))}

                    </PageContent>
                </PageContentContainer>
            </PageFull>
        </>
    );
}
