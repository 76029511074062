import React, { useEffect, useState, useRef } from 'react';
import styled, {css} from 'styled-components';
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import moment from 'moment/moment'

/* Components */
import {
    FontTitle28,
    FontSubtitle40,
    FontBody24,
    FontBody21To16,
    FontBody14,
    FontSubtitle21, FontSubtitle14, FontHeader16
} from '../../../../common/components/fonts';
import { colors } from '../../../../common/components/colors';
import {ButtonSecondary, ButtonTertiary} from '../../../../common/components/buttons';
import { images } from "../../../../common/components/images";
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import CertTrainingsNav from "../components/listnav";
import { ToolTipCreator } from "../../dashboard/components/tooltips";
import {
    trackStepFour,
    trackStepListBecomeMember,
    trackStepListStepFour,
    trackStepListStepOne,
    trackStepListStepThree,
    trackStepListStepTwo,
    trackStepOne,
    trackStepThree,
    trackStepTwo,
    trackStepZero
} from "../../../../common/utils/tracking";
import certificationprogress from "../../dashboard/containers/certificationprogress";
import CompletedCertifications from "../../dashboard/containers/certificationcompleted";
import { Map } from "immutable";
import NextCertificationAd from "./completed";

const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 30px;
  row-gap: 10px;
  justify-content: space-between;
  align-items: flex-start;
`;
const StepContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  background-color: ${props => props.backgroundColor};
  flex-wrap: wrap;
  flex-direction: column;
  gap: 40px;
  padding: 40px;
  border-radius: ${props => props.borderRadius};
  box-shadow: 0 10px 25px 0 rgb(0, 0, 0, 0.15);
  width: calc(100% - 80px);

  ${TitleSection} {
    cursor: pointer;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Buffer = styled.div`
    margin-top: 20px;
`;
const Step = styled.div`
  display: ${({ toggle }) => toggle ? 'block' : 'none'};
`;
const TopSegment = styled.div`
  background-image: url(${images.firmBackgroundTop});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  left: -60px;
  right: 0;
  top: -90px;
  bottom: 0;
  padding: 90px 60px 0 60px;
  width: 100%;
  @media screen and (max-width: 748px) {
    left: -30px;
    right: -30px;
    top: -30px;
    bottom: 0;
    padding: 30px 30px 0 30px;
  }
`;
const Icon = styled.div`
  background-image: url(${props => props.image});
  background-size: contain;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  align-self: center;
  justify-self: center;
`;
const BtnDiv = styled.div`
  width: fit-content;
  cursor: pointer;
  align-items: center;
  ${props => props.isOff && css`
      cursor: default !important;
        & > div {
          cursor: default !important;
        }
  `};
  display: flex;
  margin-bottom: 20px;
  & ${FontBody21To16} {
    margin-left: 8px;
    margin-top: 8px;
  }
`;
const LabelDiv = styled.div`
    margin-bottom: 20px;
`;
const Subtitle = styled(FontBody24)`
  margin: 30px 0 0 0;
  min-height: 108px;
`;
export const StyledModal = styled.div`
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  padding: 50px;
  border-radius: 30px;
  background-color: ${colors.white};
  -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.15);
  max-width: 390px;
  width: calc(100% - 100px);
  z-index: 20;
  word-wrap: break-word;
  tab-index: 1;
  @media screen and (max-width: 748px) {
    max-width: calc(100% - 100px);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: translate(0%, 0%);
    border-radius: 0;
    overflow-y: auto;
  }
`;
export const Close = styled.div`
  width: fit-content;
  margin-left: auto;
  position: relative;
  height: 36px;
`;
export const CloseIcon = styled(FontBody24)`
  padding: 10px;
  position: absolute;
  top: -25px;
  right: -10px;
  line-height: 24px;
  cursor: pointer;
`;

CertificationStepList.defaultProps = {
    certified: {
        "stepsCompleted": [false, false, false, false],
        "step3Pieces": {
            "peerCompleted": false,
            "clientCompleted": false,
            "peerRedo": false,
            "clientRedo": false,
        },
        "step4Pieces": {
            "profileCompleted": false,
            "calendarCompleted": false
        }
    }
}

export const useOnClickOutside = (ref, handler) => {
    useEffect(
        () => {
            const listener = (event) => {
                // Do nothing if clicking ref's element or descendent elements
                if (!ref.current || ref.current.contains(event.target)) {
                    return;
                }
                handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
                document.removeEventListener("mousedown", listener);
                document.removeEventListener("touchstart", listener);
            };
        },
        [ref, handler]
    );
}

function CertificationStepList(props) {
    const [step, setStep] = useState(0);
    const completedCerts = props.completedCertifications;
    const stepsCompleted = props.certified?.stepsCompleted;
    const peerCompleted = props.certified?.step3Pieces.peerCompleted;
    const clientCompleted = props.certified?.step3Pieces.clientCompleted;
    const peerRedo = props.certified?.step3Pieces.peerRedo;
    const clientRedo = props.certified?.step3Pieces.clientRedo;
    const certType = props.certified?.certificationDefinition?.designation;
    const [showModal, setShowModal] = useState(false);
    const [modalInfo, setModalInfo] = useState({});
    const [clipboard, setClipBoard] = useState("Copy to Clipboard");
    const ref = useRef();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const shouldCheck = (bool) => {
        if (bool) {
            return images.checkBoxChecked;
        } else {
            return images.checkBoxEmpty;
        }
    }
    const myRef = useRef(null)
    const executeScroll = () => myRef.current.scrollIntoView({ behavior: "smooth", block: "center" })

    const showToolTip = useSelector(state => state.enterprise.tooltip.get('showToolTip'));
    const toolTipPage = useSelector(state => state.enterprise.tooltip.get('toolTipPage'));

    useEffect(() => {
        if (location.search.includes("step=3")) {
            setStep(3);
            executeScroll();
        }
    }, []);

    const handleStepTwo = async () => {
        await dispatch(trackStepListStepTwo({
            "sessionCompleted": props.certified.step2Pieces.sessionCompleted,
        }));
        history.push("/my-certificates/my-coaches");
    };

    const handleStepOneTrainingOne = async () => {
        await dispatch(trackStepListStepOne({
            "mainTrainingsCompleted": props.certified.step1Pieces.mainTrainingsCompleted,
        }));
        history.push("/my-certificates/my-trainings/62")
    };
    const handleStepOneTrainingTwo = async () => {
        await dispatch(trackStepListStepOne({
            "mainTrainingsCompleted": props.certified.step1Pieces.mainTrainingsCompleted,
        }));
        history.push("/my-certificates/my-trainings/64")
    };

    const handleStepOneTrainingTwoNextGen = async () => {
        await dispatch(trackStepListStepOne({
            "mainTrainingsCompleted": props.certified.step1Pieces.mainTrainingsCompleted,
        }));
        history.push("/my-certificates/my-trainings/56")
    };

    const handleStepOneTrainingThree = async () => {
        history.push("/my-certificates/my-trainings/77")
    };

    const handleStepOneTrainingThreeNextGen = async () => {
        history.push("/my-certificates/my-trainings/75")
    };

    const handleStepFour = async () => {
        await dispatch(trackStepListStepFour(
            {
                "profileCompleted": props.certified.step4Pieces.profileCompleted,
                "calendarCompleted": props.certified.step4Pieces.calendarCompleted
            }
        ));
        history.push("/my-certificates/profile");
    };

    //     Link to client reference form
    //
    // If the link above doesn't work, copy and paste this url into your browser:
    // https://trust-willow.typeform.com/to/KFInwqUi#user_id=1234&advisor_id=5678

    const handleStepPeer = async (e) => {
        await dispatch(trackStepListStepThree(
            {
                "peerCompleted": peerCompleted,
                "clientCompleted": clientCompleted,
                "peerRedo": peerRedo,
                "clientRedo": clientRedo,

            }
        ));
        setModalInfo({
            subject: `Personal Reference for ${props.firstName}'s ${props.certified.certificationDefinition.name === "CERTIFIED ADVISOR FOR WOMEN™"
                ? "Advisor for Women™ Certificate"
                : props.certified.certificationDefinition.name === "CERTIFIED ADVISOR FOR NEXTGEN™"
                    ? "Advisor for NextGen™ Certificate"
                    : props.certified.certificationDefinition.name
            }`,
            signature: "Best Regards,",
            intro: "Hi [insert name],",
            typeform: `https://trust-willow.typeform.com/to/XeBmERhQ#user_id=${props.userId}&advisor_id=${props.userId}`,
            // text: `I am becoming a ${props.certified.certificationDefinition.name + " (" + props.certified.certificationDefinition.designation + ")"}, a training program and qualification for excellence in serving tomorrow's clients. As part of the application, I am required to provide a reference from a peer financial advisor at another firm and would appreciate if would consider taking 5-minutes to fill out a brief form to support my application:`,
            text: `I am becoming an ${props.certified.certificationDefinition.name === "CERTIFIED ADVISOR FOR WOMEN™"
                ? "Advisor for Women™ Certificate Holder"
                : props.certified.certificationDefinition.name === "CERTIFIED ADVISOR FOR NEXTGEN™"
                    ? "Advisor for NextGen™ Certificate Holder"
                    : props.certified.certificationDefinition.name+" Holder"
            }, a training program and qualification for excellence in serving tomorrow's clients. As part of the application, I am required to provide a reference from a peer financial advisor at another firm and would appreciate if you would consider taking 5-minutes to fill out a brief form to support my application:`,
            title: "Send Email to Peer",
            // additionalInfo: "To learn more about the " + props.certified.certificationDefinition.name + " (" + props.certified.certificationDefinition.designation + ") before or after providing a reference, ",
            additionalInfo: `To learn more about the ${props.certified.certificationDefinition.name === "CERTIFIED ADVISOR FOR WOMEN™"
                ? "Advisor for Women™ Certificate Program"
                : props.certified.certificationDefinition.name === "CERTIFIED ADVISOR FOR NEXTGEN™"
                    ? "Advisor for NextGen™ Certificate Program"
                    : props.certified.certificationDefinition.name+" Program"
                    + " before or after providing a reference, "}`,
            signupLink: "secure.trustwillow.com/advisor-signup?certification=" + props.certified.certificationDefinition.designation.substring(0, 3),
            formLink: "Link to peer reference form"
        })
        setShowModal(true);
        e.stopPropagation();
    }

    const handleStepClient = async (e) => {
        await dispatch(trackStepListStepThree(
            {
                "peerCompleted": peerCompleted,
                "clientCompleted": clientCompleted,
                "peerRedo": peerRedo,
                "clientRedo": clientRedo,

            }
        ));
        setModalInfo({
            subject: `Personal Reference for ${props.firstName}'s ${props.certified.certificationDefinition.name === "CERTIFIED ADVISOR FOR WOMEN™"
                ? "Advisor for Women™ Certificate"
                : props.certified.certificationDefinition.name === "CERTIFIED ADVISOR FOR NEXTGEN™"
                    ? "Advisor for NextGen™ Certificate"
                    : props.certified.certificationDefinition.name
            }`,
            signature: "Best Regards,",
            intro: "Hi [insert name],",
            typeform: `https://trust-willow.typeform.com/to/KFInwqUi#user_id=${props.userId}&advisor_id=${props.userId}`,
            // text: `I am becoming a ${props.certified.certificationDefinition.name+" ("+props.certified.certificationDefinition.designation+")"}, completing a training program and qualification for excellence in serving tomorrow's clients. As part of the application, I am required to provide a client reference and would appreciate if would consider taking 5-minutes to fill out a brief form to support my application:`,
            text: `I am becoming an ${props.certified.certificationDefinition.name === "CERTIFIED ADVISOR FOR WOMEN™"
                ? "Advisor for Women™ Certificate Holder"
                : props.certified.certificationDefinition.name === "CERTIFIED ADVISOR FOR NEXTGEN™"
                    ? "Advisor for NextGen™ Certificate Holder"
                    : props.certified.certificationDefinition.name+" Holder"
            }, completing a training program and qualification for excellence in serving tomorrow's clients. As part of the application, I am required to provide a client reference and would appreciate if you would consider taking 5-minutes to fill out a brief form to support my application:`,
            title: "Send Email to Client",
            formLink: "Link to client reference form",
            signupLink: "",
            additionalInfo: ""

        })
        setShowModal(true);
        e.stopPropagation();
    }

    const pushToCoaches = () => {
        history.push("/my-certificates/my-coaches");
    }

    const pushToProfile = () => {
        history.push("/my-certificates/profile");
    }

    useOnClickOutside(ref, () => {
        setShowModal(false);
        setClipBoard("Copy to Clipboard")
    });

    if (props.completedCertifications && props.certified === null) {
        return (<NextCertificationAd completedCertifications={completedCerts} purchased={props.purchased}/>)

    } else {
        return (
            <>
                <Container>

                    <FontSubtitle21>
                        Earn
                        the {props.certified.certificationDefinition.name === "CERTIFIED ADVISOR FOR WOMEN™"
                        ? "Advisor for Women™ Certificate"
                        : props.certified.certificationDefinition.name === "CERTIFIED ADVISOR FOR NEXTGEN™"
                            ? "Advisor for NextGen™ Certificate"
                            : props.certified.certificationDefinition.name
                    }
                        {/*the {props.certified.certificationDefinition.name} ({props.certified.certificationDefinition.designation})*/}
                    </FontSubtitle21>
                    <br />
                    <br />
                    <br />

                    <StepContainer borderRadius={'10px 10px 0 0'} backgroundColor={colors.action15} onClick={() => {
                        step === 1 ? setStep(-1) : setStep(1)
                    }}>
                        <TitleSection>
                            <FontTitle28>
                                {step === 1 ? <DownOutlined style={{ marginRight: "10px" }} /> :
                                    <RightOutlined style={{ marginRight: "10px" }} />}
                                Step 1: Watch three trainings
                            </FontTitle28>
                            <Icon image={shouldCheck(stepsCompleted[0])} />
                        </TitleSection>
                        <Step toggle={step === 1} onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}>
                            {
                                certType.includes("CAW") ?
                                    <FontBody21To16>
                                        Willow’s on-demand video trainings are designed to help you better serve today’s clients.
                                        Courses are eligible for 1 CE credit for the following designations: CFP®, CIMA®, CIMC®, RMA®, CPWA® and CDFA®.
                                        Complete Integrating Coaching into your Practice, Empowering Women Investors, and Empowering Women Investors 201.
                                    </FontBody21To16>
                                    :
                                    <FontBody21To16>
                                        Willow’s on-demand video trainings are designed to help you better serve today’s clients.
                                        Courses are eligible for 1 CE credit for the following designations: CFP®, CIMA®, CIMC®, RMA®, CPWA® and CDFA®.
                                        Complete Integrating Coaching into your Practice, Engaging NextGen Investors, and Engaging NextGen Investors 201.
                                    </FontBody21To16>
                            }
                            {!stepsCompleted[0] &&
                                <>
                                    <Buffer />
                                    {!props.certified.step1Pieces.mainTrainingCompletedCoaching &&
                                        <BtnDiv onClick={handleStepOneTrainingOne}><ButtonTertiary canSubmit={true}
                                            label={"Start Integrating Coaching Into Your Practice"} /></BtnDiv>
                                    }
                                    {props.certified.certificationDefinition.designation.includes("CAN")
                                        ? <>
                                            {!props.certified.step1Pieces.mainTrainingCompletedNextGen &&
                                                <BtnDiv onClick={handleStepOneTrainingTwoNextGen}><ButtonTertiary
                                                    canSubmit={true}
                                                    label={"Start Engaging NextGen Investors"} /></BtnDiv>
                                            }
                                        </>
                                        : <>
                                            {!props.certified.step1Pieces.mainTrainingCompletedWomen &&
                                                <BtnDiv onClick={handleStepOneTrainingTwo}><ButtonTertiary
                                                    canSubmit={true}
                                                    label={"Start Empowering Women Investors"} /></BtnDiv>
                                            }
                                        </>
                                    }
                                    {props.certified.certificationDefinition.designation.includes("CAN")
                                        ? <>
                                            {!props.certified.step1Pieces.mainTrainingCompletedNextGen ?
                                                <BtnDiv isOff={true}><ButtonSecondary
                                                    canSubmit={false}
                                                    label={"Start Engaging NextGen Investors 201"} />
                                                    <FontHeader16>&nbsp; - Prerequisite not met</FontHeader16>

                                                </BtnDiv>
                                                :
                                                <>
                                                    {!props.certified.step1Pieces.mainTrainingCompletedNextGen2 &&
                                                        <BtnDiv onClick={handleStepOneTrainingThreeNextGen}><ButtonTertiary
                                                            canSubmit={true}
                                                            label={"Start Engaging NextGen Investors 201"} /></BtnDiv>
                                                    }
                                                </>
                                            }
                                        </>
                                    : <>
                                        {!props.certified.step1Pieces.mainTrainingCompletedWomen ?
                                            <BtnDiv isOff={true}><ButtonSecondary
                                                canSubmit={false}
                                                label={"Start Empowering Women Investors 201"} />
                                                <FontHeader16>&nbsp; - Prerequisite not met</FontHeader16>

                                            </BtnDiv>
                                            :
                                            <>
                                            {!props.certified.step1Pieces.mainTrainingCompletedWomen2 &&
                                                <BtnDiv onClick={handleStepOneTrainingThree}><ButtonTertiary
                                                    canSubmit={true}
                                                    label={"Start Empowering Women Investors 201"} /></BtnDiv>
                                                }
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </Step>
                    </StepContainer>
                    <StepContainer backgroundColor={colors.action30} onClick={() => {
                        step === 2 ? setStep(-1) : setStep(2)
                    }}>
                        <TitleSection>
                            <FontTitle28>
                                {step === 2 ? <DownOutlined style={{ marginRight: "10px" }} /> :
                                    <RightOutlined style={{ marginRight: "10px" }} />}
                                Step 2: Complete one Coach Session
                            </FontTitle28>
                            <Icon image={shouldCheck(stepsCompleted[1])} />
                        </TitleSection>
                        <Step toggle={step === 2} onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}>
                            <FontBody21To16>
                                Willow's Financial Life Coaches are highly qualified with backgrounds rooted in
                                financial
                                services.
                                Our coaches help you learn to handle sensitive client situations with tomorrow's
                                clientele
                                as well
                                as be more efficient in running and growing your business. Complete one 50-minute
                                session
                                with a coach.
                            </FontBody21To16>
                            {!stepsCompleted[1] &&
                                <>
                                    <Buffer />
                                    {!props.certified.step2Pieces.sessionCompleted
                                        ? <BtnDiv onClick={handleStepTwo}><ButtonTertiary canSubmit={true}
                                            label={"Book a coaching session"} /></BtnDiv>
                                        : <>
                                            {!props.certified.step2Pieces.sessionRated
                                                ? <LabelDiv><FontBody21To16><b>Awaiting coach's
                                                    feedback</b></FontBody21To16></LabelDiv>
                                                : <>
                                                    {!props.certified.step2Pieces.sessionPassed &&
                                                        <LabelDiv><FontBody21To16><b>Please contact
                                                            support</b></FontBody21To16></LabelDiv>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </>
                            }
                        </Step>
                    </StepContainer>
                    <StepContainer backgroundColor={colors.action70} onClick={(e) => {
                        if (step === 3) {
                            setStep(-1);
                            setShowModal(false)
                        } else {
                            setStep(3);
                            e.stopPropagation();
                        }
                    }} ref={myRef}>
                        <TitleSection onClick={() => setStep(3)}>
                            <FontTitle28>
                                {step === 3 ? <DownOutlined style={{ marginRight: "10px" }} /> :
                                    <RightOutlined style={{ marginRight: "10px" }} />}
                                Step 3: Submit client and peer references
                            </FontTitle28>
                            <Icon image={shouldCheck(stepsCompleted[2])} />
                        </TitleSection>
                        <Step toggle={step === 3} onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}>
                            <FontBody21To16>
                                Willow requires you to submit two references. One reference from a financial advisory
                                client
                                who you have served for at least 1 year.
                                The second reference should be from a financial advisor at another firm. Multiple choice
                                and
                                short-answer questions are provided.
                            </FontBody21To16>
                            {!stepsCompleted[2] &&
                                <div style={{
                                    position: 'relative',
                                    boxShadow: `${showToolTip && toolTipPage === 6 ? "0 1000px 0 3000px rgba(1,1,1,0.5)" : "unset"}`,
                                    borderRadius: "10px"
                                }}
                                >
                                    <Buffer />
                                    {!peerCompleted && !peerRedo &&
                                        <BtnDiv onClick={(e) => handleStepPeer(e)}><ButtonTertiary canSubmit={true}
                                            label={"Send reference form to a peer"} /></BtnDiv>}

                                    {peerCompleted
                                        &&
                                        <LabelDiv><FontBody21To16><b>Peer completed</b></FontBody21To16></LabelDiv>
                                    }
                                    {peerRedo &&
                                        <BtnDiv onClick={(e) => handleStepPeer(e)}><ButtonTertiary canSubmit={true}
                                            label={"Send reference form to a peer"} />
                                            <FontBody21To16><b>Please ask a different peer</b></FontBody21To16>
                                        </BtnDiv>

                                    }
                                    {toolTipPage === 6 && <ToolTipCreator
                                        text={"lorem ipsum text about getting certified, about step 3"}
                                        show={true}
                                        style={{ top: "-110px", left: "50px" }}
                                        linkToNext={pushToProfile}
                                        linkToBack={pushToCoaches}
                                    />}

                                    {!clientCompleted && !clientRedo && <BtnDiv onClick={(e) => handleStepClient(e)}
                                    >
                                        <ButtonTertiary
                                            canSubmit={true}
                                            label={"Send reference form to a client"}
                                        /></BtnDiv>
                                    }
                                    {clientCompleted
                                        &&
                                        <LabelDiv><FontBody21To16><b>Client completed</b></FontBody21To16></LabelDiv>
                                    }
                                    {clientRedo &&
                                        <BtnDiv onClick={(e) => handleStepClient(e)}><ButtonTertiary canSubmit={true}
                                            label={"Send reference form to a client"} />
                                            <FontBody21To16><b>Please ask a different client</b></FontBody21To16>
                                        </BtnDiv>

                                    }
                                    {showModal &&
                                        <StyledModal onClick={(e) => e.stopPropagation()} ref={ref}>
                                            <Close onClick={(e) => {
                                                setShowModal(false);
                                                e.stopPropagation();
                                                setClipBoard("Copy to Clipboard")
                                            }}><CloseIcon>x</CloseIcon></Close>

                                            <FontTitle28>{modalInfo.title}</FontTitle28>
                                            <FontBody14><b>{modalInfo.subject}</b></FontBody14>
                                            <br /> <br />
                                            <FontBody14>{modalInfo.intro}</FontBody14>
                                            <br />
                                            <FontBody14>{modalInfo.text}</FontBody14>
                                            <br />

                                            <a target={"_blank"}
                                                href={modalInfo.typeform}><FontBody14>{modalInfo.formLink}</FontBody14></a>
                                            <br />
                                            <FontBody14>If the link above doesn't work, copy and paste this url into
                                                your
                                                browser: </FontBody14>
                                            <FontBody14>{modalInfo.typeform}</FontBody14>
                                            <br />
                                            {modalInfo.additionalInfo.length > 0 &&
                                                <>
                                                    <br />
                                                    <FontBody14>{modalInfo.additionalInfo} <a
                                                        href={modalInfo.signupLink}>here.</a></FontBody14>
                                                    <br />
                                                </>
                                            }
                                            <FontBody14>{modalInfo.signature}</FontBody14>
                                            <br />
                                            <FontBody14>{props.advisorName}</FontBody14>
                                            <br />
                                            <div onClick={(e) => {
                                                navigator.clipboard.writeText(
                                                    `${modalInfo.subject}\n\n${modalInfo.intro}\n\n${modalInfo.text}\n${modalInfo.typeform}\n\nIf the link above doesn't work, copy and paste this url into your browser:\n${modalInfo.typeform}\n\n${modalInfo.signature}\n\n${props.advisorName}`);
                                                e.stopPropagation();
                                                setClipBoard("Copied!")
                                            }}><ButtonTertiary canSubmit={true} label={clipboard} /></div>
                                        </StyledModal>}
                                </div>
                            }
                        </Step>
                    </StepContainer>
                    <StepContainer borderRadius={'0 0 10px 10px'} backgroundColor={colors.action100} onClick={() => {
                        step === 4 ? setStep(-1) : setStep(4)
                    }}>
                        <TitleSection>
                            <FontTitle28>
                                {step === 4 ? <DownOutlined style={{ marginRight: "10px" }} /> :
                                    <RightOutlined style={{ marginRight: "10px" }} />}
                                Step 4: Complete your Advisor Biography
                            </FontTitle28>
                            <Icon image={shouldCheck(stepsCompleted[3])} />
                        </TitleSection>
                        <Step toggle={step === 4} onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}>
                            {props.hasClientAcq
                                ? <FontBody21To16>
                                    Willow’s Advisor Profile showcases that you are qualified to serve tomorrow’s
                                    clients. With our help, create a friendly biography that will be presented on your
                                    custom web page for prospective clients to learn more about you!
                                </FontBody21To16>
                                : <FontBody21To16>
                                    With our help, we will create a friendly biography showcasing you are qualified to
                                    serve tomorrow’s clients!
                                </FontBody21To16>
                            }
                            {!stepsCompleted[3] &&
                                <>
                                    <Buffer />
                                    <BtnDiv onClick={handleStepFour}><ButtonTertiary canSubmit={true}
                                        label={"Set up my Advisor Biography "} /></BtnDiv>
                                </>
                            }
                        </Step>
                    </StepContainer>
                </Container>
            </>
        )

    }
}


const mapStateToProps = state => ({
    userId: state.common.user.get("userId"),
    firstName: state.common.user.get("first"),
    hasClientAcq: state.common.user.get("directoryListings") === Map() ? false : state.common.user.get("directoryListings").get("clientAcquisitionUntil") !== null
})

export default connect(mapStateToProps, null)(CertificationStepList);
