import React from 'react';
import styled from "styled-components";
import moment from "moment/moment";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

/* Components */
import {colors} from "../../../../common/components/colors";
import { FontTitle24, FontBody18 } from '../../../../common/components/fonts';
import Calendar from "../../../../common/components/calendar"
import {ButtonPrimary} from "../../../../common/components/buttons";

/* Middleware */
import {tryGetAllMyCoaches, tryGetCoachingAppointments, tryGetUpcomingTrainingsRegisteredFor} from "../middleware/home";
import {certificationValidation} from "../../certification/middleware/certificationValidation";

/* Containers */
import {AppointmentsList} from "../../../../consumer/appointments/containers/appointmentslist";
import {ProgramsList} from "../../../../consumer/programs/containers/myprogramslist";

import FirmSchedulingRescheduleCancel from "../../../../iframes/reschedulermodal/containers/reschedulecancel";

const DashboardContainer = styled.div`
    border-radius: 30px;
    background-color: ${colors.white};
    box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
    justify-content: center;
    gap: 20px;
    display: flex;
    flex-direction: column;
`;
const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
const NoEvents = styled(FontBody18)`
  text-align: center;
`;
const Events = styled.div`
  margin: 0 40px;
  display: flex;
  gap: 25px;
  flex-direction: column;
  @media screen and (max-width: 748px) {
      margin: 0 20px;
  }
  @media screen and (max-width: 450px) {
      margin: 0 10px;
  }
`;
const ButtonMargin = styled.div`
  margin: 10px auto 0 auto;
  width: fit-content;
`;

class FirmDashboardAppointments extends React.Component {
    state = {
        "selectedDay": new Date(),
        "highlightedDates": [],
        "events": [],
        "selectedDayEvents": [],
        "selectedDayEventsMeetings": [],
        "selectedDayEventsPrograms": [],
        "isLoading": true,
        "allCoaches": [],
        "sessionSelected": {},
        "showModal": false,
        "completedCertifications": null
    }

    componentDidMount() {
        this.init();
    }

    init = async () => {
        if(this.props.isLily) {

            // Meetings
            const meetings = await this.props.tryGetCoachingAppointments();
            const meetingsAll = meetings.upcomingCalls.concat(meetings.pastCalls);
            const meetingsAllDates = await this.meetingDates(meetingsAll);
            const meetingsAllTags = await this.addTimeInfo(meetingsAll);

            // Programs
            const programs = await this.props.tryGetUpcomingTrainingsRegisteredFor();
            const programsDates = await this.programDates(programs);
            const programsAllTags = await this.programTags(programs);

            // Combine
            const eventsTags = meetingsAllTags.concat(programsAllTags.flat());
            const eventsDates = programsDates.flat().concat(meetingsAllDates);

            const validation = await this.props.certificationValidation();

            await this.setState({
                "events": eventsTags,
                "highlightedDates": eventsDates.map(e => {return(new Date(e))}),
                "allCoaches": await this.props.tryGetAllMyCoaches(),
                "showModal": false,
                "completedCertifications": validation.completedCertifications,
                "isLoading": false
            })

        } else {

            await this.setState({"isLoading": false})

        }
    }

    addTimeInfo = (meetings) => {
        return meetings.reduce((filtered, c) => {
            let isPast = moment(c.end).isBefore(moment());
            let isUpcoming = moment(c.start).isAfter(moment());
            let isOngoing = (moment(c.start).isBefore(moment()) && moment(c.end).isAfter(moment()));
            let isMissed = !c.isMissed === undefined ? false : c.isMissed;
            let end = (c.expected_end === undefined || c.expected_end === null) ? c.end : c.expected_end;
            let d = c.start;
            filtered.push({
                ...c,
                "type": ((isUpcoming || isOngoing) ? "upcoming" : (isMissed ? "missed" : "past")),
                "length": moment.duration(moment(end).diff(moment(c.start))).asMinutes().toString(),
                "date": moment(d).format("ll").toString() + ", " + moment(d).format("h:mma").toString() + " - " + moment(end).format("h:mma").toString(),
                "tag": "meeting"
            })
            return filtered;
        }, []);
    };

    meetingDates = async (meetings) => {
        return Promise.all(meetings.map(m => {return(m.start)}))
    }

    lectureDates = async (lectures) => {
        return Promise.all(lectures.map(l => {return(l.webinar.flashSaleStartDateTime)}))
    }

    lectureListExtract = async (lectures, program) => {
        return Promise.all(lectures.map(l => {return({
            ...l.webinar,
            "programTitle": program.groupProgram.title,
            "program": program,
            "tag": "program"
        })}))
    }

    programDates = async (programs) => {
        return Promise.all(programs.map(p => {return(this.lectureDates(p.groupProgram.lectures))}))
    }

    programTags = async (programs) => {
        return Promise.all(programs.map(async p => {
            return this.lectureListExtract(p.groupProgram.lectures, p);
        }))
    }

    onDateChange = async (selectedDay) => {
        const events = this.state.events.filter(e => {
            if(e.tag === "meeting") {
                return(moment(e.start).format("YYYY-MM-DD") === moment(selectedDay).format("YYYY-MM-DD"));
            } else {
                return(moment(e.flashSaleStartDateTime).format("YYYY-MM-DD") === moment(selectedDay).format("YYYY-MM-DD"));
            }
        })
        await this.setState({
            "selectedDay": selectedDay,
            "selectedDayEvents": events,
            "selectedDayEventsMeetings": events.filter(e => {return (e.tag === "meeting")}),
            "selectedDayEventsPrograms": events.filter(e => {return (e.tag === "program")})
        })
    }

    executeCancel = async () => {this.props.history.push('/dashboard'); this.props.history.go(0); await this.init(); }
    executeSchedule = async () => {this.props.history.push((this.state.completedCertifications === null ? "/my-certificates" : "")+"/my-coaches")}
    executeReschedule = async () => {this.props.history.push((this.state.completedCertifications === null ? "/my-certificates" : "")+"/my-coaches/"+this.state.sessionSelected.remoteUserId+"/appointment")}
    hideModal = async () => this.setState({"sessionSelected": {}, "showModal": false});
    showModal = async (sessionSelected) => this.setState({"sessionSelected": sessionSelected, "showModal": true});

    render() {
        return(
            <DashboardContainer>
                <FontTitle24>My Appointments</FontTitle24>
                <FlexRow>
                    {!this.state.isLoading &&
                        <Calendar
                            selectedDay={this.state.selectedDay}
                            onDateChange={this.onDateChange}
                            highlightDates={this.state.highlightedDates}
                        />
                    }
                </FlexRow>
                {this.state.selectedDayEvents.length > 0 ?
                    <Events>
                        <AppointmentsList
                            meetings={this.state.selectedDayEventsMeetings}
                            members={this.state.allCoaches.map(a => {return({...a, "id": a.coachId})})}
                            reschedule={this.showModal}
                            shapeStartTopLeftRound={false}
                            hideEmpty={true}
                        />
                        <ProgramsList
                            programs={this.state.selectedDayEventsPrograms}
                            seeDetail={(id) => this.props.history.push("/my-certificates/my-trainings/"+id)}
                            shapeStartTopLeftRound={false}
                            dashboard={true}
                            hideEmpty={true}
                        />
                    </Events>
                    :
                    <NoEvents>{'No coaching or trainings scheduled for ' + moment(this.state.selectedDay).format('MMMM Do')}</NoEvents>
                }
                <FirmSchedulingRescheduleCancel
                    hideModal={this.hideModal}
                    onExecuteCancel={this.executeCancel}
                    onExecuteReschedule={this.executeReschedule}
                    sessionSelected={this.state.sessionSelected}
                    showModal={this.state.showModal}
                />
                {this.state.completedCertifications !== null &&
                    <ButtonMargin onClick={this.executeSchedule}>
                        <ButtonPrimary canSubmit={false} label={"Book appointment"}/>
                    </ButtonMargin>
                }
            </DashboardContainer>
        )
    }
}

const mapStateToProps = state => ({
    isLily: state.common.user.get("isLily")
});

const mapDispatchToProps = dispatch => ({
    tryGetAllMyCoaches: () => dispatch(tryGetAllMyCoaches()),
    tryGetCoachingAppointments: () => dispatch(tryGetCoachingAppointments()),
    tryGetUpcomingTrainingsRegisteredFor: () => dispatch(tryGetUpcomingTrainingsRegisteredFor()),
    certificationValidation: () => dispatch(certificationValidation()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FirmDashboardAppointments));