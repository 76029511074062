import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";

/* Containers */

/* Middleware */
import { trySaveCriteriaDetails } from "../middleware/prospects";
import { tryGetAdvisorProfile } from "../middleware/prospects";

/* Components */
import { LogoLoading } from "../../../../common/components/loading";
import { ButtonPrimary } from "../../../../common/components/buttons";
import { colors } from "../../../../common/components/colors";
import { TextNoInput, TextareaInputNoCountWhite, TextareaInputNoCountWhiteSubtitle, DropdownInputWhite, TextInputWhite } from "../../../../common/components/inputs";
import { FontBody16, FontSubtitle16 } from "../../../../common/components/fonts";
import { BtnDiv } from "../../../components/advisorpreviewcard";
import { LoadingCenter } from "../../../shop/components/faqs";
import { ToolTipBody } from "../../../../common/components/messages";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ToolTipContainer } from "../../certification/components/directoryform";

//#region Checkbox container
const CheckboxBorderedContainer = styled.div`
    border: 1px solid ${colors.primary30};
    border-radius: 4px;
    padding: 10px 20px;
`;

const CheckboxContainer = styled.div`
    display: grid;
    grid-template-columns: ${(props) => props.columns || "repeat(10, 1fr)"};
    gap: 10px;
    @media screen and (max-width: 750px) {
        grid-template-columns: 1fr 1fr;
    }
`;

const CheckboxLabel = styled.label`
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-right: ${(props) => props.marginRight || "0"};
    @media screen and (max-width: 1440px) {
        white-space: normal;
    }
`;

const Checkbox = styled.input`
    height: 18px;
    width: 18px;
    flex: none;
    accent-color: ${colors.action100};
`;

const CheckboxGroup = ({ title, items, checkedItems, onItemCheck, showCheckAll, onCheckAll, valid, warning, columns }) => (
    <>
        <TextNoInput title={title} valid={valid} warning={warning} />
        <CheckboxBorderedContainer>
            {showCheckAll && (
                <CheckboxLabel style={{ marginBottom: "20px" }}>
                    <Checkbox type="checkbox" onChange={onCheckAll} />
                    <FontBody16> Check All </FontBody16>
                </CheckboxLabel>
            )}
            <CheckboxContainer columns={columns}>
                {items.map((item, index) => (
                    <CheckboxLabel key={index}>
                        <Checkbox type="checkbox" value={item} checked={checkedItems[index]} onChange={(e) => onItemCheck(index)(e)} />
                        <FontBody16> {item} </FontBody16>
                    </CheckboxLabel>
                ))}
            </CheckboxContainer>
        </CheckboxBorderedContainer>
    </>
);

//#endregion

const SaveButtonMessageContainer = styled.div`
    margin-bottom: 20px;
    margin-top: 20px;
    height: 18px;
`;

//#region Answers
const YEARS_TO_RETIREMENT = ["1-5 Years", "5-10 Years", "10-20 Years", "20+ Years", "Retired"];
const RELATIONSHIP_STATUS = ["Single", "Relationship", "Married", "Domestic Partnership", "Getting divorced", "Divorced", "Widowed"];
const SPECIALIZED_DEMOGRAPHIC = ["Military members / veterans", "Special needs", "Caregivers", "LGBTQ+", "Underrepresented group", "Business owners/entrepreneurs", "Young families", "HENRYs"];
const GENDER_PREFERENCES = ["Male", "Female", "Non-binary", "Other", "All genders"];
const SPECIALITY_PLANNING = [
    "Family planning including fertility planning/adoption",
    "Planning for education",
    "Inheritance",
    "Managing Debt",
    "Real estate purchase/sale",
    "Crypto",
    "Alternative Investments",
    "ESG",
];
const SERVICE_AREAS = [
    "One time financial plans",
    "Trusts & wills",
    "Insurance",
    "Financial coaching",
    "Tax",
    "Real Estate",
    "Debt management",
    "Cash flow analysis/Budgeting",
    "Employer benefit analysis",
];
const COMMUNICATION_PREFERENCES = ["Monthly", "Quarterly", "6 months", "Annually", "As needed"];
const MEETING_FORMAT = ["In person", "Virtual"];
const FEE_STRUCTURE = [
    { value: 1, label: "Flat fee hourly" },
    { value: 2, label: "Flat fee monthly" },
    { value: 3, label: "Flat fee yearly" },
    { value: 4, label: "Percentage of assets" },
    { value: 5, label: "Flexible" },
];
const STATES = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
    "PR",
    "VI",
    "GU",
];
const ASSET_OPTIONS = [
    { value: "0", label: "No minimum" },
    { value: "100000", label: "Minimum $100K" },
    { value: "250000", label: "Minimum $250K" },
    { value: "500000", label: "Minimum $500K" },
    { value: "1000000", label: "Minimum $1M" },
    { value: "2500000", label: "Minimum $2.5M" },
    { value: "5000000", label: "Minimum $5M" },
];
//#endregion

export default function FirmMarketingCriteriaQuestions() {
    const dispatch = useDispatch();
    const [assets, setAssets] = useState("");
    const [goodClient, setGoodClient] = useState("");
    const [badClient, setBadClient] = useState("");
    const [addFees, setAddFees] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [checkedStates, setCheckedStates] = React.useState(Array(STATES.length).fill(false));
    const [feeTypes, setFeeTypes] = React.useState(Array(FEE_STRUCTURE.length).fill(false));
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [toolTipSchedulingLink, setToolTipSchedulingLink] = useState(false);
    const [schedulingLink, setSchedulingLink] = useState("");
    const [checkedYearsToRetirement, setCheckedYearsToRetirement] = React.useState(Array(YEARS_TO_RETIREMENT.length).fill(false));
    const [checkedRelationshipStatus, setCheckedRelationshipStatus] = React.useState(Array(RELATIONSHIP_STATUS.length).fill(false));
    const [checkedSpecializedDemographic, setCheckedSpecializedDemographic] = React.useState(Array(SPECIALIZED_DEMOGRAPHIC.length).fill(false));
    const [checkedGenderPreferences, setCheckedGenderPreferences] = React.useState(Array(GENDER_PREFERENCES.length).fill(false));
    const [checkedSpecialityPlanning, setCheckedSpecialityPlanning] = React.useState(Array(SPECIALITY_PLANNING.length).fill(false));
    const [checkedServiceAreas, setCheckedServiceAreas] = React.useState(Array(SERVICE_AREAS.length).fill(false));
    const [checkedCommunicationPreferences, setCheckedCommunicationPreferences] = React.useState(Array(COMMUNICATION_PREFERENCES.length).fill(false));
    const [checkedMeetingFormat, setCheckedMeetingFormat] = React.useState(Array(MEETING_FORMAT.length).fill(false));

    useEffect(() => {
        init();
    }, []);
    const assetsNumber = assets ? Number(assets.replace(/[\$,]/g, "")) : -1;
    const statesValid = checkedStates.some((state) => state === true);
    const feeValid = feeTypes.some((fee) => fee === true);
    const formValid = statesValid && feeValid && assetsNumber !== -1 && goodClient !== "" && badClient !== "";

    // checkbox setters
    const handleCheckboxGroupSingleValue = (currentState, setter) => (index) => async (event) => {
        let newChecked = Array(currentState.length).fill(false);
        newChecked[index] = event.target.checked;
        setter(newChecked);
    };

    const handleCheckboxGroupMultipleValues = (currentState, setter) => (index) => async (event) => {
        let newChecked = [...currentState];
        newChecked[index] = event.target.checked;
        setter(newChecked);
    };

    const handleCheckboxGroupSelectAllValues = (currentState, setter) => async (event) => {
        let newChecked = Array(currentState.length).fill(event.target.checked);
        setter(newChecked);
    };

    const getCheckedValues = (currentState, values) => {
        return values.filter((_, index) => currentState[index]);
    };

    const getFeeValues = () => {
        return FEE_STRUCTURE.filter((_, index) => feeTypes[index]).map((item) => item.value);
    };

    const handleSave = async () => {
        setIsLoading(true);
        setSuccess("");
        setError("");

        if (assetsNumber === "" || assetsNumber === null || assetsNumber === undefined || assetsNumber === -1) {
            setIsLoading(false);
            setError("Please enter minimum assets");
            return;
        }
        if (!statesValid) {
            setIsLoading(false);
            setError("Please select at least one state");
            return;
        }
        if (goodClient === "" || goodClient === null || goodClient === undefined) {
            setIsLoading(false);
            setError("Please describe your ideal client");
            return;
        }
        if (badClient === "" || badClient === null || badClient === undefined) {
            setIsLoading(false);
            setError("Please describe clients you feel are not a fit for your practice");
            return;
        }
        if (!feeValid) {
            setIsLoading(false);
            setError("Please select at least one fee structure");
            return;
        }
        if (formValid) {
            await dispatch(
                trySaveCriteriaDetails({
                    minimumInvestableAssets: assetsNumber,
                    statesCovered: getCheckedValues(checkedStates, STATES),
                    goodClientFit: goodClient,
                    badClientFit: badClient,
                    feeTypes: getFeeValues(),
                    feeDescription: addFees,
                    schedulingLink: schedulingLink,
                    yearsToRetirement: getCheckedValues(checkedYearsToRetirement, YEARS_TO_RETIREMENT),
                    relationshipStatus: getCheckedValues(checkedRelationshipStatus, RELATIONSHIP_STATUS),
                    specializedDemographic: getCheckedValues(checkedSpecializedDemographic, SPECIALIZED_DEMOGRAPHIC),
                    genderPreferences: getCheckedValues(checkedGenderPreferences, GENDER_PREFERENCES),
                    specialityPlanningTopics: getCheckedValues(checkedSpecialityPlanning, SPECIALITY_PLANNING),
                    serviceAreas: getCheckedValues(checkedServiceAreas, SERVICE_AREAS),
                    communicationPreferences: getCheckedValues(checkedCommunicationPreferences, COMMUNICATION_PREFERENCES),
                    meetingFormatPreferences: getCheckedValues(checkedMeetingFormat, MEETING_FORMAT),
                })
            );
            setSuccess("Successfully saved");
        }
        setIsLoading(false);
    };

    const init = async () => {
        setIsLoading(true);
        const profile = await dispatch(tryGetAdvisorProfile());
        setAssets(profile?.minimumInvestableAssets !== null && profile?.minimumInvestableAssets !== undefined ? profile.minimumInvestableAssets.toString() : "");
        setGoodClient(profile?.goodClientFit ? profile.goodClientFit : "");
        setBadClient(profile?.badClientFit ? profile.badClientFit : "");
        setAddFees(profile?.feeDescription ? profile.feeDescription : "");
        setSchedulingLink(profile?.schedulingLink ? profile.schedulingLink : "");

        if (profile?.statesCovered) {
            setCheckedStates(STATES.map((item) => profile.statesCovered.includes(item)));
        }
        if (profile?.feeTypes) {
            setFeeTypes(FEE_STRUCTURE.map((item) => profile.feeTypes.includes(item.value)));
        }

        if (profile?.yearsToRetirement) {
            setCheckedYearsToRetirement(YEARS_TO_RETIREMENT.map((item) => profile.yearsToRetirement.includes(item)));
        }
        if (profile?.relationshipStatus) {
            setCheckedRelationshipStatus(RELATIONSHIP_STATUS.map((item) => profile.relationshipStatus.includes(item)));
        }
        if (profile?.specializedDemographic) {
            setCheckedSpecializedDemographic(SPECIALIZED_DEMOGRAPHIC.map((item) => profile.specializedDemographic.includes(item)));
        }
        if (profile?.genderPreferences) {
            setCheckedGenderPreferences(GENDER_PREFERENCES.map((item) => profile.genderPreferences.includes(item)));
        }
        if (profile?.specialityPlanningTopics) {
            setCheckedSpecialityPlanning(SPECIALITY_PLANNING.map((item) => profile.specialityPlanningTopics.includes(item)));
        }
        if (profile?.serviceAreas) {
            setCheckedServiceAreas(SERVICE_AREAS.map((item) => profile.serviceAreas.includes(item)));
        }
        if (profile?.communicationPreferences) {
            setCheckedCommunicationPreferences(COMMUNICATION_PREFERENCES.map((item) => profile.communicationPreferences.includes(item)));
        }
        if (profile?.meetingFormatPreferences) {
            setCheckedMeetingFormat(MEETING_FORMAT.map((item) => profile.meetingFormatPreferences.includes(item)));
        }
        setIsLoading(false);
    };

    if (isLoading) {
        return (
            <LoadingCenter>
                <LogoLoading />
            </LoadingCenter>
        );
    }
    return (
        <>
            <CheckboxGroup
                title="Which states and territories are you able/willing to work in"
                items={STATES}
                checkedItems={checkedStates}
                onItemCheck={handleCheckboxGroupMultipleValues(checkedStates, setCheckedStates)}
                showCheckAll={true}
                onCheckAll={handleCheckboxGroupSelectAllValues(checkedStates, setCheckedStates)}
                valid={statesValid}
                warning="Required"
                columns="repeat(10, 1fr)"
            />
            <CheckboxGroup
                title="Describe your fee structure: "
                items={FEE_STRUCTURE.map((item) => item.label)}
                checkedItems={feeTypes}
                onItemCheck={handleCheckboxGroupMultipleValues(feeTypes, setFeeTypes)}
                valid={feeValid}
                warning="Required"
                columns="repeat(1, 1fr)"
            />
            <TextareaInputNoCountWhiteSubtitle
                title={"Additional information on fees "}
                valid={addFees !== ""}
                id={"addFees"}
                onChange={(e) => setAddFees(e.target.value)}
                value={addFees}
                minRows={5}
                minLength={0}
                subtitle={"Please provide any additional information on your fees that you think is important for clients to know"}
            />
            <DropdownInputWhite
                id={"assets"}
                title={"What are your required minimum assets?"}
                options={ASSET_OPTIONS}
                valid={assets !== null && assets !== ""}
                value={assets}
                onChange={(e) => setAssets(e.value)}
                warning={"Required"}
            />
            <TextareaInputNoCountWhiteSubtitle
                title={"Who is your ideal client?"}
                valid={goodClient !== ""}
                id={"goodClient"}
                onChange={(e) => setGoodClient(e.target.value)}
                value={goodClient}
                minRows={5}
                minLength={0}
                warning={"Required"}
                subtitle={"Describe their demographics, profession, goals, life journey and other relevant information."}
            />
            <TextareaInputNoCountWhite
                title={"Which clients do you feel are not a fit for your practice? "}
                valid={badClient !== ""}
                id={"badClient"}
                onChange={(e) => setBadClient(e.target.value)}
                value={badClient}
                minRows={5}
                minLength={0}
                warning={"Required"}
            />
            <ToolTipContainer>
                <ToolTipBody show={toolTipSchedulingLink} onMouseLeave={() => setToolTipSchedulingLink(false)}>
                    <FontSubtitle16>*For Willow internal purposes only</FontSubtitle16>
                </ToolTipBody>

                <TextInputWhite
                    title={
                        <span>
                            Input a link to your schedule{" "}
                            <span style={{ width: "50px", height: "50px" }} onMouseEnter={() => setToolTipSchedulingLink(true)}>
                                <InfoCircleOutlined />{" "}
                            </span>
                        </span>
                    }
                    valid={schedulingLink !== ""}
                    id={"schedulingLink"}
                    onChange={(e) => setSchedulingLink(e.target.value)}
                    value={schedulingLink}
                />
            </ToolTipContainer>
            <CheckboxGroup
                title="Target client retirement timeline"
                items={YEARS_TO_RETIREMENT}
                checkedItems={checkedYearsToRetirement}
                onItemCheck={handleCheckboxGroupMultipleValues(checkedYearsToRetirement, setCheckedYearsToRetirement)}
                columns="repeat(2, 1fr)"
            />
            <CheckboxGroup
                title="Client relationship status"
                items={RELATIONSHIP_STATUS}
                checkedItems={checkedRelationshipStatus}
                onItemCheck={handleCheckboxGroupMultipleValues(checkedRelationshipStatus, setCheckedRelationshipStatus)}
                columns="repeat(2, 1fr)"
            />

            <CheckboxGroup
                title="Specialized demographics you serve"
                items={SPECIALIZED_DEMOGRAPHIC}
                checkedItems={checkedSpecializedDemographic}
                onItemCheck={handleCheckboxGroupMultipleValues(checkedSpecializedDemographic, setCheckedSpecializedDemographic)}
                columns="repeat(2, 1fr)"
            />

            <CheckboxGroup
                title="Gender preferences you work with"
                items={GENDER_PREFERENCES}
                checkedItems={checkedGenderPreferences}
                onItemCheck={handleCheckboxGroupMultipleValues(checkedGenderPreferences, setCheckedGenderPreferences)}
                columns="repeat(2, 1fr)"
            />

            <CheckboxGroup
                title="Specialty planning topics"
                items={SPECIALITY_PLANNING}
                checkedItems={checkedSpecialityPlanning}
                onItemCheck={handleCheckboxGroupMultipleValues(checkedSpecialityPlanning, setCheckedSpecialityPlanning)}
                columns="repeat(2, 1fr)"
            />

            <CheckboxGroup
                title="Service areas"
                items={SERVICE_AREAS}
                checkedItems={checkedServiceAreas}
                onItemCheck={handleCheckboxGroupMultipleValues(checkedServiceAreas, setCheckedServiceAreas)}
                columns="repeat(2, 1fr)"
            />

            <CheckboxGroup
                title="How often do you typically communicate with clients?"
                items={COMMUNICATION_PREFERENCES}
                checkedItems={checkedCommunicationPreferences}
                onItemCheck={handleCheckboxGroupMultipleValues(checkedCommunicationPreferences, setCheckedCommunicationPreferences)}
                columns="repeat(2, 1fr)"
            />

            <CheckboxGroup
                title="Preferred meeting format"
                items={MEETING_FORMAT}
                checkedItems={checkedMeetingFormat}
                onItemCheck={handleCheckboxGroupMultipleValues(checkedMeetingFormat, setCheckedMeetingFormat)}
                columns="repeat(2, 1fr)"
            />

            <SaveButtonMessageContainer>
                {error && <FontBody16 style={{ color: `${colors.stoplightStop100}` }}>{error}</FontBody16>}
                {success && <FontBody16 style={{ color: `${colors.stoplightGo100}` }}>{success}</FontBody16>}
            </SaveButtonMessageContainer>

            <BtnDiv onClick={handleSave}>
                <ButtonPrimary canSubmit={true} label={"Save"} />
            </BtnDiv>
        </>
    );
}
