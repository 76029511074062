import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

/* Middleware */
import { tryPostAdvisorDirectoryRedirect } from './middleware/directory';
// import { tryPostMixpanel, tryPostMailchimp } from '../../common/utils/tracking';

/* Components */
import Card from './containers/card';
import IFrameAdvisorDirectoryMatchIntro from "./containers/intromatch"
import IFrameAdvisorDirectoryNav from "./containers/nav";
import { FontBody16 } from "../../common/components/fonts";
import { LogoLoading } from "../../common/components/loading";
import moment from "moment/moment";

const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 60px;
`;
export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(100% - 60px);
  padding: 0 30px;
  gap: 60px;
  flex-wrap: wrap;
`;
const LoadingCenter = styled.div`
    margin: 60px auto;
`;

export default function IFrameAdvisorDirectoryRedirect() {
    // const [nameSearch, setNameSearch] = useState('');
    // const [zipCode, setZipCode] = useState('');
    // const [checkboxes, setCheckboxes] = useState([]);
    const [cardList, setCardList] = useState({
        list: [],
        isLoading: true
    });
    const dispatch = useDispatch();

    useEffect(() => {
        initRedirect()
    }, []);

    const initRedirect = async () => {
        const searchParams = new URLSearchParams(window.location.search);
        const t1 = searchParams.get("t1"); // anonymousId
        const t2 = searchParams.get("t2"); // requestType
        sessionStorage.setItem("t1", t1);
        sessionStorage.setItem("t2", t2);
        const ropt = searchParams.get("ropt") // rangeId
        const st = searchParams.get("st") // st
        const data = { t1: t1, t2: t2, ropt: ropt, st: st }
        let advisorIds = sessionStorage.getItem("advisorIds");
        let advisorData = sessionStorage.getItem("advisorData");
        advisorData = JSON.parse(advisorData);
        if (advisorIds === undefined || advisorIds === null || advisorIds === "null" || advisorIds === "[object Object]") {
            const requestData = { t1: t1, t2: t2, ropt: ropt, st: st, details: true, newmap: true };
            // dispatch(tryPostMixpanel(null, t1, "clientAcquisitionRequestMatches", requestData));
            advisorData = await dispatch(tryPostAdvisorDirectoryRedirect(requestData));
            console.log("Ad data", advisorData);
            advisorIds = advisorData.map(a => { return a.id });
            sessionStorage.setItem("advisorIds", advisorIds);
            sessionStorage.setItem("advisorData", JSON.stringify(advisorData));
            // dispatch(tryPostMixpanel(null, t1, "clientAcquisitionViewMatches", {
            //     ...data,
            //     ids: advisorIds,
            //     id1: advisorIds[0],
            //     id2: advisorIds[1],
            //     id3: advisorIds[2]
            // }));
            // dispatch(tryPostMailchimp({ "t1": t1, "tagId": "ACQVIEWDIR", "tagValue": "TRUE" }))
            // dispatch(tryPostMailchimp({ "t1": t1, "tagId": "ADVREC1NAM", "tagValue": advisorData[0].firstName+" "+advisorData[0].lastName }))
            // dispatch(tryPostMailchimp({ "t1": t1, "tagId": "ADVREC2NAM", "tagValue": advisorData[1].firstName+" "+advisorData[1].lastName }))
            // dispatch(tryPostMailchimp({ "t1": t1, "tagId": "ADVREC3NAM", "tagValue": advisorData[2].firstName+" "+advisorData[2].lastName }))
            // dispatch(tryPostMailchimp({ "t1": t1, "tagId": "ADVREC1IMG", "tagValue": advisorData[0].additionalProfileInformation.photos[0] }))
            // dispatch(tryPostMailchimp({ "t1": t1, "tagId": "ADVREC2IMG", "tagValue": advisorData[1].additionalProfileInformation.photos[0] }))
            // dispatch(tryPostMailchimp({ "t1": t1, "tagId": "ADVREC3IMG", "tagValue": advisorData[2].additionalProfileInformation.photos[0] }))
            // dispatch(tryPostMailchimp({ "t1": t1, "tagId": "ADVREC1CER", "tagValue": advisorData[0].certifications.join(", ") }))
            // dispatch(tryPostMailchimp({ "t1": t1, "tagId": "ADVREC2CER", "tagValue": advisorData[1].certifications.join(", ") }))
            // dispatch(tryPostMailchimp({ "t1": t1, "tagId": "ADVREC3CER", "tagValue": advisorData[2].certifications.join(", ") }))
            // dispatch(tryPostMailchimp({ "t1": t1, "tagId": "ACQUPDATED", "tagValue": moment().format("MM/DD/YYYY") }))
            // advisorData.forEach((a) => {
            //     dispatch(tryPostMixpanel(null, t1, "clientAcquisitionViewMatchesIds", {
            //         ...data,
            //         advisorId: a.id,
            //         advisorName: a.firstName + " " + a.lastName
            //     }));
            // });
        }

        const advisorsCleaned = await advisorData.reduce((filtered, a) => {
            let photos = a.additionalProfileInformation.photos.filter(p => { return (p !== null) })
            let logos = a.additionalProfileInformation.logos.filter(l => { return (l !== null) })
            let date = a.badActorDate;
            let advisorRecord = {
                ...a,
                ...a.additionalProfileInformation,
                photos: photos.length > 0 ? photos[0] : null,
                logos: logos.length > 0 ? logos[0] : null,
                priority: false, // is sponsored advisor
                active: true, // is advisor active (expiration not hit)
                minAssets: parseInt(a.minimumInvestableAssets),
                gr: a.gender,
            };
            if (date) {
                // bad actor
            } else {
                filtered.push(advisorRecord)
            }
            return filtered;
        }, []);
        setCardList({
            list: advisorsCleaned,
            isLoading: false
        })
    }

    if (cardList.isLoading) {
        return (<LoadingCenter><LogoLoading /></LoadingCenter>)
    }

    return (
        <Page>
            <IFrameAdvisorDirectoryNav />
            <IFrameAdvisorDirectoryMatchIntro />
            {/*<IFrameAdvisorDirectoryFilter handleCardListChange={handleCardListChange} />*/}
            <CardContainer>
                {cardList.list.length === 0
                    ? <div style={{ margin: "0 auto" }}><FontBody16>No advisors found</FontBody16></div>
                    : <>
                        {cardList.list.map((advisor, index) => (
                            <Card key={index} advisor={advisor} allAdvisors={cardList.list} />
                        ))}
                    </>
                }
            </CardContainer>
        </Page>
    );
};

// import React, { useEffect, useState } from 'react';
// import styled from 'styled-components';
// import { useDispatch } from 'react-redux';

// /* Middleware */
// import { tryGetCertifiedAdvisors, tryPostAdvisorDirectoryRedirect } from './middleware/directory';
// import { tryPostMixpanel, tryPostMailchimp } from '../../common/utils/tracking';

// /* Components */
// import Card from './containers/card';
// import IFrameAdvisorDirectoryMatchIntro from "./containers/intromatch"
// import IFrameAdvisorDirectoryNav from "./containers/nav";
// import { FontBody16 } from "../../common/components/fonts";
// import { LogoLoading } from "../../common/components/loading";
// import moment from "moment/moment";

// const Page = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 40px;
//   padding-bottom: 60px;
// `;
// export const CardContainer = styled.div`
//   display: flex;
//   flex-direction: row;
//   width: calc(100% - 60px);
//   padding: 0 30px;
//   gap: 60px;
//   flex-wrap: wrap;
// `;
// const LoadingCenter = styled.div`
//     margin: 60px auto;
// `;

// export default function IFrameAdvisorDirectoryRedirect() {
//     // const [nameSearch, setNameSearch] = useState('');
//     // const [zipCode, setZipCode] = useState('');
//     // const [checkboxes, setCheckboxes] = useState([]);
//     const [cardList, setCardList] = useState({
//         list: [],
//         isLoading: true
//     });
//     const dispatch = useDispatch();

//     useEffect(() => {
//         initRedirect()
//     }, []);

//     const initRedirect = async () => {
//         const searchParams = new URLSearchParams(window.location.search);
//         const t1 = searchParams.get("t1"); // anonymousId
//         const t2 = searchParams.get("t2"); // requestType
//         sessionStorage.setItem("t1", t1);
//         sessionStorage.setItem("t2", t2);
//         const ropt = searchParams.get("ropt") // rangeId
//         const st = searchParams.get("st") // st
//         const data = { t1: t1, t2: t2, ropt: ropt, st: st }
//         let advisorIds = sessionStorage.getItem("advisorIds");
//         let advisorData = sessionStorage.getItem("advisorData");
//         advisorData = JSON.parse(advisorData);
//         if (advisorIds === undefined || advisorIds === null || advisorIds === "null" || advisorIds === "[object Object]") {
//             advisorData = await dispatch(tryPostAdvisorDirectoryRedirect({ t1: t1, t2: t2, ropt: ropt, st: st, details: true }));
//             advisorIds = advisorData.map(a => { return a.id });
//             sessionStorage.setItem("advisorIds", advisorIds);
//             sessionStorage.setItem("advisorData", JSON.stringify(advisorData));
//             dispatch(tryPostMixpanel(null, t1, "clientAcquisitionViewMatches", {
//                 ...data,
//                 ids: advisorIds,
//                 id1: advisorIds[0],
//                 id2: advisorIds[1],
//                 id3: advisorIds[2]
//             }));
//             dispatch(tryPostMailchimp({ "t1": t1, "tagId": "ACQVIEWDIR", "tagValue": "TRUE" }))
//             dispatch(tryPostMailchimp({ "t1": t1, "tagId": "ACQUPDATED", "tagValue": moment().format("MM/DD/YYYY") }))
//             advisorData.forEach(async (a) => {
//                 dispatch(tryPostMixpanel(null, t1, "clientAcquisitionViewMatches", {
//                     ...data,
//                     advisorId: a.id,
//                     advisorName: a.firstName + " " + a.lastName
//                 }));
//             });
//         } else {
//             advisorIds = advisorIds.split(",");
//         }

//         const advisorsCleaned = await advisorData.reduce((filtered, a) => {
//             let photos = a.additionalProfileInformation.photos.filter(p => { return (p !== null) })
//             let logos = a.additionalProfileInformation.logos.filter(l => { return (l !== null) })
//             let date = a.badActorDate;
//             let advisorRecord = {
//                 ...a,
//                 ...a.additionalProfileInformation,
//                 photos: photos.length > 0 ? photos[0] : null,
//                 logos: logos.length > 0 ? logos[0] : null,
//                 priority: false, // is sponsored advisor
//                 active: true, // is advisor active (expiration not hit)
//                 minAssets: parseInt(a.minimumInvestableAssets),
//                 gr: a.gender,
//             };
//             if (date) {
//                 // bad actor
//             }
//             else {
//                 filtered.push(advisorRecord)
//             }
//             return filtered;
//         }, []);
//         setCardList({
//             list: advisorsCleaned,
//             isLoading: false
//         })
//     }

//     if (cardList.isLoading) {
//         return (<LoadingCenter><LogoLoading /></LoadingCenter>)
//     }

//     return (
//         <Page>
//             <IFrameAdvisorDirectoryNav />
//             <IFrameAdvisorDirectoryMatchIntro />
//             {/*<IFrameAdvisorDirectoryFilter handleCardListChange={handleCardListChange} />*/}
//             <CardContainer>
//                 {cardList.list.length === 0
//                     ? <div style={{ margin: "0 auto" }}><FontBody16>No advisors found</FontBody16></div>
//                     : <>
//                         {cardList.list.map((advisor, index) => (
//                             <Card key={index} advisor={advisor} allAdvisors={cardList.list} />
//                         ))}
//                     </>
//                 }
//             </CardContainer>
//         </Page>
//     );
// };
