import {images} from '../../common/components/images';
export const devAuthData = {
    "email": "jason.aronson@gmail.com",
    "first": "Jason",
    "last": "Aronson",
    "subscription": true,
    "platform": "coach", // coach, advisor, advisor_and_coach, consumer, enterprise
    "userId": 243241,
    "uid": "74d1a449-ba40-4ad0-9989-f148807f22ed",
    "onboardingCompleted": true,
    "phone": 1111111111,
    "newUser": false,
    "companyName": "Company Name, LLC",
    "photo": images.devDataTeamJasonAvatar,
    // "wmc": null,
    "wmc": {
        "id": 1,
        "address1": "",
        "address2": "",
        "city": "Boston",
        "companyLogoUrl": images.logo,
        "companyName": "Blackrock",
        "contactPersonCellPhone": "",
        "contactPersonEmail": "",
        "contactPersonFirstName": "",
        "contactPersonLastName": "",
        "contactPersonWorkPhone": "",
        "email": "",
        "facebookUrl": "",
        "linkedinUrl": "",
        "phone": "",
        "state": "MA",
        "twitterUrl": "",
        "zipCode": "",
        "defaultNumberOfSessions": 2,
        "hrCode": "ABCDEF",
    },
    "referralType": "willow", // "wmc" or "willow" or "coach"
    "expertCategories": [{"categoryId": 1, "categoryName": "Financial Coach"}],
    "isExclusive": false,
    "isLily": true,
    "isCoach": true,
    "timeZone": "eastern",
    "prospectWmcId": 35,
    "isReferredByPartner": true,
    "pms_sessions": 1,
    "willow_sessions": 14,
    "wmcEmployeeId": "blackrock",
    "certificationStatus":  {
        "userId": 5374,
        "womenCertRenewsOn": "2025-06-10T17:28:03.995592Z",
        "womenCertPurchasedOn": "2024-06-10T17:25:02Z",
        "womenCertCompletedOn": "2024-08-22T18:14:35.058569Z",
        "nextGenCertRenewsOn": null,
        "nextGenCertPurchasedOn": null,
        "nextGenCertCompletedOn": null,
        "womenCertStatus": "completed",
        "nextGenCertStatus": "no_purchase",
        "clientAcqPurchasedOn": null,
        "clientAcqRenewsOn": null
    },
    "certificationProgress": null,
    // "certificationProgress": {
    //     "legalConfirmationOn": null,
    //     "certificationProductPurchaseLogId": null,
    //     "certificationProductPurchasedOn": null,
    //     "coachId": 900,
    //     "certificationDefinition": {
    //         "id": 3,
    //         "name": "CERTIFIED ADVISOR FOR WOMEN™",
    //         "designation": "CAW™",
    //         "stripeProductId": "prod_testeee",
    //         "stripePriceId": "price_testeeee",
    //         "stripePrices": [
    //             {"priceAmount": 1, "priceId": "price_aasdasdas"},
    //             {"priceAmount": 1000, "priceId": "price_aasdassdacc"},
    //         ],
    //     },
    //     "details": {
    //         "attestationStep": {
    //             "completedSurveyTypeformValues": [{"surveyId" : "XeBmERhQ", score: 50}, {"surveyId": "KFInwqUi", score:50}]
    //         },
    //         "profileSetupStep": {
    //             "isCalendarSynced": true,
    //             "isProfileCompleted": false
    //         },
    //         "sessionStep": {
    //             "conferenceId": 123,
    //             "sessionReviews": [{"passed": 3}]
    //         },
    //         "trainingStep": {
    //             "completedMainTraining": [],
    //             // "completedTrainings": [],
    //             "completedTrainings": [
    //                 {webinarProgramId: 64},
    //                 {webinarProgramId: 62},
    //                 {webinarProgramId: 77},
    //             ],
    //             "numberOfCompletedPrograms": 3
    //         }
    //     },
    //     "isCompleted": true
    // },
    "completedCertificates": [
        {
            "advisorId": 900,
            "advisorFullName": "Jason Aronson",
            "advisor": {},
            "completedOn": '2023-09-21T05:00:00+00:00',
            "certificationDefinitionId": 3,
            "designation": "CAW™",
            "name": "CERTIFIED ADVISOR FOR WOMEN™",
            "certificationProgressId": 1,
            "purchaseId": null,
        },
        // {
        //     "advisorId": 900,
        //     "advisorFullName": "Jason Aronson",
        //     "advisor": {},
        //     "completedOn": '2023-08-18T05:00:00+00:00',
        //     "certificationDefinitionId": 4,
        //     "designation": "CAN™",
        //     "name": "CERTIFIED ADVISOR FOR NEXTGEN™",
        //     "certificationProgressId": 2,
        //     "purchaseId": 12,
        // },
    ],
    "certificationPurchases": [
        // {
        //     "certProgressId": 352,
        //     "certificationName": "CERTIFIED ADVISOR FOR NEXTGEN™",
        //     "purchaseLogId": 530,
        //     "purchasedOn": "2022-02-28T16:36:31+00:00"
        // // },
        // {
        //     "certProgressId": 352,
        //     "certificationName": "CERTIFIED ADVISOR FOR WOMEN™",
        //     "purchaseLogId": 530,
        //     "purchasedOn": "2022-02-28T16:36:31+00:00"
        // },
        {
            "certProgressId": 352,
            "certificationName": "CERTIFIED ADVISOR FOR WOMEN™",
            "purchaseLogId": 531,
            "purchasedOn": "2023-02-28T16:36:31+00:00"
        },
        // {
        //     "certProgressId": 352,
        //     "certificationName": "CERTIFIED ADVISOR FOR WOMEN™",
        //     "purchaseLogId": 532,
        //     "purchasedOn": "2024-02-28T16:36:31+00:00"
        // }
    ],
    "directoryListings": {
        "clientAcquisitionUntil": null,
        "nextGenUntil": null,
        "practiceManagementUntil": null,
        "womenDirectoryUntil": "2025-02-27T16:38:40.336694+00:00"
    }
};