import React, { useState } from 'react';
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuid4 } from 'uuid';
import moment from 'moment/moment';

/* Components */
import { colors } from "../../../common/components/colors";
import { Section } from "./components"
import { LogoLoading } from "../../../common/components/loading";

/* Containers */
import FunnelPage from './funnelpage';
import ContactPage1 from './contactpage1';
import ContactPage2 from "./contactpage2";
import ContactPage3 from "./contactpage3";
import ContactSuccess from "./contactsuccess";

/* Middleware */
import { tryClientRequest } from '../middleware/profile';
// import {tryPostMailchimp, tryPostMixpanel} from "../../../common/utils/tracking";

const LoadingCenter = styled.div`
    margin: 60px auto;
`;

ContactNoForm.defaultProps = {
    advisorId: null,
    advisorFirstName: "",
}

function ContactNoForm(props) {
    const history = props.history;
    const advisorId = props.advisorId;
    const advisorFirstName = props.advisorFirstName;
    const [step, setStep] = useState(1);
    // const [datetime, setDatetime] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const t1 = sessionStorage.getItem("t1");
    const t2 = sessionStorage.getItem("t2");

    // const updateDateTime = (datetime) => {
    //     setDatetime(datetime)
    //     setStep(2);
    // }

    // const onBack = () => {
    //     setStep(1);
    // }

    // const updateDateTimeSubmit = async (datetime) => {
    //     setLoading(true);
    //     const request = {
    //         "advisorId": advisorId,
    //         // "email": null,
    //         "timeslot": datetime,
    //         "anonymousId": (t1 === undefined || t1 === null || t1 === "") ? uuid4() : t1,
    //         "requestType": (t2 === undefined || t2 === null || t2 === "") ? "DIR" : t2,
    //         "leadInfo": null,
    //         "firstName": null,
    //         "lastName": null
    //     };
    //     await dispatch(tryClientRequest(request))
    //     if (t1 !== null) {
    //         await dispatch(tryPostMixpanel(null, t1, "clientAcquisitionAdvisorRequestCall", request));
    //         await dispatch(tryPostMailchimp({"t1": t1, "tagId": "ACQBOOKED", "tagValue": "TRUE"}))
    //         await dispatch(tryPostMailchimp({"t1": t1, "tagId": "ACQUPDATED", "tagValue": moment().format("MM/DD/YYYY")}))
    //     }
    //     setStep(3);
    //     history.push("/public/advisor-match-scheduled-call")
    // }

    // const onSubmitEmail = async (email) => {
    //     setLoading(true);
    //     const request = {
    //         "advisorId": advisorId,
    //         "email": email,
    //         "timeslot": datetime,
    //         "anonymousId": (t1 === undefined || t1 === null || t1 === "") ? uuid4() : t1,
    //         "requestType": (t2 === undefined || t2 === null || t2 === "") ? "DIR" : t2,
    //         "leadInfo": null,
    //         "firstName": null,
    //         "lastName": null
    //     };
    //     await dispatch(tryClientRequest(request))
    //     if (t1 !== null) {
    //         await dispatch(tryPostMixpanel(null, t1, "clientAcquisitionAdvisorRequestCall", request));
    //     }
    //     setStep(3);
    // }

    const onSubmitPhone = async (phone) => {
        setLoading(true);
        const request = {
            "advisorId": advisorId,
            "email": null,
            "timeslot": null,
            "phone": phone,
            "anonymousId": (t1 === undefined || t1 === null || t1 === "") ? uuid4() : t1,
            "requestType": (t2 === undefined || t2 === null || t2 === "") ? "DIR" : t2,
            "leadInfo": null,
            "firstName": null,
            "lastName": null,
        };
        await dispatch(tryClientRequest(request))
        if (t1 !== null) {
            // await dispatch(tryPostMixpanel(null, t1, "clientAcquisitionAdvisorRequestCall", request));
            // await dispatch(tryPostMailchimp({"t1": t1, "tagId": "ACQBOOKED", "tagValue": "TRUE"}))
            // await dispatch(tryPostMailchimp({"t1": t1, "tagId": "ACQUPDATED", "tagValue": moment().format("MM/DD/YYYY")}))
        }
        setStep(3);
    }

    if (isLoading && step !== 3) {
        return (<LoadingCenter><LogoLoading /></LoadingCenter>);
    }

    return (
        <Section backgroundColor={colors.canvaDarkBlue} style={{ position: "relative" }}>
            {(t2 === "DIRECTORY" || t2 === "DIR") ?
                <FunnelPage />
                :
                <>
                    {/*<ContactPage1 show={step === 1} advisorId={5229/*advisorId*!/ setDatetime={updateDateTimeSubmit} advisorName={advisorFirstName} />*/}
                    {/*<ContactPage2 show={step === 2} onSubmit={onSubmitEmail} onBack={onBack} />*/}
                    <ContactPage3 show={step === 1}  onSubmit={onSubmitPhone} advisorName={advisorFirstName} />
                    <ContactSuccess show={step === 3} advisorName={advisorFirstName} />
                </>
            }
        </Section>
    );

}

export default withRouter(ContactNoForm);