import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/* Components */
import { FontSubtitle24, FontHeader16, FontTitle24 } from "../../../../common/components/fonts";
import { images } from "../../../../common/components/images";
import { LogoLoading } from "../../../../common/components/loading";
import FirmMarketingProspectRecord from '../components/prospectrecord';

/* Middleware */
import { tryGetProspects, tryGetLeadConversation, tryUpdateStatus } from '../middleware/prospects';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
  }
`;
const ColProspectParent = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
const DropdownRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 60px;
`;

const Dropdown = styled.div`
  padding: 11px 30px 9px 30px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.25);
  border-radius: 100px;
  cursor: pointer;
`;

const Arrow = styled.div`
  background-image: url(${images.chevronDown});
  background-size: cover;
  background-repeat: no-repeat;
  width: 15px;
  height: 9px;
`;

const DropdownList = styled.div`
  padding: 20px 30px 15px 30px;
  box-shadow: 0 3px 10px 0 rgb(0, 0, 0, 25%);
  border-radius: 20px;
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  background-color: white;
`;
const ListButton = styled(FontHeader16)`
    padding: 7px 0;
    cursor: pointer;
`;
const DropdownTitle = styled(FontHeader16)`
    width: 80px;
`;
const Relative = styled.div`
    position: relative;
`;
const Background = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  z-index: 1;
`;
const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;

export default function FirmMarketingProspects() {
    const [filter, setFilter] = useState("Pending")
    const [filterOpen, setFilterOpen] = useState(false)
    const [prospects, setProspects] = useState([])
    const [filtered, setFiltered] = useState([])
    const [isLoading, setLoading] = useState(true)
    const [showPopup, setPopup] = useState(false)
    const [conversation, setConservation] = useState([])
    const [pending, setPending] = useState(["Lead information sent to advisor", "Follow up sent", "Follow up with advisor completed"])
    const [presentedCount, setPresentedCount] = useState(0)
    const [selectedCount, setSelectedCount] = useState(0)
    // state = {
    //     "filter": "Pending", // Pending, Won, Lost
    //     "filterOpen": false,
    //     "prospects": [],
    //     "filtered": [],
    //     "isLoading": true,
    //     "showPopup": false,
    //     "conversation": [],
    //     "pending": ["Lead information sent to advisor", "Follow up sent", "Follow up with advisor completed"]
    // }

    // componentDidMount() {
    //     this.init();
    // }

    useEffect(() => {
        init()
    }, [])
    const init = async () => {
        const prospects = await tryGetProspects();
        // prospects.timesShownToLeads
        setProspects(prospects?.leads ? prospects.leads : [])
        setFiltered(prospects?.leads && prospects?.leads.length > 0 ? prospects.leads.filter(p => pending.includes(p.status)) : [])
        setPresentedCount(prospects.timesShownToLeads)
        setSelectedCount(prospects?.leads.filter((l) => l.status === "Lead information sent to advisor").length)
        setLoading(false)
        // this.setState({
        //     "prospects": prospects?.leads ? prospects.leads : [],
        //     "filtered": prospects?.leads && prospects?.leads.length > 0 ? prospects.leads.filter(p => this.state.pending.includes(p.status)) : [],
        //     "isLoading": false
        // })
    }

    const setShowPopup = (showPopup) => {
        setPopup(showPopup)
    }

    const setConversation = (conversation) => {
        setConversation(conversation)
    }

    const toggleFilterChange = (value) => {
        setFilter(value)
        setFiltered(filterProspectsByValue(value))
        setFilterOpen(false)
        // this.setState({
        //     "filter": value,
        //     "filtered": prospects && prospects?.length > 0 ?
        //         value === "Won" ? prospects.filter(p => (p.status === "Matched")) :
        //             value === "Lost" ? prospects.filter(p => (p.status === "Advisor did not win lead")) :
        //                 prospects.filter(p => pending.includes(p.status)) : [],
        //     "filterOpen": false
        // })
    }
    const filterProspectsByValue = (value) => {
        if (prospects && prospects.length > 0) {
            if (value === "Won") {
                return prospects.filter(p => (p.status === "Matched"))
            } else if (value === "Lost") {
                return prospects.filter(p => (p.status === "Advisor did not win lead"))
            } else if (value === "All") {
                return prospects
            } else if (value === "Pending") {
                return prospects.filter(p => pending.includes(p.status))
            } else {
                return []
            }
        }
    }
    const toggleFilterOpen = () => {
        setFilterOpen(true)
    }

    const toggleFilterClose = () => {
        setFilterOpen(false)
    }

    const selectStatus = async (leadId, leadStatus) => {
        setLoading(true)
        await tryUpdateStatus(leadId, leadStatus);
        setLoading(false)
        init()
    }

    const getConvo = async (userID) => {
        const APIConversation = await tryGetLeadConversation(userID);
        setConversation(APIConversation);
        init();
    }

        if (isLoading) {
            return (<LoadingCenter><LogoLoading /></LoadingCenter>)
        } else {
            return (
                <>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:'30px'}}>
                        <FontSubtitle24>{presentedCount} Times Presented</FontSubtitle24>
                        <FontSubtitle24> {selectedCount} Times Selected </FontSubtitle24>
                    </div>
                    {prospects && prospects?.length > 0 ?
                        <>
                            <Row>
                                <FontSubtitle24 spaced={true}>{filter.toUpperCase()} PROSPECTS ({filtered.length})</FontSubtitle24>
                                <Relative>
                                    <Dropdown onClick={toggleFilterOpen}>
                                        <DropdownRow>
                                            <DropdownTitle>{filter}</DropdownTitle>
                                            <Arrow />
                                        </DropdownRow>
                                    </Dropdown>
                                    {filterOpen &&
                                        <>
                                            <DropdownList>
                                                <ListButton onClick={() => toggleFilterChange("All")}>All</ListButton>
                                                <ListButton onClick={() => toggleFilterChange("Pending")}>Pending</ListButton>
                                                <ListButton onClick={() => toggleFilterChange("Won")}>Won</ListButton>
                                                <ListButton onClick={() => toggleFilterChange("Lost")}>Lost</ListButton>
                                            </DropdownList>
                                            <Background onClick={toggleFilterClose} />
                                        </>
                                    }
                                </Relative>
                            </Row>
                            <ColProspectParent>
                                {filtered.map((p, pIndex) => {
                                    return (
                                        <FirmMarketingProspectRecord
                                            key={pIndex}
                                            prospect={p}
                                            selectStatus={selectStatus}
                                            index={pIndex}
                                            getConvo={getConvo}
                                            showPopup={showPopup}
                                            setShowPopup={setShowPopup}
                                            conversation={conversation}
                                        />
                                    )
                                })}
                            </ColProspectParent>
                        </>
                        : <FontTitle24>No prospects found</FontTitle24>
                    }
                </>
            )
        }

}

// const mapDispatchToProps = dispatch => ({
//     // tryGetAdvisorProspects: () => dispatch(tryGetAdvisorProspects()),
//     // tryPostAdvisorProspectsAccept: () => dispatch(tryPostAdvisorProspectsAccept()),
//     tryGetProspects: () => dispatch(tryGetProspects()),
//     tryGetLeadConversation: (leadId) => dispatch(tryGetLeadConversation(leadId)),
//     tryUpdateStatus: (leadId, leadStatus) => dispatch(tryUpdateStatus(leadId, leadStatus))
// })

// export default connect(null, mapDispatchToProps)(withRouter(FirmMarketingProspects));