import React from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser'

/* Components */
import {colors} from "../../../common/components/colors";
import {Section, Body} from './components';
import {FontTitle40, FontBody12, FontBody14, FontBody18} from "../../../common/components/fonts";
import {NumberOutlined} from '@ant-design/icons';

const Title = styled(FontTitle40)`
  font-size: 60px !important;
  @media screen and (max-width: 1680px) {
    font-size: 45px !important;
  }
  line-height: 1;
  color: ${props => props.color};
`;
const Description = styled(FontBody14)`
    color: ${props => props.color};
    line-height: 2;
`;
const FirmBio = styled(FontBody18)`
color: ${props => props.color};
padding:0;
margin:0;
  vertical-align: middle;
  @media screen and (max-width: 1680px) {
    font-size: 16px;
    line-height: 1;
  }
`;

Disclosure.defaultProps = {
    description: "",
    logos: [],
    firm_sec: "",
    advisor_sec: "",
}

export default function Disclosure(props) {
    const disclosure = props.disclosure;
    const advisor_sec = props.advisor_sec;

    return (
        <Section backgroundColor={colors.canvaBlush} style={{position:"relative"}}>
            <Title color={colors.primary100}>Disclosure</Title>
            <Body>
                <Description>
                    {disclosure !== null ? parse(disclosure) : ""} TrustWillow.com provides referrals to financial professionals.
                    TrustWillow.com.com is not a current client of advisor or advisory services. Financial
                    professionals pay cash compensation for these referrals, which creates the incentive for
                    TrustWillow.com to make these referrals, resulting in a conflict of interest. Advisors
                    compensate Willow up to $5,200 dollars for marketing services.
                </Description>
                <FirmBio>Advisor SEC/CRD <NumberOutlined /> {advisor_sec}</FirmBio>
            </Body>
        </Section>
    )
}
