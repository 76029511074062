import React, {useState, useEffect} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {useDispatch, useSelector} from "react-redux";

/* Components */
import { LogoLoading } from "../../../../common/components/loading";
// import CertManage from "./manage";
// import CertBenefits from "./benefits";
import CertificationStepList from "./steplist";
import CertProfile from "./directoryprofile";
import FirmCoachesList from '../../coaches/containers/list'
import FirmCoachesProfile from '../../coaches/containers/record';
import  FirmTrainingsList from '../../trainings/containers/list';
import FirmTrainingRecord from '../../trainings/containers/record';

/* Middleware */
import {certificationValidation} from "../middleware/certificationValidation";
import {FontBody24, FontSubtitle40} from "../../../../common/components/fonts";
import CertTrainingsNav from "../components/listnav";
import {images} from "../../../../common/components/images";
import FirmTrainingsNav from "../../trainings/components/listnav";
import {colors} from "../../../../common/components/colors";

export const CertBack = styled(Link)`
  width: fit-content;
  color: ${colors.primary100};
  align-items: center;
  cursor: pointer;
  margin-bottom: 2em;
`;
const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const TopSegment = styled.div`
  background-image: url(${images.firmBackgroundTop});
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  left: -60px;
  right: 0;
  top: -90px;
  bottom: 0;
  padding: 90px 60px 0 60px;
  width: 100%;
  @media screen and (max-width: 748px) {
    left: -30px;
    right: -30px;
    top: -30px;
    bottom: 0;
    padding: 30px 30px 0 30px;
  }
`;
const Subtitle = styled(FontBody24)`
  margin: 30px 0 0 0;
  min-height: 108px;
`;

const TrainingNav = styled.div`
    margin: -90px -60px 90px -60px;
    @media screen and (max-width: 748px) {
      margin: -30px -30px 30px -30px;
    }
    @media screen and (max-width: 450px) {
      margin: -30px -15px 30px -15px;
    }
`;

function IntroTop() {
    return(
        <>
            <TopSegment>
                <FontSubtitle40 spaced={true}>ADVISOR CERTIFICATES</FontSubtitle40>
                <Subtitle>Complete certificates designed to help you better attract and serve Women, NextGen &
                    Underrepresented clients.</Subtitle>
                {/*<CertTrainingsNav/>*/}
            </TopSegment>
            {/*{(location.pathname === "/my-certificates/my-trainings" || location.pathname === "/my-certificates/my-trainings/electives") &&*/}
            {/*    <TrainingNav>*/}
            {/*        <FirmTrainingsNav />*/}
            {/*    </TrainingNav>*/}
            {/*}*/}
        </>
    )
}

export default function CertPreviewEvents() {
    const [isLoading, setIsLoading] = useState(true);
    const [certified, setCertified] = useState({
        "currentProgress": {
            "stepsCompleted": [false,false,false,false],
            "step3Pieces": {
                "peerCompleted": false,
                "clientCompleted": false
            },
            "step4Pieces": {
                "profileCompleted": false,
                "calendarCompleted": false
            },
        },
        "completedCertifications": null,
        "certificationDefinition": null,
        "isLoading": true,
    });
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        init();
    }, []);

    const init = async () => {
        const validation = await dispatch(certificationValidation());
        setCertified({...validation, "isLoading": false})
    }

    const pushToProfile = async () => {
        await history.push("/my-certificates/profile")
        setIsLoading(false)
    }

    useEffect(() => {
        if(!certified.isLoading) {
        //     if(certified.currentProgress === null && certified.completedCertifications !== null) {
        //         pushToProfile()
        //     } else {
                setIsLoading(false)
            // }
        }
    }, [certified]);

    if(isLoading) {
        return(<LoadingCenter><LogoLoading /></LoadingCenter>)
    }

    return(
        <>

            {(location.pathname === "/my-certificates") &&
                <>
                    <IntroTop />
                    <CertificationStepList
                        certified={certified.currentProgress}
                        completedCertifications={certified.completedCertifications}
                        purchased={certified.certificationStatus}
                    />
                </>
            }

            {(location.pathname === "/my-certificates/my-trainings" || location.pathname === "/my-certificates/my-trainings/electives") &&
                <>
                    {/*<IntroTop />*/}
                    <FirmTrainingsList />
                </>
            }
            {(location.pathname.includes("/elective-trainings") && location.pathname !== "/elective-trainings") &&
                <>
                    {/*<IntroTop />*/}
                    <FirmTrainingRecord />
                </>
            }
            {(location.pathname.includes("/my-certificates/my-trainings") && location.pathname !== "/my-certificates/my-trainings" && location.pathname !== "/my-certificates/my-trainings/electives") &&
                <FirmTrainingRecord />
            }

            {(location.pathname === "/my-certificates/my-coaches" || location.pathname === "/my-certificates/my-coaches/browse" || location.pathname === "/my-coaches" || location.pathname === "/my-coaches/browse") &&
                <>
                    {/*<IntroTop />*/}
                    <FirmCoachesList />
                </>
            }

            {(
                (location.pathname.includes("/my-certificates/my-coaches") && location.pathname !== "/my-certificates/my-coaches" && location.pathname !== "/my-certificates/my-coaches/browse") ||
                (location.pathname.includes("/my-coaches") && location.pathname !== "/my-coaches" && location.pathname !== "/my-coaches/browse")
            ) &&
                <FirmCoachesProfile />
            }

            {(location.pathname.includes("/my-certificates/profile")) &&
                <>
                    {/*<IntroTop />*/}
                    <CertProfile
                        certified={certified}
                        init={init}
                    />
                </>
            }

        </>
    )

}




